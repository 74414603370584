.details-modal--active {
    --details-modal-video-display: block;
    --details-modal-opacity: 1;
    --details-modal-pointer-events: auto;
    --details-modal-transform: none;
}

.hero-subnav__video:not([src]) {
    display: var(--details-modal-video-display, none);
}

@media (max-width: 539px) {
    .details__modal {
        --details-modal-padding-h: 12px;
    }

    .details__modal-info-btn.button {
        font-size: 10px;
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .details__modal-info-btn.button:before {
        display: none;
    }
}

@media (max-width: 1023px) and (max-height: 599px) {
    .details__modal {
        --details-modal-right: 50%;
        max-width: 480px;
    }

    :root {
        --details-modal-transform: translate(50%, 10px);
    }

    .details-modal--active {
        --details-modal-transform: translate(50%, 0);
    }
}

.hero-subnav__link {
    transition: opacity 250ms ease-in-out 300ms;
}

.hero-subnav__link--active {
    --hero-subnav-link-opacity: 0.45;
}

.hero-subnav__link--active ~ .hero-subnav__link--active {
    --hero-subnav-link-opacity: 1;
}

.hero-subnav__links.swiper-initiaized {
    --hero-subnav-link-margin-right: 0;
}

.hero-subnav__links-container {
    max-width: none;
}

@media (min-width: 1024px) {
    :root {
        --details-modal-bottom: 24px;
        --details-modal-right: 40px;
        --details-modal-width: 580px;
    }

    .hero-subnav__container {
        --hero-subnav-buttons-width: auto;
        --hero-subnav-details-flex-direction: row;
        --hero-subnav-details-justify-content: space-between;
        --hero-subnav-flex-direction: row-reverse;
        --hero-subnav-justify-content: space-between;
        --hero-subnav-links-width: calc(100% - 475px);
        --hero-subnav-meta-display: inline-flex;
        --hero-subnav-meta-flex-direction: row;
        --hero-subnav-meta-border-width: 0;
        --hero-subnav-meta-item-border-bottom: none;
        --hero-subnav-meta-item-border-right: 1px solid #dedede;
        --hero-subnav-meta-item-display: block;
        --hero-subnav-meta-item-padding: 12px 24px;
        --hero-subnav-meta-item-last-padding: 12px 0 12px 24px;
        --hero-subnav-meta-item-text-align: center;
        --hero-subnav-meta-item-width: auto;
        --hero-subnav-meta-margin-top: 0;
        --hero-subnav-meta-order: 0;
        --hero-subnav-meta-padding: 0;
        --hero-subnav-meta-width: auto;
    }

    .hero-subnav__container:not(.hero-subnav__container--sticky) {
        --hero-subnav-button-title-font-size: 18px;
        --hero-subnav-buttons-height: 118px;
        --hero-subnav-button-image-size: 68px;
        --hero-subnav-button-image-top: 50%;
        --hero-subnav-button-image-transform: translateY(-50%);
        --hero-subnav-buttons-margin-bottom: -19px;
        --hero-subnav-buttons-top: -19px;
        --hero-subnav-height: 276px;
        --hero-subnav-links-height: 99px;
    }

    .hero-subnav__button {
        padding-left: 24px;
        padding-right: 24px;
        transition: 500ms ease-in-out;
    }

    .hero-subnav__button--image {
        padding-right: 137px;
    }

    .hero-subnav__container:not(.hero-subnav__container--sticky) .hero-subnav__button:hover {
        height: calc(100% - 19px);
        transform: translateY(19px);
    }

    .hero-subnav__container:not(.hero-subnav__container--sticky) .hero-subnav__button:hover:before {
        bottom: 100%;
        content: '';
        height: 20px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .hero-subnav__copy-text {
        white-space: nowrap;
    }

    .hero-subnav__links {
        max-width: none;
    }

    .hero-subnav__links:not(.swiper-initiaized) {
        --hero-subnav-link-margin-right: 48px;
    }
}

@media (max-width: 1023px) {
    .hero-subnav__links {
        height: 100% !important;
        position: static !important;
    }
}

@media (min-width: 1280px) {
    .hero-subnav__container {
        --hero-subnav-height: 298px;
        --hero-subnav-meta-item-padding: 8px 24px;
        --hero-subnav-meta-item-last-padding: 8px 0 8px 24px;
    }

    .hero-subnav__container:not(.hero-subnav__container--sticky) {
        --hero-subnav-copy-font-size: 18px;
        --hero-subnav-copy-line-height: 1.33333;
        --hero-subnav-copy-margin-top: 16px;
        --hero-subnav-meta-item-eyebrow-font-size: 14px;
        --hero-subnav-meta-item-font-size: 24px;
        --hero-subnav-title-font-size: 56px;
    }
}

@media (min-width: 1600px) {
    .hero-subnav__container {
        --hero-subnav-links-width: auto;
        --hero-subnav-meta-item-padding: 8px 49px;
        --hero-subnav-meta-item-last-padding: 8px 0 8px 49px;
    }

    .hero-subnav__container:not(.hero-subnav__container--sticky) .hero-subnav__button {
        padding-left: 41px;
        padding-right: 53px;
    }

    .hero-subnav__container:not(.hero-subnav__container--sticky) .hero-subnav__button--image {
        padding-left: 46px;
        padding-right: 137px;
    }

    .hero-subnav__links-container {
        flex-grow: 1;
    }
}

/* sticky nav TEMP */
.hero-subnav__container--sticky {
    background-color: #fff;
    position: fixed;
    top: 0;
    transform: translateY(-200%);
    transition: transform 450ms ease-in-out;
    width: 100%;
}

.subnav--sticky .hero-subnav__container--sticky {
    transform: translateY(0);
}

.hero-subnav__details--sticky {
    max-width: none;
}

@media (max-width: 767px) {
    .hero-subnav__copy .badge {
        display: flex;
        max-width: fit-content;
    }
}

@media (min-width: 1024px) {
    .hero-subnav__container--sticky {
        --hero-subnav-meta-item-padding: 0 32px;
        --hero-subnav-meta-item-last-padding: 0 0 0 32px;
        box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.03);
    }

    .hero-subnav__container--sticky.hero-subnav__container--hide-details {
        height: 50px;
    }

    .hero-subnav__container--sticky .hero-subnav__links-container {
        max-height: 50px;
    }

    .hero-subnav__container--sticky .hero-subnav__buttons {
        max-height: 50px;
        top: 0 !important;
    }
}

@media (max-width: 1023px) {
    .hero-subnav__links-container {
        position: relative !important;
    }
}

.residence-main-div,
.residence-details,
.Community-main,
.neighborhood-main {
    .embeddedServiceHelpButton,
    .nhs-desktop,
    .utility-nav {
        // display: none !important;
        opacity: 0;
        pointer-events: none;
    }
}

@media (min-width: 1024px) {
    .hero-subnav__button {
        --wrap-padding-h: min(var(--wrap-padding-h), 40px);
    }
}
