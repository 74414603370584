@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.schedule-tour {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  .content-section {
    width: 50%;
  }

  .image-building {
    left: 60%;
    left: 70%;
    max-height: 60%;
    position: absolute;
    top: 30%;
    top: 50%;
    width: 60%;
  }

  .image-building:before {
    content: "";
    display: block;
    padding-top: 116.66666%;
    padding-top: 134.42136%;
  }

  .image-building-image {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .image-section {
    background-color: #325b81;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 480px;
    position: relative;
    width: 80%;
    width: 72.75132%;

    @media (min-width: 1024px) {
      max-height: none;
      width: 70%;
      width: 72.75132%;
    }
  }

  .image-section:before {
    padding-top: 130%;
    padding-top: 129.18825%;
    content: "";
    display: block;
  }

  .two-image {
    margin-bottom: 4.75%;
    padding-right: 3%;
    width: 50%;
  }

  @include max-width($iphonex-landscape) {
    .content-section {
      padding-top: 50px;
      width: 100%;
    }

    .two-image {
      margin-bottom: 7.25%;
      order: -1;
      padding-right: 0;
      width: 100%;
    }
  }
}

.Review_Policy_main_div {
  .Review_Policy_div {
    background-color: $white;
    padding: 5%;
  }

  .modal-body {
    background-color: unset !important;
  }
}

.Broker-Btns {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  .button + .button {
    margin: 20px 0 0;
  }

  @media (min-width: 1300px) {
    flex-direction: row;

    .button + .button {
      margin: 0 0 0 20px;
    }
  }
}