@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.mobile-main-section {
    display: none;
    .cards-section {
        display: flex;
        flex-wrap: wrap;
        .accordion {
            padding-top: 25px;
            width: 85%;
            margin: auto;
            .card {
                border: none;
                border-bottom: 1px solid #e0e0e0;
                padding: 10px 0;
                &:last-child {
                    .collapse,
                    .collapsing {
                        .card-body {
                            padding: 0;
                        }
                    }
                }
                .card-header {
                    background: transparent;
                    padding: 0;
                    border: none;
                    button {
                        text-decoration: none !important;
                        width: 100%;
                        padding: 0;
                        .card-head {
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                            color: $black-new;
                            align-items: center;
                            .card-heading {
                                letter-spacing: -1.07px;
                                text-align: left;
                                color: $green;
                                font-weight: $font-atten-medium;
                                font-size: 24px;
                                line-height: 35px;
                            }
                            .fa-minus {
                                display: none;
                            }
                            .fa_icons {
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .collapse,
                .collapsing {
                    order: 1;
                    &.show~.card-header {
                        .card-head {
                            .fa-minus {
                                display: block;
                            }
                            .fa-plus {
                                display: none;
                            }
                        }
                    }
                    .collapsing~.card-header {
                        .card-head {
                            .fa-minus {
                                display: none;
                            }
                            .fa-plus {
                                display: block;
                            }
                        }
                    }
                    .card-body {
                        padding: 0;
                        .region-card {
                            width: 100%;
                            border: 1px solid $white;
                            color: $black-new;
                            background-color: $white;
                            .region-list {
                                list-style: none;
                                padding: 0;
                                text-align: left;
                                li {
                                    border-bottom: 1px solid #E0E0E0;
                                    font-size: 20px;
                                    line-height: 35px;
                                    letter-spacing: -0.55px;
                                    font-weight: $font-atten-medium;
                                    position: relative;
                                    padding: 15px 0;
                                    cursor: pointer;
                                    &:before {
                                        content: " ";
                                        position: absolute;
                                        width: 7px;
                                        height: 7px;
                                        border-top: 2px solid #C3C3C3;
                                        border-right: 2px solid #C3C3C3;
                                        transform: translate(-1px, -50%) rotate(45deg);
                                        right: 10px;
                                        top: 50%;
                                    }
                                    &:after {
                                        background: #C3C3C3;
                                        height: 2px;
                                        width: 13px;
                                        content: " ";
                                        position: absolute;
                                        transform: translate(-50%, -50%);
                                        right: 5px;
                                        top: 50%;
                                    }
                                    &:last-child {
                                        border-bottom: none;
                                        padding-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include max-width($iphone6-plus-landscape) {
        display: block;
        .cards-section {
            .accordion {
                .card {
                    .card-header {
                        button {
                            .card-head {
                                .card-heading {
                                    letter-spacing: -0.7px;
                                    font-size: 28px;
                                    line-height: 52px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-contact-image-box-main.contact-us-block {
    display: none;
    @include max-width($iphone6-plus-landscape) {
        display: block;
        .image-box {
            height: 400px;
            background-size: cover;
        }
    }
    @include max-width($iphone4-landscape) {
        .image-box {
            height: 239px;
        }
    }
}