@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.plan-banner {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: flex;
    position: relative;

    .HeroContent {
        position: relative;
        width: 100%;
        align-items: flex-end;
        padding-bottom: 8%;

        &:after {
            content: ' ';
            position: absolute;
            background: #fff;
            width: 5000px;
            height: 5000px;
            transform: rotate(26.6deg) translate(27%, 74%);
            bottom: 0;
            right: 0;
        }

        .HeroContentWrapper {
            width: 66%;
            // margin: 0 auto;
            padding: 0% 5% 0%;
            position: relative;
            z-index: 3;

            .bannerHeader {
                .heading-section {
                    text-align: left;
                    margin-left: 0;
                    width: 70%;

                    .main-title {
                        padding-bottom: 32px;
                        padding: 15px 0;
                    }
                    @include max-width($iphone4-landscape) {
                        width: 100%;
                    }
                }
                .generic-text{
                    font-size: 18px; 
                    line-height: 25px;
                    margin: 0;
                    font-weight: $font-atten-medium;
                    color: $gray-new;
                }
            }

        }
    }
    .add_overlay{
        background: transparent linear-gradient(119deg, #00000000 0%, rgba(0,0,0,0.8))
    }
    .hero-logo{
        height: 300px;
        width: 500px;
        position: absolute;
        transform: translate(-50%,-50%);
        z-index: 555;
        top: 30%;
        left: 50%;
        img{
            object-fit: fill;
        }
    }
    
    @include max-width($desktop-xl){
        .hero-logo{
            top: 37%;
   
        }
    }
    @include max-width($desktop-large) {
        .HeroContent {
            &:after {
                content: ' ';
                position: absolute;
                background: #fff;
                width: 5000px;
                height: 5000px;
                transform: rotate(26.6deg) translate(27%, 74%);
                bottom: 0;
                right: 0;
            }
        }
        .hero-logo{
            height: 200px;
            top: 38%;
            width: 400px;
        }
    }
    @include max-width(1366px){
        .hero-logo{
            height: 200px;
            top: 25%;
            width: 400px;
        }
    }
    @include max-width($macbook) {
        .HeroContent {
            padding-bottom: 5%;
        }
    } 
    @include max-width($desktop){
        .HeroContent{
            &:after {
                content: ' ';
                position: absolute;
                background: #fff;
                width: 5000px;
                height: 5000px;
                transform: rotate(26.6deg) translate(27%, 72%);
                bottom: 0;
                right: 0;
            }
        }
        .hero-logo{
            height:223px;
            top: 30%;
    
        }
    }
    @include max-width($tablet-landscape) {
        .HeroContent {
            padding-bottom: 4;
            .HeroContentWrapper {
                width: 100%;
                .bannerHeader {
                    .heading-section {
                        width: 90%;
                    }
                }
            }
        }
        .hero-logo{   
        top: 34%;
        }
    }
    @include max-width($iphonex-landscape) {
     
        .HeroContent {
            padding-bottom: 5%;
            .HeroContentWrapper {
                width: 100%;
            }

            &:after {
                transform: rotate(26.6deg) translate(27%, 73%);
            }
        }
    }
    @include max-width(812px) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 71%);
            }
        }
    }
    @include max-width($tablet) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 69%);
            }
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        width: 100%;
                    }
                }
            }
        }
    }
    @include max-width($iphone6-plus-landscape) {
        padding-bottom: 10%;
        .HeroContent {
            padding-bottom: 10px;
        }
        .hero-logo{
            height: 146px;
    width: 305px;
    top: 23%;
        }
    }
    @include max-width($iphone4-landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(38%, 68%);
            }
            .HeroContentWrapper {
                padding: 0 10%;
            }
        }
        .hero-logo{   
              
            top: 30%;
        }
    }
    @include max-width($iphone6){
        .hero-logo{
            top: 26%;
    height: 102px;
   
    width: 230px;
        }
    }
    @media (max-width:991px) and (orientation: landscape){
        .hero-logo{
            height: 120px;
            width: 220px;
            top: 23%;
            left: 50%;
        }
    }
   
    @media (max-width:812px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 73.7%);
            }
        }
        .hero-logo{
            height: 110px;
    width: 189px;
    top: 33%;
    left: 51%;
            
            
        }
    }
    @media (max-width:768px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 73.7%);
            }
        }
        .hero-logo{
            height: 120px;
            width: 204px;
            top: 23%;
            left: 50%;
        }
        
    }
    @media (max-width:736px) and (orientation: landscape) {
        padding-bottom: 0;
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 72.3%);
            }
        }
        .hero-logo{
            height: 94px;
            width: 211px;
            top: 26%;
        }
    }
    @media (max-width:667px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%,72.3%);
            }
        }
    }
    @media (max-width:568px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%,72.3%);
            }
        }
    }
}