@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.blog-and-news-post {
    color: $white;
    max-width: calc(33.33333333333333% - 24px);

    a, a:hover, a:visited {
        color: $apple;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
    }

    h6 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
    }

    img {
        height: 211px;
        margin-bottom: 24px;
    }

    .excerpt {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
    }

    .sub_text {
        font-size: 13px;
        line-height: 25px;
        letter-spacing: 3px;
        margin-bottom: 8px;
        text-transform: uppercase;
    }

    @media (max-width: $tablet-landscape) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 100%;

        .image {
            margin-right:20px;

            img {
                height: 240px;
                margin-bottom: unset;
            }
        }
    }

    @media (max-width: $iphone6-landscape) {
        display: unset;
        max-width: 400px;

        .image {
            margin-right: unset;

            img {
                height: 211px;
                margin-bottom: 24px;
            }
        }
    }
}