@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
// #UTour {
//     display: none;
// }
div#UTour {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: -webkit-default;
    cursor: default;
    img {
        height: auto;
        max-width: 100%;
        width: auto;
    }
    .Utourpop_form {
        display: flex;
        height: 100%;
        .Uform-column {
            width: 50%;
            height: 100vh;
            overflow: auto;
            .Utour-header {
                padding: 30px 50px 0 !important;
                .Brandlogo {
                    display: flex;
                    align-items: center;
                    .Brandlogo-anchor {
                        padding-right: 28px !important;
                        border-right: 1px solid #D8D8D8;
                    }
                    .headtxt {
                        font-size: 16px;
                        line-height: 18px;
                        padding-left: 25px !important;
                        font-weight: 600;
                        text-transform: uppercase;
                        color: $black-new;
                        font-weight: $font-atten-bold;
                        padding-right: 10px !important;
                        letter-spacing: 3.2px;
                    }
                }
            }
            .UTour-leftSection {
                width: 60%;
                margin: 10% auto 0;
                @include max-width($desktop-xl) {
                    width:70%;
                }
                .UTour-Heading {
                    color: $black-new;
                    font-weight: $font-atten-medium;
                    font-size: 52px;
                    line-height: 62px;
                    width: 90%;
                    margin-bottom: 50px;
                    @include max-width($desktop-xl) {
                        font-size: 40px;
                        line-height: normal;
                        width:80%;
                        margin-bottom: 35px;
                    }
                    @include max-width($desktop-large) {
                        font-size: 35px;
                    }
                    @include max-width($tablet-landscape) {
                        font-size: 35px;
                        width: 100%;
                    }
                    @include max-width($iphone4-landscape) {
                        font-size: 28px;
                    }
                }
                .UTour-SUBHeading {
                    font-size: 16px;
                    line-height: 18px;
                    text-transform: uppercase;
                    color: $black-new;
                    font-weight: 600;
                    font-weight: $font-atten-bold;
                    margin-bottom: 15px;
                }
                .home_advisor {
                    margin-top: 20px;
                }
                .UTour-Description {
                    font-size: 20px;
                    line-height: 27px;
                    color:$bordergray;
                    @include max-width($desktop-large) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .Utour-btn {
                    text-transform: uppercase;
                    @include green-filled-button;
                    font-size: 13px !important;
                    letter-spacing: 3.9px;
                    line-height: 25px;
                    width: 100%;
                    margin-bottom: 25px;
                }
                // @include max-width($desktop-xl) {
                //     margin: 10% auto 0;
                // }
            }
        }
        .Uform-image {
            display: flex;
            width: 50%;
            position: relative;
            img {
                height: 100% !important;
                object-fit: cover;
                background-position: center;
                background-size: cover;
                outline: none;
            }
            .utour-image-text {
                position: absolute;
                left: 10%;
                right: 10%;
                bottom: 10%;
                .utour-image-heading {
                    font-size: 70px;
                    color: $white;
                    line-height: 70px;
                    letter-spacing: -3.5px;
                    font-weight: $font-atten-medium;
                }
                .utour-image-description {
                    color: $white;
                    font-size: 20px;
                    line-height: 26px;
                    letter-spacing: -0.5px;
                    font-weight: $font-atten-book;
                }
                @include max-width($desktop-large) {
                    .utour-image-heading {
                        font-size: 45px;
                        letter-spacing: -1.5px;
                    }
                }
            }
        }
        @include max-width($iphonex-landscape) {
            .Uform-column {
                width: 100%;
                .UTour-leftSection {
                    width: 85%;
                }
            }
            .Uform-image {
                display: none;
            }
        }
        @include max-width($iphone4-landscape) {
            .Uform-column {
                .UTour-leftSection {
                    width: 75%;
                    .Utour-btn {
                        font-size: 12px !important;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

//custom scroll bar 
// .Uform-column::-webkit-scrollbar-thumb {
//     background-color: #cccccc;
//     outline: 1px solid #cccccc;
//     border-radius:10px;
// }
// .Uform-column::-webkit-scrollbar-track {
//     box-shadow: none;
// }
// .Uform-column::-webkit-scrollbar {
//     width: 0.7em;
//     @include max-width($iphonex-landscape) {
//         width: 0px;
//     }
// }

.fancybox-slide--html {
    padding: 0 !important;
    #UTour {
        .fancybox-close-small {
            color: $white !important;
            background: unset !important;
            width: 35px !important;
            height: 35px !important;
            right: 25px !important;
            top: 25px !important;
            border-radius: 50% !important;
            border: 1px solid $white;
            padding: 8px !important;
            opacity: 1;
            @include max-width($iphonex-landscape) {
                color: #545454 !important;
                border: 1px solid #545454;
            }
        }
    }
}