@import '../../assets/scss/partials/variables';
@import '../../assets/scss/partials/mixins';
@import '../../assets/scss/partials/fonts';

.common-card {
    padding: 0 5% 0;
    display: flex;
    flex-wrap: wrap;
    .card-main-div {
        margin-bottom: 4%;
        // width: 50%;
        // padding: 0 25px;
        .card-imgs {
            position: relative;
            height: 653px;
            padding: 25px;
            overflow: hidden;

            .plan-img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .owl-carousel {
                    .owl-item {
                        overflow: hidden;
                    }
                    .owl-nav {
                        position: absolute;
                        bottom: 40px;
                        right: 40px;
                        color: $white;
                        z-index: 2;
                        width: 70px;
                        height: 25px;
                        button {
                            &:after {
                                position: absolute;
                                content: '';
                                width: 12px;
                                height: 12px;
                                border-right: 2px solid $white;
                                border-bottom: 2px solid $white;
                                transform: rotate(-45deg);
                                top: 35%;
                                right: 0;
                            }
                            &:before {
                                position: absolute;
                                content: '';
                                top: 55%;
                                height: 2px;
                                width: 20px;
                                background: $white;
                                right: 0;
                            }
                            span {
                                display: none;
                            }
                        }
                        .owl-prev {
                            &:after {
                                transform: rotate(135deg);
                                left: 0;
                            }
                            &:before {
                                left: 0;
                            }
                        }
                    }
                }
                .overlay {
                    height: 100%;
                    &:after {
                        background: transparent linear-gradient(180deg, #00000000 0%, #000000 300%) 0% 0% no-repeat
                            padding-box;
                        content: '';
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
            .card-snipe {
                position: absolute;
                z-index: 2;
                left: 0;
                top: 0;
                width: 100%;
                padding: 8px;
                background-color: $green;
                font-weight: $font-atten-medium;
                color: $white;
                font-size: 12px;
                text-align: center;
                text-transform: uppercase;
            }
            .promo-icon {
                height: 70px;
                width: 70px;
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                background-repeat: no-repeat;
            }
            .card-status {
                background: $white;
                padding: 7px 10px;
                display: inline-block;
                font-size: 11px;
                font-weight: $font-atten-bold;
                color: $black-new;
                line-height: 13px;
                margin-bottom: 20px;
                text-transform: uppercase;
                letter-spacing: 1.1px;
            }
            .card-img-info {
                position: absolute;
                z-index: 7;
                bottom: 30px;
                height: auto !important;
                color: $white;
                padding-right: 10px;
                width: 80%;
                .card-name {
                    font-size: 34px;
                    letter-spacing: -0.93px;
                    line-height: 40px;
                    margin-bottom: 5px;
                    font-weight: $font-atten-medium;
                    color: #fff;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .card-price {
                    font-size: 20px;
                    line-height: 27px;
                    // margin: 0;
                    margin-bottom: 2px;
                }
                .cardloc {
                    margin-top: 5px;
                }
            }
        }
        .owl-carousel,
        .owl-stage-outer,
        .owl-stage,
        .owl-item,
        .owl-item div {
            height: 100%;
        }
        .card-info {
            .card-detail {
                display: flex;
                justify-content: space-evenly;
                margin: 0;
                padding: 15px 0 10px;
                align-items: center;
                width: 100%;
                margin: auto;
                li {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    list-style: none;
                    border-right: 1px solid $lightgray;
                    padding: 7px;
                    letter-spacing: 1.3px;
                    text-transform: uppercase;
                    text-align: center;
                    color: $black-new;
                    letter-spacing: 0.5px;
                    font-weight: 700;
                    &:last-child {
                        border-right: none;
                    }
                    .info-value {
                        font-size: 11px;
                        font-weight: $font-atten-medium;
                        margin-bottom: 5px;
                        color: $black-new;
                        line-height: 25px;
                    }
                    .info-name {
                        font-size: 10px;
                        color: $gray-new;
                        line-height: 14px;
                    }
                }
            }
            .learn_more_btn {
                border: 1px solid $new-border-color;
                color: $black-new;
                padding: 2% 10%;
                font-weight: $font-atten-bold;
                line-height: 25px;
                font-weight: bold;
                letter-spacing: 2px;
                font-size: 13px;
                display: flex;
                justify-content: center;
                &:hover {
                    text-decoration: none;
                    color: $black-new;
                    background-color: $green;
                }
            }
        }
        @include max-width($desktop-xl) {
            .card-imgs {
                height: 600px;
            }
        }
        @include max-width($iphonex-landscape) {
            .card-img-info {
                .cardloc {
                    margin-top: 9px !important;
                }
            }
        }
        @include max-width($desktop-large) {
            .card-imgs {
                height: 500px;
                .card-img-info {
                    .card-name {
                        font-size: 34px;
                        line-height: 40px;
                    }
                    .card-price {
                        font-size: 18px;
                        line-height: 21px;
                    }
                }
            }
            .card-info {
                .card-detail {
                    li {
                        padding: 10px 5px;
                        .info-value {
                            font-size: 12px;
                        }
                        .info-name {
                            font-size: 9px;
                        }
                    }
                }
            }
        }
        @include max-width($macbook) {
            .card-info {
                .card-detail {
                    li {
                        .info-value {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        @include max-width($tablet-landscape) {
            .card-imgs {
                height: 442px;
                .card-img-info {
                    .card-name {
                        font-size: 30px;
                        line-height: 40px;
                    }
                }
            }
            .card-info {
                .card-detail {
                    width: 100%;
                    li {
                        .info-value {
                            font-size: 9px;
                        }
                        .info-name {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
        @include max-width($tablet) {
            width: 100%;
            .card-info {
                .card-detail {
                    width: 100%;
                    li {
                        .info-value {
                            font-size: 9px;
                        }
                        .info-name {
                            font-size: 9px;
                        }
                    }
                }
            }
        }
        @include max-width($iphone6-plus-landscape) {
            margin-bottom: 8%;
            .card-info {
                .card-detail {
                    width: 100%;
                    li {
                        .info-value {
                            font-size: 11px;
                        }
                        .info-name {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        @include max-width($iphone4-landscape) {
            margin-bottom: 15%;
            width: 100%;
            .card-imgs {
                .plan-img {
                    .owl-carousel {
                        .owl-nav {
                            right: 20px;
                        }
                    }
                }
            }
            .card-info {
                li {
                    line-height: 0.1px;
                }
            }
        }
        @include max-width($iphone6) {
            .card-info {
                .card-detail {
                    width: 100%;
                    li {
                        .info-value {
                            font-size: 9px;
                        }
                        .info-name {
                            font-size: 9px;
                        }
                    }
                }
            }
        }
    }
    .owl-carousel {
        position: relative;
        margin-bottom: 5%;
        .owl-stage-outer {
            .owl-stage {
                padding: 0 !important;
                .owl-item {
                    width: 610px;
                }
            }
        }

        .owl-nav {
            display: flex;
            position: absolute;
            bottom: 2px;
            left: 5%;
            transform: translateY(100%);

            button {
                margin: 0;
                border: 0;

                &.disabled {
                    opacity: 1;
                }

                &:hover {
                    background: transparent;
                    color: inherit;
                }

                .sliderArrow {
                    position: relative;
                    border: 1px solid $new-border-color;
                    height: 61px;
                    width: 61px;

                    &:after,
                    &:before {
                        content: ' ';
                        position: absolute;
                        top: 50%;
                        left: 45%;
                    }

                    &:before {
                        width: 8px;
                        height: 8px;
                        border-top: 2px solid $black-new;
                        border-right: 2px solid $black-new;
                        transform: translate(-1px, -50%) rotate(45deg);
                    }

                    &:after {
                        background: $black-new;
                        transform: translate(-50%, -50%);
                        height: 2px;
                        width: 16px;
                    }

                    &.arrowLeft {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .owl-dots {
            position: absolute;
            bottom: -24px;
            transform: translateY(100%);
            background: $new-border-color;
            height: 2px;
            display: flex;
            width: 75%;
            left: 15%;

            .owl-dot {
                height: 2px;
                flex-grow: 1;
                width: 100%;

                &.active {
                    background: $black-new;
                }

                span {
                    display: none;
                }
            }
        }

        button:focus,
        button:active {
            outline: 0;
            border: 0;
        }
    }
    @include max-width($desktop-large) {
        .owl-carousel {
            .owl-nav {
                left: 5%;
                button {
                    .sliderArrow {
                        height: 50px;
                        width: 50px;
                    }
                }
            }
            .owl-dots {
                bottom: -24px;
                left: 16%;
            }
        }
    }
    @include max-width($tablet) {
        .owl-carousel {
            .owl-nav {
                button {
                    .sliderArrow {
                        height: 45px;
                        width: 45px;
                    }
                }
            }
            .owl-dots {
                width: 74%;
                left: 19%;
                bottom: -42px;
            }
        }
    }
    @media (max-width: 896px) and (orientation: landscape) {
        .owl-carousel {
            .owl-nav {
                bottom: 7px;
            }
            .owl-dots {
                bottom: -16px;
            }
        }
    }
    @include max-width($iphonex-landscape) {
        .owl-carousel {
            .owl-dots {
                left: 19%;
                // bottom: -42px;
            }
        }
    }

    @include max-width($iphone6-landscape) {
        .owl-carousel {
            .owl-nav {
                button {
                    .sliderArrow {
                        height: 45px;
                        width: 45px;
                    }
                }
            }
            .owl-dots {
                width: 74%;
                // bottom: -6px;
            }
        }
    }
    @include max-width($iphone4-landscape) {
        padding: 0.5% 10% 5%;

        .owl-carousel {
            .owl-nav {
                left: 14px;
                bottom: 18px;
            }

            .owl-dots {
                width: 59%;
                left: 39%;
                margin-bottom: 10px;
                bottom: -15px;
            }
        }
    }
    .slick-slider.slick-initialized {
        width: 100%;
    }
    .slick-next,
    .slick-prev {
        z-index: 1;
        top: 40% !important;
        background: $lightgray;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        position: absolute;
        margin: 0;
        &.slick-disabled {
            opacity: 0.3;
        }
        &:after {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            border-right: 2px solid $white;
            border-bottom: 2px solid $white;
            transform: rotate(135deg);
            top: 40%;
            right: 15px;
        }
        &:before {
            display: none;
        }
    }

    .slick-next {
        right: -50px;
        &:after {
            transform: rotate(-45deg);
            left: 15px;
        }
    }
    .slick-prev {
        left: -50px;
    }
    @include max-width($tablet-landscape) {
        .slick-next,
        .slick-prev {
            width: 35px;
            height: 35px;
            &:after {
                right: 11px;
            }
        }
        .slick-next {
            right: -15px;
            &:after {
                left: 11px;
            }
        }
        .slick-prev {
            left: -15px;
        }
    }
}
.cards-section {
    .promo_non_active {
        text-align: center;
        padding: 5%;
    }
    .cardsectiontitle-main-div {
        .cardsectiontitle-headline {
            padding-bottom: 0;
        }
    }
    @include max-width($iphonex-landscape) {
        .promo_non_active {
            padding: 10% 0;
        }
    }
    @include max-width($iphone4-landscape) {
        .cardsectiontitle-main-div {
            padding: 5% 7%;
        }
        .promo_non_active {
            padding: 23% 0;
        }
    }
}
.non_active_btn {
    @include green-filled-button;
    &:hover,
    &:focus {
        color: $black-new;
    }
}
.load-more {
    display: block;
    border: 0;
    margin: 0px auto 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: $black-new;
    color: $white;
    text-transform: uppercase;
    font-weight: $font-atten-medium;
    cursor: pointer;
}
