.large-copy__details {
  position: sticky;
  top: var(--large-copy-details-sticky-top, 40px);
  transition: 450ms ease-in-out;
}

.subnav--sticky {
  --large-copy-details-sticky-top: 222px;
}

@media (min-width: 1024px) {
  .large-copy {
    --large-copy-font-size: 40px;
  }

  .large-copy {
    position: relative;
  }

  .large-copy:before {
    background-color: var(--large-copy-background-color, tranparent);
    content: "";
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100vw;
  }

  .large-copy__content {
    position: relative;
    max-width: 1200px; 
    margin-left: 20px; 
    padding-right: 20px;
    text-align: left;
    transform: translateX(5%);
  }
}

@media (min-width: 1600px) {
  .large-copy {
    --large-copy-font-size: 48px;
  }

  .large-copy--details {
    --large-copy-font-size: 48px;
  }
}