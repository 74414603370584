@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.blog-section {
    display: flex;
    // background-color: $bglightgray;
    // padding:5% 5%;
    // margin-left: auto;
    // margin-right: auto;
    // max-width: 1920px;
    // padding: 0 var(--wrap-padding-h-mobile, var(--wrap-padding-h, 32px));

    .blog-content {
        // background-color: $white;
        // padding: min(80px, 8%);
        padding: 0;
        width: 100%;
        // border: 1px solid #DBDBDB;
        .header-section{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-bottom: min(50px, 5%);
            flex-wrap: wrap;
            .heading-section-left{
                width: 35%;
                text-align: left;
                .heading-section{
                    text-align: left;
                    width: 100%;
                }
            }
            .heading-section-right{
                width: 55%;
                display: flex;
                text-align: left;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                padding-bottom: 40px;
                // .title-info{
                //     font-size: 20px;
                //     line-height: 30px;
                //     color: $black-new;
                //     margin: 0;
                //     letter-spacing: -1.1px;
                //     line-height: 30px;
                //     p {
                //       font-size: 20px;
                //       font-family: $font-primary;
                //       margin-bottom: 0;
                //     }
                // }
                // .learn-more{
                //     color: $black-new;
                //     border-bottom: 2px solid $green;
                //     font-size: 24px;
                //     font-family: $font-primary;
                //     font-weight: 600;
                //     margin-top: 10px;
                //     letter-spacing: -1.2px;
                //     line-height: 30px;
                //     &:hover{
                //         text-decoration: none;
                //     }
                // }
            }
        }
        .owl-stage{
            display: flex;
            justify-content: space-between;
        }
        .owl-carousel .owl-stage:after{
            display: none;
        }
        .blog-info{
            height: 100%;
            .blog-img{
                height: 400px;
                margin-bottom:30px;
            }
            h6{
                font-size: 28px;
                line-height: 37px;
                font-weight: $font-atten-medium;
                color:$black-new;
            }

            .blog-text {
                color: #848484;
                font-size: 20px;
                line-height: 1.375;
                margin: 0;
                max-width: var(--media-card-copy-max-width, 50ch);

                p {
                  margin: 0;
                }
            }
        }
        .blog-content-width {
            display: flex;
            .blog-info {
                margin-right: 4.3%;
                &:last-child {
                    margin-right: 0 !important;
                }
            }
        }
        .blog-content-width .blog-info:first-child:nth-last-child(4),  .blog-content-width .blog-info:first-child:nth-last-child(4) ~ div {
            width: 25%;
        }
        .blog-content-width .blog-info:first-child:nth-last-child(3),  .blog-content-width .blog-info:first-child:nth-last-child(3) ~ div {
            width: 33.33%;
        }
        .blog-content-width .blog-info:first-child:nth-last-child(2),  .blog-content-width .blog-info:first-child:nth-last-child(2) ~ div {
            width: 50%;
        }
        .blog-content-width .blog-info:first-child:nth-last-child(1) {
            width: 100%;
        }
        @include max-width($desktop-xl) {
            .blog-info{
                .blog-img{
                    height: 400px;
                }
            }
        }
        @include max-width($desktop-large) {
            .header-section{
                // .heading-section{
                //     .main-title {
                //         font-size: 42px;
                //     }
                // }
                // .heading-section-right{
                //     .learn-more{
                //         font-size: 18px;
                //         letter-spacing: -1px;
                //         line-height: 24px;
                //     }
                // }
            }
            .blog-info{
                .blog-img{
                    height: 340px;
                    margin-bottom:25px;
                }
                h6{
                    font-size: 22px;
                    line-height: 32px;
                }
            }
            .blog-content-width {
                .blog-info {
                    margin-right: 2.5%;
                }
            }
    }
        @include max-width($macbook) {
            .blog-info{
                .blog-img{
                    height:321px;
                }
            }
        }
        @include max-width($tablet-landscape) {
            .blog-info-div{
                .owl-stage{
                     padding-left: 0px !important;
                }
            }
            .blog-info{
                .blog-img{
                    height:371px;
                }
            }
        }
        @include max-width($iphonex-landscape) {
            .header-section{
                padding-bottom: 40px;
                .heading-section-left{
                    width: 100%;
                }
                .heading-section-right{
                    width: 100%;
                    padding-bottom: 20px;
                }
            }
            .blog-info{
                .blog-img{
                    height: 324px;
                }
            }
        }
        @include max-width($iphone6-landscape) {
            border: unset;
            .owl-stage{
                justify-content:unset;
            }
            .blog-info{
                .blog-img{
                    height: 321px;
                }
            }
        } 
    }

    @media (max-width: 1023px) {
      .blog-content .header-section .heading-section-right {
      }

      // .blog-content .header-section .heading-section-right .title-info {
      //   font-size: 20px;
      //   line-height: 1.5;
      //   margin-bottom: 0;
      // }

      .blog-content .blog-content-width {
        flex-direction: column;
      }

      .blog-content .blog-content-width .blog-info + .blog-info {
        margin-top: 50px;
      }

      .blog-content .blog-content-width .blog-info h6 {
        font-family: var(--font-family-medium);
        font-size: var(--media-card-title-font-size, 22px);
        font-weight: 500;
      }

      .blog-content .blog-content-width .blog-text {
        font-size: 16px;
      }

      .blog-content .blog-content-width .blog-info,
      .blog-content .blog-content-width .blog-info:first-child:nth-last-child(3),
      .blog-content .blog-content-width .blog-info:first-child:nth-last-child(3)~div {
        margin-right: 0;
        min-width: 100%;
      }
    }

    @media (max-width: 1399px) {
      .blog-content {
        border: none;
        // padding: 0;
      }
    }
}
