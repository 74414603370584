@import '../../assets/scss/partials/variables';
@import '../../assets/scss/partials/mixins';
@import '../../assets/scss/partials/fonts';

.blocks-image .row {
    margin-left: 0;
    margin-right: 0;
}

.blocks-image {
    // padding:3% 0%;
    // margin: 0;
    // &.row {
    //     margin-right: 0px;
    //     margin-left: 0px;
    // }
    .four-blocks {
        // padding:5%;
        margin: 0;
        padding: 0;

        .block-info {
            padding: 4% 5%;
        }
        .icon-image {
            width: 50px;
            height: 50px;
            margin-bottom: 20px;
        }
        .title {
            font-size: 28px;
            margin-bottom: 20px;
            font-weight: $font-atten-medium;
            color: $black-new;
        }
        .text {
            font-size: 20px;
            margin-bottom: 20px;
            color: $black-new;
            line-height: 30px;
        }
        .learn-more {
            color: $black-new;
            border-bottom: 2px solid $green;
            font-size: 24px;
            font-weight: $font-atten-medium;
            letter-spacing: -1.2px;
            line-height: 30px;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .side-image {
        padding: 0 !important;
    }
    @include max-width($desktop-large) {
        .four-blocks {
            .block-info {
                padding: 35px 20px;
            }
            .title {
                font-size: 26px;
                margin-bottom: 15px;
            }
            .text {
                margin-bottom: 15px;
            }
            .learn-more {
                font-size: 18px;
                letter-spacing: -1px;
                line-height: 24px;
            }
        }
    }
    @include max-width($tablet-landscape) {
        .four-blocks {
            .title {
                font-size: 24px;
            }
        }
    }
    @include max-width($iphonex-landscape) {
        padding: 0;
        .special-case {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }

        .four-blocks {
            .icon-image {
                width: 25px;
                height: 25px;
                margin-right: 20px;
                margin-bottom: 0;
            }
            .title {
                margin-bottom: 0;
            }
        }
        .side-image {
            height: 387px;
            order: -1;
        }
    }
    @include max-width($iphone4-landscape) {
        .four-blocks {
            padding: 5% 10%;
            .block-info {
                padding: 35px 0px;
            }
        }
    }
}
