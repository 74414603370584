@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.posrev {
  display: flex;
  flex-direction: row-reverse;
}

.iframe-main-div {
  background-color: $bggray-new;
  display: flex;
  flex-wrap: wrap;

  .heading-section {
    text-align: left;
    width: 100%;
  }

  .iframe-button {
    margin-top: 20px;
  }

  .iframe-content {
    align-self: center;
  }

  .iframe-content-section {
    max-width: 960px;
    padding: 60px var(--wrap-padding-h-desktop, var(--wrap-padding-h, 32px));

    @media (min-width: 1024px) {
      margin-left: auto;
      padding-right: max(8vw, var(--wrap-padding-h-desktop, var(--wrap-padding-h, 32px)));
    }
  }

  &.posrev .iframe-content-section {
    @media (min-width: 1024px) {
      margin-left: 0;
      margin-right: auto;
      padding-left: max(8vw, var(--wrap-padding-h-desktop, var(--wrap-padding-h, 32px)));
      padding-right: var(--wrap-padding-h-desktop, var(--wrap-padding-h, 32px));
    }
  }

  .iframe-modal-button {
    bottom: 5%;
    left: 5%;
    position: absolute;
  }

  .iframe-modal-image {
    cursor: pointer;
  }

  .iframe-view-more {
    -webkit-appearance: none;
    align-items: center;
    background:$black-new;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    color: $white;
    display: flex;
    // font-family: $atten-heavy;
    font-size: 26.5px;
    height: 64px;
    justify-content: center;
    text-align: center;
    transition: 250ms ease-in-out;
    width: 64px;
  }

  .iframe-view-more:is(:focus, :hover) {
    background-color: #333;
    color: $white;
  }

  .iframe-view-more:focus:not(:focus-visible) {
    outline: none;
  }

  .divide-equal:first-child:nth-last-child(2),
  .divide-equal:first-child:nth-last-child(2) ~ div {
    width: 50%;
  }

  .divide-equal:first-child:nth-last-child(1) {
    width: 100%;
  }

  @include max-width($iphonex-landscape) {
    .iframe-content {
      width: 100% !important;
    }

    .iframe-image {
      margin-top: 3%;
      width: 100% !important;
    }

    .iframe-modal-button {
      bottom: 10%;
    }
  }

  @include max-width($iphone4-landscape) {
    .iframe-image {
      margin-top: 0;
    }
  }
}