.media-tabs__tab--active,
.media-tabs__tab:focus,
.media-tabs__tab:hover {
    --media-tabs-copy-color: var(--media-tabs-copy-color-active, #525252);
    --media-tabs-title-color: var(--media-tabs-copy-color-active, --black);
}

[data-state='autoplay'] .media-tabs__tab--completed .media-card__border-top {
    background-color: var(--black) !important;
}

@media (min-width: 1024px) {
    .media-tabs {
        --media-tabs-gallery-item-col-margin-h: 10px;
        --media-tabs-gallery-item-display: flex;
        --media-tabs-gallery-item-margin-right: 20px;
        --media-tabs-gallery-item-row-gap: 0;
        --media-tabs-gallery-item-large-width-percentage: 58.427%;
        --media-tabs-gallery-item-opacity: 0;
        --media-tabs-gallery-item-pointer-events: none;
        --media-tabs-gallery-item-transform: translateY(20px);
        --media-tabs-gallery-item-transition: 700ms ease-in-out;
        --media-tabs-gallery-item-small-width-percentage: 41.573%;
        --media-tabs-gallery-item-position: absolute;
    }

    .media-tabs__group--active {
        --media-tabs-gallery-item-opacity: 1;
        --media-tabs-gallery-item-pointer-events: auto;
        --media-tabs-gallery-item-transform: translateY(0);
    }

    .media-tabs__tab--active {
        --media-tabs-progress-scale-x: 1;
        --media-tabs-progress-transition-duration: var(--media-tabs-active-duration, 7s);
    }

    [data-state='paused'] .media-tabs__tab--active {
        --media-tabs-progress-transition-duration: 500ms;
    }
}
