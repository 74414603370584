@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

@media (min-width: 1024px) {
  .form-grid__lockup-copy {
    border-top: 1px solid #848484;
    margin-top: 21px;
    padding-top: 24px;
  }
}

.form-grid__lockup-copy img {
  display: none;
}


@media (max-width: 1023px) {
  .form-grid__lockup {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  .form-grid__form {
    order: -1;
    margin-bottom: 50px;
    margin-top: -30px;
  }
}

// Form
.mortgage-calculator {
  .form-group {
    margin-bottom: 5px;
  }

  .form-label {
    color: $darkgray;
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  input.mortgage_field {
    padding: 0 8px !important;
  }

  .form-control {
    background-color: $bglightgray;
    box-shadow: unset;
    border: 1px solid #ced4da;
    padding: 0 5px;
    color: #242424;

    &:focus {
      background-color: $bglightgray;
    }
  }

  @include max-width($desktop-large) {
    .form-label {
      font-size: 10px;
    }
  }
}
