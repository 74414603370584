@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.promo_plan-banner {
    //height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    padding-bottom: 140px;
    .hero-logo{
        //height: 300px;
        width: 500px;
        z-index: 555;
        margin-top: 4%;
        img{
            object-fit: fill;
        }
    }
    .promo-subtext{
        font-size: 30px;
        color: #fff;
        width: 30%;
        text-align: center;
        padding: 2% 0;
    }
   
    
    .dropdown {
        width: 320px;

        background-color: #fff;
        margin-bottom: 10%;
        position: relative;
        .dropdown-header {
            padding: 15px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            z-index: 11;
            font-weight: 600;
            .icon {
                font-size: 13px;
                color: #91A5BE;
                transform: rotate(90deg);
                transition: all .2s ease-in-out;
                &.open {
                    transform: rotate(90deg);
                }
            }
        }
        .dropdown-body {
            padding: 5px;
            // border-top: 1px solid #E5E8EC;
            display: none;
            position: absolute;
            width: 100%;
            height: 300px;
             box-shadow: 0 10px 25px rgba(0,0,0,.1);
            z-index: 99;
            background-color: #fff;
            top: 100%;
            overflow: auto;
            &.open{
                display: block;
            }
            .dropdown-item {
                padding: 10px;
                .dropdown-item-dot {
                    opacity: 0;
                    color: #91A5BE;
                    transition: all .2s ease-in-out;
                    &.selected {
                        opacity: 1;
                      }
                }
            }
        }

    }
    
    
    @include max-width($desktop-large) {
        .hero-logo{
            //height: 200px;
            width: 400px;
        }
    }
    @include max-width(1366px){
        .hero-logo{
            //height: 200px;
            width: 400px;
        }
    }
   
   
   
    @include max-width($iphone6-plus-landscape) {
        // padding: 10% 0;
        .hero-logo{
            //height: 146px;
            width: 305px;
            }
        .promo-subtext{
            width: 100%;
        }
    }
    @include max-width($iphone4-landscape){
        padding: 25% 0 15%;
        .promo-subtext{
            font-size: 24px;
            width: 90%;

        }
    }
    @include max-width($iphone6){
        .hero-logo{
            //height: 102px;
            width: 230px;
        }
    }
    @media (max-width:991px) and (orientation: landscape){
        .hero-logo{
            //height: 120px;
            width: 220px;
        }
    }
   
    @media (max-width:812px) and (orientation: landscape) {
        .hero-logo{
            //height: 110px;
            width: 189px;    
        }
    }
    @media (max-width:768px) and (orientation: landscape) {
        .hero-logo{
            //height: 120px;
            width: 204px;
        }
        
    }
    @media (max-width:736px) and (orientation: landscape) {
        // padding-bottom: 0;
        .hero-logo{
            //height: 94px;
            width: 211px;
        }
    }
}
.promotion_cards{
    .promotion_card_section{
        padding: 4% 5% 10%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 90%;
        margin: -100px auto 0;
        background-color: #fff;
        @include max-width($iphone4-landscape){
            margin: -45px auto 0;
            padding: 8% 5% 10%;
        }
        .promotion_eyebrow_text{
            font-weight: 500;
            font-size: 23px;
            text-align: center;
            letter-spacing: 2px;
            margin-bottom: 2%;
            @include max-width($iphone4-landscape){
                margin-bottom: 5%;
            }
        }
        .promotion_card_headline{
            font-size: 60px;
            font-weight: 600;
            text-align: center;
            @include max-width($iphone6-plus-landscape) {
                font-size: 42px;
            }
            @include max-width($iphone4-landscape){
                font-size: 30px;
            }
        }
        .dropdown {
            width: 320px;
            background-color: #fff;
            margin: 4% 0%;
            position: relative;
            border: 1px solid #c9c0c0;
            @include max-width($iphone4-landscape){
                width: 280px;
            }
            .dropdown-header {
                padding: 15px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                z-index: 11;
                font-weight: 600;
                letter-spacing: 1.5px;
                @include max-width($iphone4-landscape){
                    letter-spacing: 0px;
                }
                .icon {
                    font-size: 13px;
                    color: #91A5BE;
                    transform: rotate(90deg);
                    transition: all .2s ease-in-out;
                    &.open {
                        transform: rotate(90deg);
                    }
                }
            }
            .dropdown-body {
                padding: 5px;
                // border-top: 1px solid #E5E8EC;
                display: none;
                position: absolute;
                width: 100%;
                height: 300px;
                 box-shadow: 0 10px 25px rgba(0,0,0,.1);
                z-index: 99;
                background-color: #fff;
                top: 100%;
                overflow: auto;
                &.open{
                    display: block;
                    border: 1px solid #c9c0c0;
                    &::-webkit-scrollbar{
                        width:0;
                    }
                }
                .dropdown-item {
                    padding: 10px;
                    .dropdown-item-dot {
                        opacity: 0;
                        color: #91A5BE;
                        transition: all .2s ease-in-out;
                        &.selected {
                            opacity: 1;
                          }
                    }
                }
            }
    
        }
        .promo_cards_section{
            margin-top: 5%;
            width: 100%;
            position: relative;
            .no_promotion_text{
                max-width: 500px;
                text-align: center;
                margin: 0 auto;
                font-size: 17px;
                line-height: 27px;
                font-weight: 600;
            }
            .noPromo{
                text-align: center;
                font-weight: 500;
            }
            .owl-stage {
                display: flex;
                &:after{
                    display: none;
                }
            }
            .owl-nav{
                position: absolute;
                top: 50%;
                width: 100%;
                margin: 0;
                margin-top: -17px;
                &:hover{
                    background: transparent;
                }
                button{
                    // border-radius: 50% !important;
                    position: absolute;
                    margin: 0;
                    &:after{
                        position: absolute;
                        content: "";
                        width: 10px;
                        height: 10px;
                        border-right: 2px solid #000;
                        border-bottom: 2px solid #000;
                        transform: rotate(-45deg);
                        top: 43%;
                        right: 13px;
                    }
                    span{
                        display: none;
                    }
                }
                .owl-prev{
                    left: -7%;
                    .sliderArrow {
                        height: 36px;
                        width: 30px;
                        border: 1px solid #ccc;
                        box-shadow: 3px 3px 3px #ccc;
                    }
                    &:after{
                        transform: rotate(135deg);
                        left: 12px;
                    }
                }
                .owl-next{
                    right: -7%;
                    .sliderArrow {
                        height: 36px;
                        width: 30px;
                        border: 1px solid #ccc;
                        box-shadow: 3px 3px 3px #ccc;
                    }
                }
    }
                .owl-dots{
                    display: none;
                }
                @include max-width($desktop-large) {
                    .owl-nav{
                        button{
                            &:after{
                                width: 10px;
                                height: 10px;
                                right: 12px;
                            }
                        }
                        .owl-prev{
                            &:after{
                                left: 12px;
                            }
                        }
                    }
                }
                @include max-width($iphone6-plus-landscape){
                    .owl-nav{
                        button{
                            &:after{
                                width: 10px;
                                height: 10px;
                                right: 12px;
                            }
                        }
                        .owl-prev{
                            left: -6px;
                        }
                        .owl-next{
                            right: -12px;
                        }
                    }
                }
                @include max-width($iphone4-landscape) {
                    .owl-nav{
                        button{
                            &:after{
                                width: 10px;
                                height: 10px;
                                right: 12px;
                            }
                        }
                        .owl-prev{
                            &:after{
                                left: 12px;
                            }
                        }
                    }
                    .owl-dots {
                        display: none;
                        position: absolute;
                        bottom: -50px;
                        width: 100%;
                        padding-bottom: 10px;
                        .owl-dot{
                            span{
                                background:#ccc !important;
                                width: 8px;
                                height: 8px;
                            }
                            &.active{
                                span{
                                    background: #000 !important;
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                        }
                    }
                }
                .promo_cards{
                    flex-direction: row;
                    display: flex;
                    justify-content: center;
                    @include max-width($iphone4-landscape) {
                        flex-direction: column;
                        .card_box{
                            width: 100%;
                        }
                    }
                }
                .card_box{
                    margin-left: 5px;
                    // margin-top: 2%;
                    padding: 7%;
                    text-align: center;
                    // width: 22%;
                    background-color: #EBEBEB;
                    .promo_card_title{
                        padding: 10%;
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                    .promo_card_btn{
                        border: 1px solid #fff;
                        color: #545454;
                        width: 65%;
                        background-color: #fff;
                        padding: 3% 10%;
                        text-align: center;
                        font-family: "Atten Heavy",Arial,sans-serif;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        margin: 15px auto;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }
        }
    }

}