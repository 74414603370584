@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.blog_listing_container {
    .blog-cover-section {
        background-color: $bggray-new;
        .blog-cover-content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .blog-cover-info {
                padding: 5%;
                .header-section {
                    width: 95%;
                    .heading-section {
                        text-align: left;
                        width: 100%;
                        margin: unset;
                        .sub-title {
                            color: $green;
                            font-size:16px;
                            letter-spacing: 4.8px;
                            line-height: 25px;
                        }
                        .main-title {
                            padding: 15px 0;
                            a{
                                text-decoration: none;
                                color: $gray-new;   
                            }
                        }
                    }
                    .title-info {
                        color: $black-new;
                        font-size: 20px;
                        line-height: 30px;
                        margin: 0;
                        padding: 10px 0 10px;
                    }
                    p {
                        margin: 0;
                        padding: 0;
                    }
                    .read_more {
                        @include white-learn-more;
                        display: inline-block;
                        margin-top: 20px;
                        min-width: 314px;
                        &.no-link {
                            pointer-events: none;
                        }
                        &:hover {
                            background-color: $green;
                            border: 1px solid $green;
                        }
                    }
                }
            }
            .blog-cover-img {
                padding: 5%;
                padding-right: 0.5%;
                img {
                    height: 536px;
                }
            }
            .divide-equal:first-child:nth-last-child(2),
            .divide-equal:first-child:nth-last-child(2)~div {
                width: 50%;
            }
            .divide-equal:first-child:nth-last-child(1) {
                width: 100%;
            }
            @include max-width($desktop-xl) {
                .blog-cover-img {
                    img {
                        height: 450px;
                    }
                }
            }
            @include max-width($desktop-large) {
                .blog-cover-info {
                    .header-section {
                        .heading-section {
                            .main-title {
                                padding-bottom: 5px;
                            }
                        }
                        .read_more {
                            min-width: 250px;
                            letter-spacing: 2px;
                        }
                    }
                }
            }
            @include max-width($tablet-landscape) {
                .blog-cover-info {
                    .header-section {
                        width: 80%;
                    }
                }
                .blog-cover-img {
                    img {
                        height: 370px;
                    }
                }
            }
            @include max-width($iphonex-landscape) {
                .blog-cover-img {
                    width: 100% !important;
                    padding-bottom: 0;
                    padding-right: 5%;
                    img {
                        height: 300px;
                    }
                }
                .blog-cover-info {
                    width: 100% !important;
                    .header-section {
                        width: 100%;
                        .heading-section {
                            .sub-title {
                                padding-top: 15px;
                                padding-bottom: 20px;
                                font-size: 14px;
                                letter-spacing: 4.2px;
                            }
                        }
                    }
                }
            }
            @include max-width($iphone6-landscape) {
                .blog-cover-img {
                    img {
                        height: 262px;
                    }
                }
                .blog-cover-info {
                    .header-section {
                        width: 100%;
                        .heading-section {
                            .main-title {
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }
                            .sub-title {
                                padding-top: 20px;
                            }
                        }
                        .title-info {
                            padding: 0;
                        }
                        .read_more {
                            width: 100%;
                            margin-top: 55px;
                            margin-bottom: 35px;
                        }
                    }
                }
            }
        }
    }
    .category_list {
        display: flex;
        justify-content: center;
        padding: 3%;
        border-bottom: 1px solid $new-border-color;
        color: $black-new;
        flex-wrap: wrap;
        .category_dropdown_list {
            width: 100%;
            padding: 12px 20px;
            border: 2px solid $new-border-color;
            -webkit-appearance: none;
            background: no-repeat calc(100% - 20px);
            text-transform: uppercase;
        }
        .cat_list_item {
            margin-right: 5%;
            cursor: pointer;
            font-weight: 500;
            padding: 1% 0;
            font-size: 18px;
            color: $black-new;
            text-decoration: none;
            &:hover {
                color: $green;
            }
            &.active {
                color: $green;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        @include max-width($tablet-landscape) {
            .cat_list_item {
                margin-right: 4%;
            }
        }
        @include max-width($iphonex-landscape) {
            display: block;
            padding: 40px 5%;
            border: none;
            .cat_list_item {
                display: inline-block;
                width: 50%;
                margin: 0;
                padding: 3px 0;
            }
        }
    }
}


// blog details page
.blog-content-container{
    .blog-content{
        p{
            font-size: 22px;
            width: 95%;
            padding: 0 5% 5%;
            margin: auto;
            padding-bottom: 2%;
        }
        h3{
            font-weight: $font-atten-medium;
            width: 85%;
            margin: auto;
            padding-bottom: 1%;
            font-size: 30px;
            line-height: 42px;
        }
        h1,h2,h4,h5,h6{
            font-weight: $font-atten-medium;
            width: 85%;
            margin: auto;
            padding-bottom: 1%;
            line-height: 42px;
        }
        h3.social_icons  {
            a {
                color: $black-new !important;
                font-size: 25px;
                padding: 0;
                padding-bottom: 5%;
                display: inline-block;
                padding-right: 15px;
                @include max-width($iphonex-landscape) {
                    font-size: 30px;
                }
            }
    
        }
        a,a:hover {
            color:$green;
        }
        .blog_btn{
            font-size: 22px;
            width: 95%;
            margin: auto;
            padding: 0 5% 2%;
            a{
            background-color: #bed245!important;
            border: 2px solid #bed245!important;
            color: #545454!important;
            box-shadow: none;
            font-size: 13px!important;
            letter-spacing: 3.9px;
            font-family: "Atten Heavy",Arial,sans-serif!important;
            text-transform: uppercase;
            min-width: 260px;
            border-radius: 0;
            font-size: 20px;
            padding: 10px 5px;
            line-height: 25px;
            text-decoration: none;
            text-align: center;
            display: inline-block;
            font-size: 16px!important;
            letter-spacing: 3.2px;
            line-height: 30px;
            cursor: pointer;
            }
        }
        @include max-width($iphone4-landscape){
            .blog_btn{
                a{
                    display: flex;
                    justify-content: center;
                }
            }
        }
        ul{
            padding: 0 10%;
        }
        .author_name_date {
            font-size: 14px;
            text-transform: capitalize;
            line-height: 20px;
            padding-bottom: 20px;
            width: 85%;
            margin: auto; 
            @include max-width($iphone4-landscape) {
                font-size: 16px;
                width:100%;
            }
        }
        .wp-block-columns{ 
            display: flex;
            margin: auto;
            width: 95%;
            padding: 0 5% 2%;
            .wp-block-column {
                margin: 0 20px;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            &.wp-block-columns1 {
                .wp-block-column {
                    width:100%;
                }
            }
            &.wp-block-columns2 {
                .wp-block-column {
                    width:50%;
                }
            }
            &.wp-block-columns3 {
                .wp-block-column {
                    width:33.3%;
                }
            }
            &.wp-block-columns4 {
                .wp-block-column {
                    width:25%;
                }
            }
            @include max-width($iphone4-landscape) {
                display: block;
                .wp-block-column {
                    width: 100% !important;
                    margin: 20px 0;
                  }
              }
           }
    }

        @include max-width($desktop-large) {
        .blog-content{
            p{
                font-size: 20px;
            }
        }
        }
    

        @include max-width($iphone4-landscape) {
            .blog-content{
                padding: 5%;
                p{
                    padding: 0;
                    width: 100%;
                    padding-bottom: 5%;
                }
                h3{
                    width: 100%;
                }
            }
        }
        .blog-cover-detail-content{
            background-color: #f7f7f7;
        .blog-cover-content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-bottom: 5%;
            .blog-cover-info {
                padding: 5%;
                .header-section {
                    width: 95%;
                    .heading-section {
                        text-align: left;
                        width: 100%;
                        margin: unset;
                    }
                    .sub-title{
                        color:$green;
                        font-size:16px;
                        letter-spacing: 4.8px;
                        line-height: 25px;
                    }
                    .main-title{
                        padding: 15px 0;
                    }
                    .title-info {
                        color: $black-new;
                        font-size: 20px;
                        line-height: 30px;
                        margin: 0;
                        padding: 10px 0 10px;
                    }
                    .author_name_date {
                        font-size: 14px;
                        text-transform: capitalize;
                        line-height: 20px;
                    }
                    p {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
            .blog-cover-img {
                img {
                    height: 701px;
                }
            }
            .divide-equal:first-child:nth-last-child(2),
            .divide-equal:first-child:nth-last-child(2)~div {
                width: 50%;
            }
            .divide-equal:first-child:nth-last-child(1) {
                width: 100%;
            }
            @include max-width($desktop-xl) {
                .blog-cover-img {
                    img {
                        height: 650px;
                    }
                }
            }
            @include max-width($desktop-large) {
                .blog-cover-info {
                    .header-section {
                        .heading-section {
                            .main-title {
                                padding-bottom: 5px;
                            }
                        }
                    }
                }
                .blog-cover-img {
                    img {
                        height: 510px;
                    }
                }
            }
            @include max-width($tablet-landscape) {
                .blog-cover-info {
                    .header-section {
                        width: 80%;
                    }
                }
                .blog-cover-img {
                    img {
                        height: 485px;
                    }
                }
            }
            @include max-width($iphonex-landscape) {
                padding-bottom: 0;
                .blog-cover-img {
                    width: 100% !important;
                    padding-bottom: 0;
                    img {
                        height: 386px;
                    }
                }
                .blog-cover-info {
                    width: 100% !important;
                    padding-bottom: 0;
                    .header-section {
                        width: 100%;
                        .heading-section {
                            .sub-title {
                                padding-top: 15px;
                                padding-bottom: 20px;
                                font-size: 14px;
                                letter-spacing: 4.2px;
                            }
                        }
                    }
                }
            }
            @include max-width($iphone6-landscape) {
                .blog-cover-info {
                    .header-section {
                        width: 100%;
                        .heading-section {
                            .main-title {
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }
                            .sub-title {
                                padding-top: 20px;
                            }
                        }
                        .title-info {
                            padding: 0;
                        }
                    }
                }
            }
        }
        .social_icons {
            a {
            color: $black-new !important;
            font-size: 25px;
            padding-right: 15px;
            padding-top: 5%;
            display: inline-block;
            @include max-width($iphonex-landscape) {
                padding-top: 3%;
            }
        }
                
        }
    }
    .category_list {
        display: flex;
        justify-content: flex-start;
        padding: 2% 10% 1%;
        width: 85%;
        color: $black-new;
        flex-wrap: wrap;
        text-align: center;
        .cat_list_item {
            margin-right: 4%;
            cursor: pointer;
            font-weight: 500;
            font-size: 18px;
            padding-bottom: 1%;
            color: $black-new;
            &:hover {
                text-decoration: none;
                color: $green;
            }
            &.active {
                color: $green;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        @include max-width($desktop-large) {
            padding: 2% 4% 1%;
        }
        @include max-width($macbook) {
            padding: 2% 2% 1%;
        }
        @include max-width($tablet-landscape) {
            width: 78%;
            .cat_list_item {
                margin-right: 4%;
            }
        }
        @include max-width($iphonex-landscape) {
            display: none;
        }
    }
    }
    
.blogs-section{
    .blog_card_container {
        padding: 0 5%;
        padding-right: 1%;
        display: flex;
        flex-wrap: wrap;
        .blog_card {
            width: 30%;
            margin-right: 3%;
            .blog_card_img {
                height: 500px;
            }
        }
        .blog_card_content {
            padding-bottom: 10%;
            .blog_card_category {
                padding: 4% 0;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 1.6px;
                color: $gray-new;
            }
            .blog_card_content_para {
                font-size: 18px;
                a {
                    color: $green;
                }
            }
            .read_more {
                text-decoration: none;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: $font-atten-bold;
                position: relative;
                color:$green;
                &::before{
                    content: " ";
                    position: absolute;
                    top: 50%;
                    left: auto;
                    width: 7px;
                    height: 7px;
                    border-top: 2px solid $green;
                    border-right: 2px solid $green;
                    -webkit-transform: translate(-1px,-50%) rotate(45deg);
                    transform: translate(-1px,-50%) rotate(45deg);
                    right: -30px;
                }

                &::after {
                    background: $green;
                    -webkit-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                    height: 2px;
                    width: 13px;
                    content: " ";
                    position: absolute;
                    top: 50%;
                    left: auto;
                    right: -34px;
                }
                &:hover {
                    color: $green;
                }
            }
            @include max-width($iphone4-landscape) {
                padding-bottom: 7%;
                .blog_card_category {
                    padding: 25px 0;
                }
            }
        }
        .blog_card_content_caption {
            h3 {
                font-size: 30px;
                font-weight: $font-atten-bold;
                line-height: 32px;
                a{
                    text-decoration: none;
                    color: $black-new ;
                }
            }
            
        }
        @include max-width($desktop-large) {
            .blog_card {
                .blog_card_img {
                    height: 400px;
                }
            }
            .blog_card_content {
                .blog_card_content_caption {
                    h3 {
                        font-size: 24px;
                    }
                }
            }
        }
    
    @include max-width($tablet-landscape) {
        .blog_card {
            .blog_card_img {
                height: 370px;
            }
        }
    }
    @include max-width($iphonex-landscape) {
        .blog_card {
            width: 45%;
            margin-right: 5%;
            .blog_card_img {
                height: 335px;
            }
        }
        .blog_card_content {
            .blog_card_content_caption {
                h3 {
                    font-size: 30px;
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .blog_card {
            width: 100%;
        }
    }
}
.author_container{
    padding: 4% 9%;
    .author_content_box{
        display: flex;
        .author-image{
            width: 23%;
            text-align: center;
            img{
                max-width: 87%;
                object-fit: contain;
            }
        }
        .author_details_box{
            width: 70%;
            .author_content{
                padding: 5% 2%;
                .author-title{
                    font-weight: 600;
                }
            }
            .author-social-icons a{
                color: #545454!important;
                font-size: 25px;
                display: inline-block;
                padding: 0 15px 5% 0;
            }
        }
        @include max-width($iphone4-landscape) {
            flex-direction: column;
            .author-image{
                width: 100%;
                text-align: center;
            }
            .author_details_box{
                width: 100%;
                .author_content{
                    padding: 10%;
                }
            }
        }
        @media (max-width:897px) and (orientation: landscape) {
            .author-image{
                width: 28%;
                img{
                    max-width:100%;
                }
            }
        }
    }
}
    .card_heading_content {
        padding: 5%;
        padding-bottom: 2%;
        .brand_heading {
            color: $green;
            text-transform: uppercase;
            font-weight:500;
            font-size: 14px;
            letter-spacing: 4.8px;
            line-height: 25px;
        }
        .latest_post_heading {
            font-size: 68px;
            line-height: 70px;
            font-weight: 500;
        }
        @include max-width($desktop-large) {
            .latest_post_heading {
                font-size: 54px;
            }
        }
        @include max-width($iphonex-landscape) {
            .brand_heading {
                letter-spacing: 4.2px;
            }
            .latest_post_heading {
                font-size: 48px;
            }
        }
        @include max-width($iphone4-landscape) {
            padding-top: 8%;
        }
    }
}