@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.image-box-main{
    // padding: 5% 0;
    background-color: $bglightcream;
    .image-box{
        background-size: cover;
        min-height: 400px;
        padding-left: 5%;
        padding-bottom: 5%;
        display: flex;
        position: relative;
        align-items: flex-end;
        height:1096px;
        z-index: 0;
            &.add_overlay::after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom:0;
                right: 0;
                background:$overlayblack;
                z-index: -1;
            }
            .video-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                background: $black-new;
                #click-to-unmute{
                    display: none;
                }
            }
            .image-box-content {
                color:$white;
                width:50%;
                .image-heading{
                        font-size: 80px;
                        padding-bottom:50px;
                        font-weight: $font-atten-medium;
                        word-break: break-word;
                        margin: 0;
                        letter-spacing: -4px;
                        font-weight: 500;
                        line-height: 80px;
                }
                .image-button{
                    @include white-learn-more;
                    text-decoration: none;
                    min-width: 314px;
                    display: inline-block;
                    background-color: $white;
                    &.no-link{
                        pointer-events: none;
                    }
                    &:hover{
                        background-color:$green;
                        border:1px solid $green;
                    }
                }

            }
    @include max-width($desktop-xl) {
         height: 891px;
         .image-box-content{
            width: 50%;
            .image-heading{
                font-size: 52px;
                line-height: 60px;
                letter-spacing: -2px;
            }
         }
    }
    @include max-width($desktop-large) {
        height: 700px;
        .image-box-content{
            .image-heading{
                font-size: 40px;
                line-height: 48px;
            }
            .image-button{
                font-size: 16px;
                letter-spacing: 2px;
                min-width:250px;
            }
        }
    }
    @include max-width($tablet-landscape) {
        height: 585px;
        .video-box {
            display: none;
        }
        .image-box-content{
                width:60%;
                .image-heading{
                    font-size: 52px;
                    line-height: 54px;

                }
        }
    }
    @include max-width($iphonex-landscape) {
        height: 890px;
        .image-box-content{
            width:80%;
        }
    }
    @include max-width($iphone4-landscape) {
            padding:15% 10%;
            .image-box-content{
                width:100%;
                .image-button{
                    width:100%;
                }

            }
        }
    }

    .promo_image-box{
        background-size: cover;
        min-height: 400px;
        padding-bottom: 5%;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height:1096px;
        z-index: 0;
            &.add_overlay::after{
                content: ""; 
                position: absolute;
                top: 0;
                left: 0;
                bottom:0;
                right: 0;
                background:$overlayblack;
                z-index: -1;
            }
            .video-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: $black-new;
                #click-to-unmute{
                    display: none;
                }
            }
            .hero-logo{
                z-index: -1;
                width: 45%;
            }
            .image-box-content {
                color:$white;
                width:30%;
                text-align: center;
                .image-heading{
                        font-size: 40px;
                        padding-bottom:50px;
                        word-break: break-word;
                        margin: 0;
                        // letter-spacing: -4px;
                        font-weight: 500;
                        // line-height: 80px;
                }
                .image-button{
                    @include white-learn-more;
                    text-decoration: none;
                    min-width: 314px;
                    display: inline-block;
                    background-color: $white;
                    &.no-link{
                        pointer-events: none;
                    }
                    &:hover{
                        background-color:$green; 
                        border:1px solid $green;
                    }
                }

            }
        @include max-width($desktop-xl) {
            height: 891px;
            .image-box-content{
                width: 50%;
                .image-heading{
                    font-size: 35px;
                    line-height: 60px;
                    // letter-spacing: -2px;
                }
            }
        }  
        @include max-width($desktop-large) {
            height: 700px;
            .image-box-content{
                .image-heading{
                    font-size: 35px;
                    // line-height: 48px;
                }
                .image-button{
                    font-size: 16px;
                    letter-spacing: 2px;
                    min-width:250px;
                }
            }   
        }  
        @include max-width($tablet-landscape) {
            height: 585px;
            .video-box {
                display: none;
            }
            .image-box-content{
                    width:60%;
                    .image-heading{
                        font-size: 35px;
                        // line-height: 54px;
                    }
            }
        }
        @include max-width($iphonex-landscape) {
            height: 890px;
            .image-box-content{
                width:80%;   
            }
        }
        @include max-width($iphone4-landscape) {
                height: auto;
                padding-top: 80px;
                .hero-logo{
                    width: 100%;
                    margin-top: 13%;
                }
                .image-box-content{
                    width:84%;
                    .image-heading{
                        font-size: 20px;
                        line-height: 30px;
                        // letter-spacing: 1px;
                    }
                    .image-button{
                        width:100%;
                    }

                }
        }   
    }


    @include max-width($iphonex-landscape) {
        // padding: 10% 0;
        .image-box-content{
            width:100%;
        }
    }
}