@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.direction-main-div{
    background:$wildsand;
    padding:8% 5%;
    .main-header{
        display:flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-bottom:40px;
        .direction-header{
            width:70%;
            .direction-heading{
                font-size:64px;
                color:$green;
                line-height: 70px;
                font-weight: $font-atten-medium;
            }
            .direction-subtext{
                font-size:28px;
                color:$black-new;
            }
        }
    }
    .direction-content-image{
        display:flex;
        .direction-content{
            width:50%;
            background:$white;
            color:$black-new;
            .hours-direction{
                padding:10%;
                width:90%;
                .hours-main{
                  margin-bottom: 30px;
                  &:last-child{
                    margin-bottom: 0 !important;
                }
                }
                .hours-heading{
                    font-size: 28px;
                    padding-bottom: 15px;
                    margin: 0;
                    font-weight: $font-atten-medium;
                }
                .hours{
                    font-size: 20px;
                    margin-bottom: 5px;
                }
                .driving-directions{
                    font-size: 20px;
                    color:$black-new;
                    font-weight: $font-atten-medium;
                    border-bottom:2px solid $green ;
                    text-decoration:none;
                    margin-bottom: 5px;
                    display: inline-block;
                }
                .address{
                    font-size: 20px;
                    margin: 0;
                    p{
                        margin: 0;
                    }
                } 
                .neigh-map{
                    font-size: 20px;
                    text-decoration:none;
                    color:$black-new;
                    font-weight: $font-atten-medium;
                    border-bottom:2px solid $green ;
                    margin-top: 40px;
                    display: inline-block;
                }
            }
        }
        .direction-image{
            width:50%;
            background-color: $bglightgray;
            .image-div{
                // height: 551px;
                height: 100%;
            }
        }
    }
    @include max-width($desktop-xl) {
        .main-header{
            .direction-header{
                width:70%;
                .direction-heading{
                    font-size:50px;
                    line-height: 60px;
                }
                .direction-subtext{
                    font-size:25px;
                    color:$black-new;
                }
            }
        }
        // .direction-content-image{
        //     .direction-image{
        //         .image-div{
        //             height: 503px;
        //         }
        //     }
        // }
    }
    @include max-width($desktop-large){
        .main-header{
            padding-bottom: 30px;
            .direction-header{
                .direction-heading{
                    font-size: 45px;
                    line-height: 48px;
                }
                .direction-subtext{
                    font-size:20px;
                }
            }
        }
        .direction-content-image{
            .direction-content{
                .hours-direction{
                    .hours-main{
                        margin-bottom: 20px;
                    }
                    .hours-heading{
                        font-size: 20px;
                    }
                    .hours,.address,.neigh-map,.driving-directions{
                        font-size: 16px;
                    }
                }
            }
            // .direction-image{
            //     .image-div{
            //         height: 405px;
            //     }   
            // }
        }   
    }    
    @include max-width($tablet-landscape){
        .main-header{
            .direction-header{
                .direction-heading{
                    font-size: 38px;
                    line-height: 36px;
                }
                .direction-subtext{
                    font-size:16px;
                }
            }
        }
        // .direction-content-image{
        //     .direction-image{
        //         .image-div{
        //             height: 400px;
        //         }   
        //     }
        // }   
    } 
    @include max-width($iphonex-landscape){
        .main-header{
            padding-bottom: 20px;
        }
        .direction-content-image{
            flex-direction:column-reverse;
            .direction-content{
                width:100%;
                .hours-direction{
                    width:100%;
                    padding:8%;
                }
            }
            .direction-image{
                width:100%;
                .image-div{
                    height: 441px;
                }  
            }
        }
    }
    @include max-width($iphone4-landscape){
        padding:15% 10%;
        .main-header{
            padding-bottom: 10px;
            .direction-header{
                width:90%;
            }
        }
        .direction-content-image{
            .direction-content{
                .hours-direction{
                    padding: 10% 5%;
                }
            }
            .direction-image{
                .image-div{
                    height:237px;
                }
            }
        }
    }
}
    
