@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.generic-main-div{
    display:flex;
    background:$bggray-new;
    align-items: center;
    .generic-image{
        height: 960px;
    }
    .generic-content{
        padding:5%; 
        width: 50%;
        .generic-content-section{
            width:75%;
            .heading-section {
                width: 100%;
                text-align: left;
            }
            .generic-description{
                font-size: 20px;
                color: $black-new;
                padding-bottom: 30px;
                letter-spacing: -1.1px;
                line-height: 30px;
                p{
                  margin: 0;
                }
            }
            .generic-button{
                @include black-outline-button;
                display: inline-block;
                margin-top: 20px;
                min-width: 314px;
            }
        }
    }
    .wp_shortcode{
        width: 50%;
        .wptb-table-container{
            .wptb-table-container-matrix{
                table{
                    width: 95%;
                    margin: 0 auto;
                    p {
                        margin: 5px 0;
                    }
                }
            }
        }
        @include max-width($iphonex-landscape){
            display: block;
            width: 100%;
            padding: 2%;
            .wptb-table-container{
                .wptb-table-container-matrix{
                    table{
                        width: 100%;
                    }
                }
            }
        }
        @include max-width($iphone4-landscape){
            width: 100%;
            padding: 3%;
        }
    }
    .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
        width: 50% ;
    }
    .divide-equal:first-child:nth-last-child(1) {
        width: 100% ;
    }
    @include max-width($desktop-xl) {
        .generic-image{
            height: 780px;
        }
    }
    @include max-width($desktop-large) {
        .generic-image{
            height: 720px;
        }
        .generic-content{
            .generic-content-section{
                    .generic-button {
                        min-width: 250px;
                        letter-spacing: 2px;
                    }
                }   
        }
    }
    @include max-width($tablet-landscape) {
        .generic-image{
            height: 640px;
        }
        .generic-content{
            .generic-content-section{
                width:80%;
            }
        }
    }
    @include max-width($iphonex-landscape) {
        flex-wrap: wrap;
        // padding: 10% 5%;
        .generic-image{
            width:100% !important;
            padding: 0;
            height: 300px; 
        }
        .generic-content{
            width:100% !important;
            order:1;
            padding: 10% 5%;
            .generic-content-section{
                width:100%;
                .generic-description{
                    padding-bottom: 20px;
                }
            }   
        }

    }
    @include max-width($iphone4-landscape) {
        // padding: 15% 5%;
            .generic-image{
                height: 280px; 
            }
            .generic-content{
                padding: 15% 10%;
                .generic-button{
                    width:100%;
                }
            }
        }
}







