@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";


.review-landing-banner {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: flex;

    .HeroContent {
        position: relative;
        width: 100%;
        padding-bottom: 8%;
        align-items: flex-end;

        &:after {
            content: ' ';
            position: absolute;
            background: #fff;
            width: 5000px;
            height: 5000px;
            transform: rotate(26.6deg) translate(27%, 72%);
            bottom: 0;
            right: 0;
        }

        .HeroContentWrapper {
            width: 66%;
            // margin: 0 auto;
            padding: 0% 5% 0%;
            position: relative;
            z-index: 3;

            .bannerHeader {
                .heading-section {
                    text-align: left;
                    margin-left: 0;
                    width: 70%;

                    .main-title {
                        padding-bottom: 32px;
                        color: $gray-new;
                        padding: 15px 0;
                    }
                    @include max-width($iphone4-landscape) {
                        width: 100%;
                    }
                }
                .generic-text{
                    font-size: 16px; 
                    line-height: 22px;
                    margin: 0;
                    color: $gray-new;
                }
            }
            .eyebrow-text-div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .sectiontitle-eyebrow_text {
                    text-transform: uppercase;
                    font-size: 16px;
                    letter-spacing: 2px;
                    color: $gray-new;
                    line-height: 25px;
                    padding-right: 15px;
                }
                .eyebrowtext-border {
                    border-top: 2px solid $new-border-color;
                    flex-grow: 1;
                }
            }
        }
    }
    .add_overlay{
        background: transparent linear-gradient(119deg, #00000000 0%, #00000045 100%)
    }

    @include max-width($desktop-large) {
        .HeroContent {
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        .main-title {
                            width: 76%;
                            font-size: 50px;
                            line-height: 1.2;
                            letter-spacing: -2px;
                        }
                    }
                }
            }
        }
    }
    @include max-width($macbook) {
        .HeroContent {
            padding-bottom: 5%;
        }
    }
    @include max-width($tablet-landscape) {
        .HeroContent {
            padding-bottom: 0;
            .HeroContentWrapper {
                width: 100%;
                .bannerHeader {
                    .heading-section {
                        width: 90%;
                    }
                }
            }
        }
    }
    @include max-width($iphonex-landscape) {
     
        .HeroContent {
            padding-bottom: 20px;
            .HeroContentWrapper {
                width: 100%;
            }

            &:after {
                transform: rotate(26.6deg) translate(27%, 69%);
            }
        }
    }
    @include max-width(812px) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 71%);
            }
        }
    }
    @include max-width($tablet) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 69%);
            }
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        width: 100%;
                    }
                }
            }
        }
    }
    @include max-width($iphone6-plus-landscape) {
        padding-bottom: 10%;
        // height: 65vh;
        .HeroContent {
            padding-bottom: 10px;
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        .main-title {
                            width: 100%;
                            font-size: 39px;
                            line-height: 1.2;
                        }
                        .generic-text{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(38%, 69%);
            }
            .HeroContentWrapper {
                padding: 0 10%;
            }
        }
    }
    @media (max-width:812px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 72.3%);
            }
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        .main-title {
                            font-size: 32px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:736px) and (orientation: landscape) {
        padding-bottom: 0;
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 72.3%);
            }
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        .main-title {
                            font-size: 32px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:667px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%,72.3%);
            }
        }
    }
    @media (max-width:568px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%,72.3%);
            }
        }
    }
}

.review_filter{
    padding: 0 25px 15px;
    display: flex;
    .filter_area{
        min-width: 400px;
        width: fit-content;
        border: 1px solid #bababa;
        padding: 12px;
        display: flex;
        align-items: baseline;
        margin: 12px 15px;
        justify-content: space-between;
        background-color: #FBFBFA;
        @include max-width($tablet-landscape){
            min-width: 40%;
        }
        @media (max-width:812px) and (orientation: landscape) {
            min-width: 300px;
        }
        @media (max-width:736px) and (orientation: landscape) {
            min-width: 271px;
        }
        @include max-width($iphone6-landscape) {
            min-width: 94%;
        }
        label{
            margin-right: 8px;
            margin-bottom: 0;
            font-weight: 600;
            text-transform: uppercase;
        }
        select{
            border: 0px solid transparent;
            background-color: #fbfbfa;
            font-weight: 700;
            width: 40%;
            color: #545454;
        }
    }
   .filter_price{
        min-width: 400px;
        border: 1px solid #bababa;
        padding: 12px;
        display: flex;
        align-items: baseline;
        margin: 12px 15px;
        justify-content: space-between;
        background-color: #FBFBFA;
        @include max-width($tablet-landscape){
            min-width: 43%;
        }
        @media (max-width:896px) and (orientation: landscape) {
            min-width: 332px;
        }
        @media (max-width:812px) and (orientation: landscape) {
            min-width: 300px;
        }
        @media (max-width:736px) and (orientation: landscape) {
            min-width: 283px;
        }
        @include max-width($iphone6-landscape) {
            min-width: 94%;
        }
        @include max-width($iphone6) {
            min-width: 300px;
        }
        label{
            margin-right: 8px;
            margin-bottom: 0;
            font-weight: 600;
            text-transform: uppercase;
        }
        select{
            border: 0px solid transparent;
            background-color: #fbfbfa;
            font-weight: 700;
            width: 55%;
            color: #545454;
        }
   }
   @include max-width($iphone6-landscape) {
       flex-direction: column;
   }

}