
@import "../css/bootstrap.min";
@import "partials/variables";
@import "partials/fonts";
@import "partials/mixins";
@import "partials/general";
@import "partials/utility-library";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://assets.thebdxinteractive.com/Tripointe/trendmaker.css");

// Copied over from node_modules due to build issues. See file for more info.
@import "../css/algolia-autocomplete-theme-classic.css";
@import "partials/algolia-autocomplete";

