@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.flexiable-section {
    // background-color: $white;
    // padding: 6% 5%;

    .flexiable-content {
        display: flex;
        flex-wrap: wrap;
        // background-color: $white;
        &:nth-child(even){
            .flexibale-img{
             order:1;
            }
        }
        .flexibale-img{
            height: 756px;
            width: 50%;
            background: lightgray;
        }
        .flexiable-info{
            width: 50%;
            padding: 6%;
            border: 1px solid #D2D0D0;
            a{
                &:hover{
                    text-decoration: none;
                }
            }
            .heading-section {
                text-align: left;
                width: 100%;
                margin: unset;
            }
            .title-info{
                font-size: 20px;
                line-height: 30px;
                color: $black-new;
                margin-bottom: 25px;
                letter-spacing: -1px;
            }
            .learn-more{
                color: $black-new;
                border-bottom: 2px solid $green;
                font-size: 24px;
                letter-spacing: -1.2px;
                line-height: 30px;
                font-weight: $font-atten-medium;
                &:hover{
                    text-decoration: none;
                }
            }
        }

        @media (min-width: 1025px) {
          .flexibale-img {
            height: auto;
          }
        }

        @include max-width($tablet-landscape) {
            display: block;
            height: 100%;
            position: relative;
            border: 1px solid #D2D0D0;
            &.left-img{
                .flexibale-img{
                 order:0;
                }
            }
            .flexibale-img{
                height: 274px;
                width: 100%;
                border: unset;
            }
            .flexiable-info{
                width: 100%;
                padding: 40px 6% 100px;
                border: unset;
            }
        }
    }

    @include max-width($tablet-landscape) {
        .owl-stage{
          display: flex !important;
        }
    }

    @include max-width($iphonex-landscape) {
        // padding: 10% 5%;
    }

    @include max-width($iphone4-landscape) {
        // padding: 15% 10%;
        .owl-stage{
            padding-left: 0px !important;
        }
    }
}