.promo-qmi-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -3rem 0 0 -3rem;

    > div {
        padding: 3rem 0 0 3rem;
        flex: 0 0 1;
    }

    @media screen and (min-width: 750px) {
        > div {
            flex: 0 0 50%;
        }
    }

    @media screen and (min-width: 1100px) {
        > div {
            flex: 0 0 33%;
        }
    }

    @media screen and (min-width: 1400px) {
        > div {
            flex: 0 0 25%;
        }
    }
}

.promo-qmi-list__load-more {
    width: 100%;
    padding: 1rem 4rem;
    background-color: var(--accent-color);
    color: #fff;
    font-weight: 700;
    font-size: 1.125rem;
    display: block;
    margin: 3rem auto 0 auto;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width: 750px) {
        width: auto;
    }
}
