.gallery-modal,
.gallery-modal__close {
  animation: fadeIn 750ms forwards 1 cubic-bezier(0.42, 0.46, 0.45, 0.99);
  opacity: 0;
  transform: translateY(80px);
}

.gallery-modal__media {
  background-color: #fafafa;
}

@media (max-width: 1023px) {
  .gallery-modal.hide + .gallery-modal__close {
    display: none;
  }
}

.mouse-follow.loaded.active {
  --mouse-follow-opacity: 1;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: none;
  }
}