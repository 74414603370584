.media-lockup__title.section-header__title span:nth-of-type(2) {
  color: var(--highlight-color);
}

.media-lockup__subtitle:first-letter {
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .media-lockup {
    --media-lockup-title-font-size: 48px;
    --media-lockup-subtitle-font-size: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1299px) {
  .media-lockup__lockup .button {
    margin-top: 35px;
  }
}