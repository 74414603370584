@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.living-section{
    background-color: $bggray-new;
    .living-content{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .living-info{
            // padding: 8% 5%;
            .header-section {
                width: 70%;
                .heading-section{
                    text-align: left;
                    width: 100%;
                    margin: unset;
                }
                .title-info{
                    margin: 0;
                    padding: 10px 0 25px;
                    a{
                        color: $wildsand;
                        &:hover{
                            color: $wildsand;
                        }   
                    }
                }
                p {
                    margin: 0;
                }
                .learn-more{
                    margin-top: 20px;
                    &.no-link{
                        pointer-events: none;
                    }
                }
            }
        }
        .living-img {
            // padding: 8%;
            background-color: #EDEDED;
            img{
               height: 760px;
            }
        }  
        .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
            width: 50% ;
        }
        .divide-equal:first-child:nth-last-child(1) {
            width: 100%;
        }
        @include max-width($desktop-xl) {
            .living-img{
                img{
                   height: 601px;
                }
            } 
        }
        @include max-width($desktop-large) {
            .living-img{
                img{
                    height: 540px;
                }
            }
        }
        @include max-width($tablet-landscape) {
            .living-info{
                .header-section {
                    width: 80%;
                }
            }
            .living-img{
                img{
                   height: 410px;
                }
            } 
        }
        @include max-width($iphonex-landscape) {
            .living-img{
                width: 100% !important;
                img{
                    height: 360px;
                }
            }
            .living-info{
                width: 100% !important;
                .header-section {
                    width: 100%;
                }
            }
        }
        @include max-width($iphone6-landscape) {
            // .living-img{
            //     padding: 15% 10%;
            // }
            .living-info{
                // padding: 15% 10%;
                .header-section {
                    width: 100%;
                }
              
            }
        }
    }
}
