@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.focus_boxes {
	padding: 8% 5% 5%;
	.boxes_wrapper {
		display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        max-width: 1364px;
        margin: 0 auto;
        @include max-width($iphonex-landscape) {
           flex-direction: column-reverse;
        }
		.accordion_wrapper {
			width: 48%;
            @include max-width($iphonex-landscape) {
                width: 100%;
            }
			.heading_wrap {
				margin-bottom: 50px;
                .heading-section{
                    text-align: left;
                    margin: 10px 0;
                    width: 60%;
                }
			}
			.accordion_block {
				padding-bottom: 20px;
				.item {
					cursor: pointer;
					margin-bottom: 30px;
					.item_head {
						opacity: .4;
						position: relative;
						transition: opacity .5s ease;
						padding-right: 100px;
                        &:hover{
                            opacity: 1;
                        }
                        &::after{
                            content: " ";
                            position: absolute;
                            width: calc(100% - 45px);
                            top: -10px;
                            height: 1px;
                            background: #e5e5e5;
                        }
						.focus-boxes-heading {
							max-width: 380px;
							font-weight: 500;
							font-size: 24px;
							line-height: 1.5;
							color: #545454;
							margin-bottom: 18px;
							letter-spacing: -.7px;
						}
						.arrowRight {
							position: absolute;
							top: 34px;
							right: 45px;
							border: 1px solid #d2d0d0;
							height: 45px;
							width: 45px;
							&::before {
								content: " ";
								position: absolute;
								width: 8px;
								height: 8px;
								border-top: 2px solid #545454;
								border-right: 2px solid #545454;
								transform: translate(-1px, -50%) rotate(45deg);
								transition: 0.3s ease-in-out;
								top: 50%;
								left: 45%;
							}
							&::after {
								content: " ";
								position: absolute;
								background: #545454;
								transform: translate(-50%, -50%);
								height: 2px;
								width: 16px;
								transition: 0.3s ease-in-out;
								top: 50%;
								left: 45%;
							}
						}
					}
					.item_head.active {
						opacity: 1;
						.arrowRight {
							&:after {
								transform: translate(-50%, -50%) rotate(89deg);
								left: 46%;
								top: 52%;
							}
							&:before {
								transform: translate(-1px, -50%) rotate(135deg);
								top: 57%;
								left: 38%;
							}
						}
					}
				}
			}
		}
		.accordion_content {
			width: 50%;
			position: relative;
            @include max-width($iphonex-landscape) {
                width: 100%;
                padding-bottom: 40px;
            }
			.boxes-wrap-result{
				position: relative;
				img{
					// position: absolute;
					height: 110vh;
					@include max-width($iphonex-landscape) {
						position: relative;
						height: 100%;
					}
				}
				.wptb-table-container-matrix{
					@include max-width($iphonex-landscape) {
						overflow: scroll;
					}
					table{
						@include max-width($iphonex-landscape) {
							width: 100%;
						}
					}
				}


				
			}
		}
	}
}
