@import '../../assets/scss/partials/variables';
@import '../../assets/scss/partials/mixins';
@import '../../assets/scss/partials/fonts';
.cardsectiontitle-main-div {
    padding: 2% 5%;
    .sectiontitle-headline {
        // color: $gray-new;
        // font-size: 80px;
        // font-family: $font-secondary;
        // word-break: break-word;
        // // font-weight: 700;
        // line-height: 80px;
        // letter-spacing: -4px;
        padding-bottom: 2%;

        .green-color {
            color: $green;
        }
    }
    .qmi-sort {
        display: flex;
        justify-content: flex-end;
        font-weight: $font-atten-medium;
        font-size: 14px;
        align-items: center;
        color: $black-new;
        select {
            padding: 5px;
            padding-left: 0;
            border: none;
            color: $black-new;
            font-weight: $font-atten-medium;
        }
    }
    .eyebrow-text-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;
        width: 100%;
        .sectiontitle-eyebrow_text {
            // text-transform: uppercase;
            // font-family: $atten-heavy;
            // font-size: 16px;
            // letter-spacing: 2px;
            // color: $gray-new;
            // line-height: 25px;
            padding-right: 15px;
        }
        .eyebrowtext-border {
            border-top: 1px solid var(--gray-light);
            flex-grow: 1;
        }
    }
    // @include max-width($desktop-xl) {
    //     .sectiontitle-headline {
    //         font-size: 52px;
    //         line-height: 60px;
    //         letter-spacing: -3px;
    //     }
    // }
    @include max-width($desktop-large) {
        // .sectiontitle-headline {
        //     font-size: 50px;
        //     line-height: 48px;
        //     letter-spacing: -2px;
        // }
        .eyebrow-text-div {
            .sectiontitle-eyebrow_text {
                font-size: 14px;
            }
        }
    }
    @include max-width($tablet-landscape) {
        // .sectiontitle-headline {
        //     font-size: 38px;
        //     line-height: 40px;
        // }
        .eyebrow-text-div {
            .sectiontitle-eyebrow_text {
                font-size: 12px;
            }
        }
    }
    @include max-width($iphone4-landscape) {
        padding: 10% 10% 0;
    }
}
