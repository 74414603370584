@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.contact-section {
    display: flex; // background-color: $bglightgray;
    padding: 5% 5%;
    .contact-content {
        background-color: $white;
        padding: 5%;
        padding-bottom: 10%;
        width: 100%;
        border: 1px solid #DBDBDB;
        .header-section {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-bottom: 3%;
            flex-wrap: wrap;
            .heading-section-left {
                width: 40%;
                text-align: left;
                .heading-section {
                    text-align: left;
                    width: 100%;
                    margin-left: 4%;
                }
            }
            .heading-section-right {
                width: 55%;
                display: flex;
                text-align: left;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                padding-bottom: 40px;
                margin-right: 2%;
            }
        }
        .contact-info-div {
            display: flex;
            flex-wrap: wrap;
            .contact-info {
                width: 31%;
                border: 1px solid #DBDBDB;
                padding: 5px;
                margin: 1%;
                padding: 30px;
                min-height: 198px;
                .contact-name {
                    .title {
                        font-size: 24px;
                        letter-spacing: -0.6px;
                        line-height: 30px;
                        color: $black-new;
                        font-weight: $font-atten-medium;
                    }
                }
                h6 {
                    font-weight: $font-atten-book;
                    color: $black-new;
                    font-size: 22px;
                }
                .email,
                .contact_url {
                    color: $green;
                    font-weight: $font-atten-book;
                    font-size: 22px;
                    text-decoration: none;
                    word-break: break-all;
                    display: block;
                }
                .contact-text {
                    font-size: 22px;
                    letter-spacing: -1.1px;
                    line-height: 30px;
                    color: $black-new;
                    margin: 0;
                    p {
                        margin: 0;
                    }
                }
            }
        }
        @include max-width($desktop-large) {
            .header-section {
                .heading-section-left {
                    width: 35%;
                }
            }
            .contact-info-div {
                .contact-info {
                    .contact-text,
                    h6,
                    .email,
                    .contact_url {
                        font-size: 20px;
                    }
                }
            }
        }
        @include max-width($tablet-landscape) {
            .contact-info-div {
                .contact-info {
                    width: 48%;
                }
            }
        }
        @include max-width($iphonex-landscape) {
            padding: 10% 5%;
            .header-section {
                .heading-section-left {
                    width: 96%;
                    margin: auto;
                    .heading-section {
                        margin-left: 0;
                    }
                }
                .heading-section-right {
                    width: 96%;
                    margin: auto;
                    padding-bottom: 0px;
                }
            }
            .contact-info-div {
                .contact-info {
                    .contact-text,
                    h6,
                    .email,
                    .contact_url {
                        font-size: 18px;
                        letter-spacing: -0.9px;
                        line-height: 26px;
                    }
                }
            }
        }
        @include max-width($iphone6-plus-landscape) {
            padding: 5% 5%;
            border: unset;
            padding-bottom: 0;
            .header-section {
                .heading-section-left {
                    width: 100%;
                    .heading-section {
                        .sub-title {
                            font-size: 13px;
                            letter-spacing: 3.9px;
                            line-height: 25px;
                        }
                    }
                }
                .heading-section-right {
                    width: 100%;
                    .learn-more {
                        display: none;
                    }
                }
            }
            .contact-info-div {
                .contact-info {
                    width: 100%;
                    margin: 20px 0;
                    padding-bottom: 50px;
                }
            }
        }
        @include max-width($iphone6-plus-landscape) {
            padding: 15% 5%;
            padding-bottom: 0;
        }
    }
}

.contact_modal {
    .modal-header {
        right: 40px !important;
        top: 40px !important;
        .close {
            background-color: unset !important;
            border: 1px solid #707070 !important;
            border-radius: 50% !important;
            height: 40px !important;
            width: 40px !important;
            span {
                color: $black-new !important;
            }
        }
    }
    @include max-width($iphonex-landscape) {
        .modal-header {
            top: 0px !important;
            right: 0px !important;
            .close {
                border: none !important;
            }
        }
    }
    .modal-body {
        background-color: $white !important;
        iframe {
            position: unset !important;
            visibility: hidden;
        }
        .contact-modal-section {
            display: flex;
            flex-wrap: wrap;
            .contact-img {
                background-size: cover;
                display: flex;
                position: relative;
                align-items: flex-end;
                height: 100vh;
                z-index: 0;
                width: 50%;
                &.add_overlay::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background: $overlayblack;
                    z-index: -1;
                }
                .contact-image-box-content {
                    color: $white;
                    position: absolute;
                    bottom: 10%;
                    .contact-image-heading {
                        font-size: 70px;
                        font-weight: $font-atten-medium;
                        word-break: break-word;
                        letter-spacing: -3.5px;
                        line-height: 70px;
                        margin: 0 6%;
                        margin-bottom: 3%;
                    }
                    .contact-image-description {
                        margin: 0 6%;
                        font-size: 20px;
                        line-height: 26px;
                        letter-spacing: -0.5px;
                    }
                }
            }
            .contact_us_form {
                width: 50%;
                padding: 6% 10%;
                overflow: auto;
                height: 100vh;
            }
            @include max-width($desktop-xl) {
                iframe {
                    height: 100vh !important;
                }
                .contact-img {
                    .contact-image-box-content {
                        .contact-image-heading {
                            font-size: 60px;
                            letter-spacing: -0.5px;
                        }
                        .contact-image-description {
                            font-size: 18px;
                        }
                    }
                }
                .contact_us_form {
                    padding: 5% 8%;
                }
            }
            @include max-width($iphonex-landscape) {
                iframe {
                    height: 100vw !important;
                }
                .contact-img {
                    display: none;
                }
                .contact_us_form {
                    width: 100%;
                }
            }
            @include max-width($iphone4-landscape) {
                iframe {
                    height: 100vh !important;
                }
                .contact_us_form {
                    width: 100%;
                    padding: 10% 8%;
                }
            }
        }
    }
}

.mobile-contact-image-box-main {
    display: none;
    @include max-width($iphone6-plus-landscape) {
        display: block;
        .image-box {
            height: 400px;
            background-size: cover;
        }
        .image-box-content {
            background: $green;
            padding: 10%;
            .image-heading {
                font-size: 41px;
                line-height: 46px;
                color: $white;
                letter-spacing: -2.05px;
                font-weight: $font-atten-medium;
            }
            .image-description {
                color: $white;
                letter-spacing: -0.8px;
                line-height: 20px;
                font-size: 16px;
                margin-bottom: 8%;
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .image-box {
            height: 239px;
        }
    }
}