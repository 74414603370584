@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.sidebar-signup-panel{
    width: 45%;
    margin: auto;
    padding: 5%;
    color: $black-new;
 
 
    .sidebar-signup__title {
        font-size: 40px;
        font-weight: $font-atten-medium;
        margin: 0;
    }
    .sidebar-signup__info {
        font-size: 20px;
    }
    .sidebar-signup__disclaimer {
        display: block;
        font-size: 12px;
        line-height: 1.5;
        margin-top: 25px;
        color: $black-new;
        a{
          &.form_privacy{
            text-decoration: underline;
            &:hover{ 
              color: $green;
              text-decoration: underline;
            }
          }
          &:hover{   
            color: $green;
            text-decoration: underline;
          }
        }
    }
    .thankyou_message {
        font-size: 30px;
        display: grid;
        justify-content: center;
        p{
            font-size: 14px;
        }
        .download-box{
            display: flex;
            justify-content: flex-start;
            .download-image{
                width: 115px;
                height: 128px;
            }
            .download-data{
                margin-left: 25px;
                .download-title{
                    margin: 10px 0;
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 24px;
                }
                .sub-title{
                    line-height: 24px;
                    font-size: 25px;
                    margin-bottom: 15px;
                }
                .downlink-link{
                    text-decoration: underline;
                    font-size: 25px;
                    letter-spacing: 0px;
                    line-height: 24px;
                }
            }
        }

    }
    .hide_confirm_msg{
        .download-box{
            display: none !important;
        }
        
    }
    form#mktoForm_5803 {
        .row-class-1{
            .Col1{
                .mktoFieldWrap{
                    .mktoHtmlText{
                        width: auto!important;
                    }
                }
            }
        }
        .row-class-2{
            .Col2{
                width: 100%;
            }
            legend{
display: none;
            }
        }
        .row-class-5 {
          display: none;
        }
        .row-class-6{
            .mktoFieldWrap {
                // display: flex;
                // align-items: end;
                // justify-content: flex-start;
                // margin-top: 10px;
                .mktoCheckboxList {
                    width: 20px !important;
                    padding: 0px;
                    order: -1;
                    margin-right: 10px;
                    .mktoField{
                        height: auto;
                        margin-bottom:0;
                        margin-top: 3px;
                    }
                }
                label {
                    width: 100% !important;
                    font-weight: $font-atten-book;
                    color: $black-new;
                    font-size: 16px;
                    padding: 0;
                }
            } 
        }
        .row-class-7{
            .Col7{
                .mktoFieldWrap{
                    .mktoLabel{
                        width: 100%!important;
                        font-weight: 300;
                        color: #545454;
                        font-size: 16px;
                        padding: 0;
                    }
                }
            }
        }
        .row-class-9{
            label {
                font-weight: $font-atten-book;
                color: $black-new;
                padding-bottom: 5px;
                font-size: 16px;
            }
        }
        .mktoFormRow {
            .mktoField {
                height: 52px;
                font-size: 16px !important;
                line-height: 52px;
                margin-bottom: 5px;
                padding: 0 15px;
                background: $white;
                border: 1px solid $white;
                font-weight: $font-atten-book !important;
            }
            textarea.mktoField {
                padding: 15px;
                // text-transform: lowercase;
                line-height: 20px;
                font-size: 14px;
                height: auto;
                font-weight: $font-atten-book !important;
                // &::placeholder {
                //     text-transform: lowercase;
                // }
            }
        }
        label[for="Phone"] {
            display: none;
        }
        .mktoButtonWrap.mktoSimple {
            button.mktoButton {
            //   @include green-filled-button;
              margin-top: 20px;
              background-image: unset;
            }
        }
        .mktoError {
			.mktoErrorMsg {
				font-weight: $font-atten-book !important;
			}
		}
    }      
    @include max-width($desktop-large) {
        .sidebar-signup__title {
            font-size: 35px;
        }
        .sidebar-signup__info {
            font-size: 16px;
        }
        .sidebar-signup__disclaimer {
            font-size: 11px;
        }
        .thankyou_message {
            font-size: 20px;
            .download-box{
                .download-image{
                    width: 100px;
                    height: 115px;
                }
                .download-data{
                    .download-title{
                        margin-bottom: 8px;
                        font-size: 14px;
                    }
                    .sub-title{
                        font-size: 18px;
                        margin-bottom: 8px;
                    }
                    .downlink-link{
                        font-size: 20px;
                    }
                }
            }  
        }
        form#mktoForm_5803 {
            .row-class-6{
                .mktoFieldWrap {
                    .mktoCheckboxList {
                        .mktoField{
                            margin-top: 0;
                        }
                    }
                    label {
                        font-size: 14px;
                    }
                } 
            }
            .row-class-9{
                label {
                    font-size: 14px;
                }
              
            }
            .mktoFormRow {
                .mktoField {
                    font-size: 13px;
                }
                textarea.mktoField {
                    font-size: 13px;
                }
            }
        }
    }
    @include max-width($tablet-landscape) {
        width: 50%;
        .thankyou_message {
            .download-box{
                .download-data{
                    .sub-title{ 
                      font-size: 13px;
                    }
                    .downlink-link{
                        font-size: 14px;
                    }
                }
            } 
        }
    }
    @include max-width($tablet) {
        .thankyou_message {
            .download-box{
                .download-data{
                    .download-title{
                        margin: 0;
                        font-size: 12px;
                    }
                    .sub-title{ 
                      font-size: 14px;
                      margin-bottom: 0px;
                    }
                    .downlink-link{
                        font-size: 18px;
                    }
                }
            } 
        }
    }
    @include max-width($iphone6-plus-landscape) {
       
        width: 65%;
     
        .thankyou_message {
            .download-box{
                .download-image{
                    width: 80px;
                }
                .download-data{
                    .download-title{
                        margin: 0;
                        font-size: 12px;
                    }
                    .sub-title{ 
                      font-size: 14px;
                      margin-bottom: 0px;
                    }
                    .downlink-link{
                        font-size: 18px;
                    }
                }
            } 
        }
    }
    @include max-width($iphone4-landscape) {
        width: 100%;
        .thankyou_message {
            .download-box{
                .download-image{
                    // width: 103px;
                    height: 115px;
                    width: 170px;
                }
                .download-data{
                    .download-title{
                        margin: 5px 0;
                    }
                    .sub-title{ 
                        // font-size: 23px;
                        margin-bottom: 5px;
                        font-size: 14px;
                    }
                    
                    .downlink-link{
                        // font-size: 23px;
                        font-size: 15px;
                    }
                }
            } 
        }
    }
   
}