.global-components {
  overflow: hidden;
}

.gallery-modal--scrolled {
  --gallery-modal-meta-opacity: 0;
}

.media-caorusel__arrow:not(.swiper-button-disabled) {
  --media-carousel-arrow-opacity: 1;
  --media-carousel-arrow-pointer-events: auto;
}

.swiper-slide {
  user-select: none;
}

.media-carousel__arrows {
  padding-top: 67.84511%;
  padding-top: 47%;
}

.media-caorusel__arrow-icon {
  display: block;
  height: auto;
  width: 100%;
}

@media (max-width: 1023px) {
  .media-caorusel__arrow {
    width: 100px;
  }
}

@media (min-width: 1024px) {
  .media-carousel {
    --media-carousel-item-margin-right: 20px;
    --media-carousel-item-col-margin-h: 10px;
  }

  .media-carousel__arrows {
    padding-top: 37.5%;
    padding-top: 43.5%;
  }
}

@media (min-width: 2000px) {
  .media-carousel {
    --overflow: visible;
    --media-carousel-item-width: calc(35.075vw - 10px); /* lines up with 2 col gallery */
    --media-carousel-item-width: calc(39.075vw - 10px); /* doesnt line up */
  }

  .media-carousel__arrows {
    padding-top: 26.2vw;
  }
}

[data-swiper*='"slidesPerView": "auto"'] .swiper-slide {
  height: auto;
}
