@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.qmi-plan-banner {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: flex;

    .HeroContent {
        position: relative;
        width: 100%;
        padding-bottom: 8%;
        align-items: flex-end;

        &:after {
            content: ' ';
            position: absolute;
            background: #fff;
            width: 5000px;
            height: 5000px;
            transform: rotate(26.6deg) translate(27%, 72%);
            bottom: 0;
            right: 0;
        }

        .HeroContentWrapper {
            width: 66%;
            // margin: 0 auto;
            padding: 0% 5% 0%;
            position: relative;
            z-index: 3;

            .bannerHeader {
                .heading-section {
                    text-align: left;
                    margin-left: 0;
                    width: 80%;

                    .main-title {
                        padding-bottom: 32px;
                        padding: 15px 0;

                        > span {
                            font-weight: $font-atten-medium;
                        }
                    }
                    @include max-width($iphone4-landscape) {
                        width: 100%;
                    }
                }
                .generic-text{
                    font-size: 16px; 
                    line-height: 22px;
                    margin: 0;
                    font-weight: $font-atten-medium;
                    color: $gray-new;
                }
            }

        }
    }
    .add_overlay{
        background: transparent linear-gradient(119deg, #00000000 0%, #00000045 100%)
    }

    @include max-width($desktop-large) {
        .HeroContent {
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        .main-title {
                            width: 76%;
                        }
                    }
                }
            }
        }
    }
    @include max-width($macbook) {
        .HeroContent {
            padding-bottom: 5%;
        }
    }
    @include max-width($tablet-landscape) {
        .HeroContent {
            padding-bottom: 0;
            .HeroContentWrapper {
                width: 100%;
                .bannerHeader {
                    .heading-section {
                        width: 90%;
                    }
                }
            }
        }
    }
    @include max-width($iphonex-landscape) {
     
        .HeroContent {
            padding-bottom: 20px;
            .HeroContentWrapper {
                width: 100%;
            }

            &:after {
                transform: rotate(26.6deg) translate(27%, 69%);
            }
        }
    }
    @include max-width(812px) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 71%);
            }
        }
    }
    @include max-width($tablet) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 69%);
            }
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        width: 100%;
                    }
                }
            }
        }
    }
    @include max-width($iphone6-plus-landscape) {
        padding-bottom: 10%;
        // height: 65vh;
        .HeroContent {
            padding-bottom: 10px;
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        .main-title {
                            width: 100%;
                        }
                        .generic-text{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(38%, 70%);
            }
            .HeroContentWrapper {
                padding: 0 10%;
            }
        }
    }
    @media (max-width:812px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 72.3%);
            }
        }
    }
    @media (max-width:736px) and (orientation: landscape) {
        padding-bottom: 0;
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%, 72.3%);
            }
        }
    }
    @media (max-width:667px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%,72.3%);
            }
        }
    }
    @media (max-width:568px) and (orientation: landscape) {
        .HeroContent {
            &:after {
                transform: rotate(26.6deg) translate(27%,72.3%);
            }
        }
    }
}
.qmi-sort-heading{
    display: flex;
    justify-content: flex-end;
    font-weight: $font-atten-medium;
    font-size: 16px;
    align-items: center;
    color:$black-new;
    width: 90%;
    padding: 15px 0;
    margin: auto;
    select{
        padding: 5px;
        padding-left: 0;
        border: none;
        color:$black-new;
        font-weight: $font-atten-medium;
    }
}