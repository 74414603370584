@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.neigh-name {
  align-items: center;
  border-radius: 24px;
  border: 1px solid #E8E8E8;
  color: #525252;
  display: inline-flex;
  font-size: 14px;
  margin-bottom: 41px;
  padding: 9px 23px 9px 21px;
  transition: 250ms ease-in-out;

  path {
    fill: currentColor;
    stroke: currentColor;
  }
}

.neigh-name:focus,
.neigh-name:hover {
  --icon-translate-x: -5px;
  background-color: var(--black);
  border-color: var(--black);
  color: #fff;
  text-decoration: none;
}

.neigh-name__icon {
  margin-right: 12px;
  transition: 300ms transform ease-in-out;
  transform: translateX(var(--icon-translate-x, 0));
}

.plan_image_caption {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  width: 100%;
  height: auto;
  padding: 10px;
  text-align: center;
  background-color: rgba(0,0,0,.5);
  color: $white;

  @media (min-width: 1024px) {
    font-size: 16px;
    padding-bottom: 25px;
  }
}

.residence-content {
  background-color: $bggray-new;
  height: 100%;
  overflow: auto;
  padding: min(50px, 4%) min(var(--wrap-padding-h), 89px) 50px min(var(--wrap-padding-h), 64px);
  position: relative;
  width: 40%;
}

.residence-details {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  height: calc(100vh - 99px);
  margin-bottom: 157px;
}

.residence-image {
  background-color: $bggray-new;
  border-right: 1px solid $lightborder;
  height: 100%;
  padding: 0 !important;
  padding: 30px 5% 5px;
  position: relative;
  width: 60%;

  // .image-div,
  .iframe-div {
    iframe {
      width: 100%;
      height: 100%;
      border: none;

      .splash-screen-wrapper {
        .splash-screen-thumbnail {
          background-size: unset !important;
        }
      }
    }
  }
}

.residence-image {
  .iframe-div,
  .image-div {
    height: 100% !important;
    padding: 0 !important;
  }

  .iframe-div {
    background: #000;
    height: auto !important;
    padding-top: 56.25% !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    iframe {
      height: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .iframe-div--full-height {
    height: 100% !important;
    padding-top: 0 !important;
  }

  .slider-wrapper,
  .slick-list,
  .slick-slider,
  .slick-track {
    height: 100%;
  }

  .slick-arrow {
    position: absolute;
    top: 50% ;
    background: $white;
    width: 43px;
    height: 43px;
    margin: 0;
    z-index: 1;

    &:after {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border-right: 2px solid #111111;
      border-bottom: 2px solid #111111;
      transform: rotate(-45deg);
      top: 44%;
      right: 15px;
    }

    &:before {
      position: absolute;
      content: "";
      top: 50%;
      height: 2px;
      width: 15px;
      background: #111;
      right: 15px;
      opacity: 1;
    }

    &.slick-disabled {
      opacity: 0.5;
    }
  }

  .slick-slide {
    height: auto;

    > div {
      background-color: #000;
      height: 100%;
    }

    .plan_image_slider {
      height: 100%;
      position: relative;
    }
  }

  .slick-prev {
    left: 10px;

    &:after {
      transform: rotate(135deg);
      left: 15px;
    }

    &:before {
      left: 15px;
    }
  }

  .slick-next {
    right: 10px;
  }

  .slick-dots {
    display: none !important;
  }

  .slick-track {
    display: flex;
  }
}

/* reduce spacing and sizes in plan detail col for short screens */
@media (min-width: 1024px) and (max-height: 1000px) {
  .neigh-name {
    margin-bottom: 20px;
  }

  .plan__details {
    margin-top: 20px;
  }

  .plan-details {
    padding-bottom: 20px;
    padding-top: 14px;
  }
}

@media (min-width: 1600px) and (max-width: 1699px) {
  .plan__buttons .button {
    font-size: 12px;
  }
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .plan__buttons .button {
    font-size: 10px;
    min-width: auto;
    padding: 10px 8px 8px;
  }

  .plan__buttons .button:not(:hover)::before {
    opacity: 0;
  }

  .residence-details .residence-content {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.residence-details .hero-subnav__container {
  flex-basis: 100%;
  flex-shrink: 0;
  order: 3;
}

@media (max-width: 1023px) {
  .residence-content {
    height: auto;
    overflow-y: visible;
    padding-top: 40px;
    width: 100%;
  }

  .residence-details {
    display: block;
    height: 100%;
    margin-bottom: 0;
  }

  .residence-image {
    padding: 0;
    width: 100%;
  }

  .residence-image {
    height: auto;
    max-height: 100vh;
    min-height: 460px;
    position: relative;
  }

  .residence-image:before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }

  .residence-image .slick-slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .residence-details .hero-subnav__container {
    flex-basis: 100%;
    flex-shrink: 0;
    order: inherit;
  }
}

// Floorplan save <3 icon button
.floorplan-save__icon,
.floorplan-save__icon + div {
  background: none;
  border: none;
  height: 30px;
  margin-top: 2px;
  padding: 0;
  position: absolute;
  right: min(var(--wrap-padding-h), 89px);
  top: min(50px, 4%);
  width: 30px;

  @media (max-width: 1023px) {
    margin-top: 13px;
  }

  @media (min-width: 1024px) and (max-width: 1599px) {
    right: 24px;
  }
}

.floorplan-save__icon {
  pointer-events: none;
}

.floorplan-save__icon svg {
  display: block;
  height: auto;
  width: 100%;
}

.floorplan-save__icon + div {
  //color: transparent;
  //font-size: 0px;
  //overflow: hidden;
  //text-indent: -1000px;
  cursor: pointer;
}

.floorplan-save__icon + div > div {
  height: 100%;
}

// Floorplan saved modal

.floorplan-save__icon + div > div.floorplan-save__modal {
  background: #fff;
  height: 160px;
  max-width: calc(100vw - 40px);
  position: fixed;
  right: 30px;
  text-indent: 0;
  top: 20px;
  width: 600px;
  z-index: 1000;
}

.floorplan-save__modal-content {
  color: #000;
  font-size: 24px;
  line-height: 1.1;
  padding: 30px 60px 30px 40px;
}

.floorplan-save__modal-link {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .floorplan-save__icon + div > div.floorplan-save__modal {
    height: 200px;
  }

  .floorplan-save__modal-content {
    padding-top: 50px;
  }
}

.floorplan-save__icon--hidden + div {
  //display: none !important;
}
