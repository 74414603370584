// More overrides in tailwind.css
// having this in tailwind.css doesn't work
:root {
    /* Input*/
    --aa-search-input-height: 40px;
    --aa-font-family: new-atten, "Open Sans", sans-serif;
    // charcoal
    --aa-text-color-rgb: 84, 84, 84;
    --aa-panel-border-color-rgb: 190, 211, 68;
    --aa-input-border-color-rgb: 84, 84, 84;
    // slate
    --aa-primary-color-rgb: 140, 140, 140;
    --aa-icon-color-rgb: 140, 140, 140;
    // steel
    --aa-selected-color-rgb: 200,200,200;
    --aa-icon-size: 18px;
    --aa-muted-color-rgb: 140, 140, 140;
}

@media (min-width: 1024px) {
    :root {
        --aa-search-input-height: 76px;
    }
}
