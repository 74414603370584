// ----- General ----- //
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
body{
    font-family: $general-font !important;
    font-weight: $font-atten-book;
    color: $black-new;
}
img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.wrap{
    max-width: 1500px;
    margin: auto;
}
body.body_overflow{
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
}
body.compensate-for-scrollbar {
    overflow: hidden !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    box-shadow: none !important;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    color: unset;
    background-color: unset;
    border-color: unset;
}
a{
    text-decoration: none;
    color: $green;
    // outline: none;
    &:hover{
        color: $green;
        outline: none;
    }
    // &:focus{
    //     outline: none;
    // }
}
ul,ol{
    padding-left: 20px;
}
b,strong{
    font-weight: bold;
}
.modal{
    padding: 0 !important;
    height: 100vh !important;
    z-index: 9999;
}
.modal-dialog{
    height: 100%;
    padding: 0 !important;
    margin: unset;
    max-width: 100%;
    overflow-y: hidden !important;
    .modal-content{
        height: 100%;
        border: none;
        border-radius: unset;
        .modal-header{
            background-color: $bggray-new;
            padding: 2% 5%;
            border: 0;
            .modal-title{
                width: 90%;
            }
            h2{
                font-size: 44px;
                font-weight: $font-atten-medium;
                line-height: 58px;
                color:$black-new;
            }
            p{
                text-transform: uppercase;
                font-weight: $font-atten-medium;
                font-size: 14px;
                letter-spacing: 5.6px;
                color: $black-new;
                line-height: 19px;
                margin: 0;
            }
            .close{
                background: #fff;
                opacity: 1;
                border-radius: 0%;
                height: 76px;
                width: 76px;
                padding: 0;
                margin: 0;
                border: 1px solid $new-border-color;
                // position: absolute;
                // right: 5%;
                color:$black-new;
                span{
                    font-size: 50px;
                    line-height: 25px;
                }
                @include max-width($desktop-xl){
                    height: 50px;
                    width: 50px;
                    span{
                        font-size: 35px;
                    }
                }
                @include max-width($iphonex-landscape) {
                    height: 35px;
                    width: 35px;
                    span{
                        font-size: 25px;
                        line-height: 15px;
                    }
                }
            }
            @include max-width($desktop-xl) {
                h2{
                    font-size: 40px;
                    line-height: 40px;
                }
            }
            @include max-width($desktop-large){
                h2{
                    font-size: 34px;
                    line-height: 34px;
                }
            }
            @include max-width($tablet-landscape){
                h2{
                    font-size: 30px;
                }
                p{
                    font-size: 12px;
                    letter-spacing: 4.8px;
                    line-height: 16px;
                }
            }
        }
        .modal-body{
            flex:1;
            padding: 0;
            margin-bottom: -1px;
            // background-color: $bggray-new;
            overflow-y: auto;
            iframe{
                width: 100%;
                height: 100%;
                border: unset;
                position: absolute;
            }
        }
    }

}
.Estimate_modal.Request_quote{
    background-color: $bggray-new;
}
.Estimate_modal.Request_quotes{
    background-color: $bggray-new;
}
.no-link{
    pointer-events: none !important;
}
.page_not{
    background-color:$bglightcream;
    height: 80vh;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hidden-btn{
    display: none !important;
}

.embeddedServiceHelpButton{
    display: none !important;

    .helpButton{
        bottom: 6% !important;
        right: 8%!important;    

        &:hover,&:active,&:focus{
            &::before{
                display: none;
            }
        }

        .uiButton{
            background-color: $green !important;
            color: $black-new !important;
            border-radius : 50px !important;
            box-shadow: none !important;
            font-size: 13px!important;
            letter-spacing: 3.9px;
            font-weight: $font-atten-bold !important;
            width: 100% !important;
            max-width: 100%;
            justify-content: center !important;

            &:hover,&:active,&:focus{
                &::before{
                    display: none;
                }
            }

            &.helpButtonDisabled{
                display: none;
            }
            .helpButtonLabel{
                flex-grow: 0;
                text-transform: uppercase !important;
                text-decoration: none !important;
            }
            .embeddedServiceIcon{
                color: $white;
            }
        }

    }

    @include max-width($iphonex-landscape){
        .helpButton{
            bottom: 6%!important;
            right: 13%!important;
        }
    }
    @include max-width-orientation($iphonex-landscape,'landscape'){
        .helpButton{
            bottom: 28%!important;
            right: 4%!important;
        }
    }
    @include max-width($iphone4-landscape){
        .helpButton{
            bottom: 13%!important;
            right: 4%!important;
        }
    }
}

.embeddedServiceSidebarMinimizedDefaultUI{
    bottom: 5% !important;
    right: 8%!important;
    width: auto !important;
    max-width: unset !important;
    background-color: $green !important;
    color: $black-new !important;
    border-radius : 50px !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 16px!important;
    letter-spacing: 3.9px;
    font-weight: $font-atten-bold !important;
    text-transform: uppercase !important;  
    .minimizedText{
        .message{
            color: $white !important;
        }
    }
}


/*SFDC CHAT*/
.sfdc_chat_disclaimer {
    padding: 10px 10px 0 10px !important;
    font-size: 12px;
    color: #000;
    line-height: 18px;
    text-align: left;
    font-weight: $font-atten-book;
}

.embeddedServiceSidebarButton{
    &:focus{
        box-shadow: none !important;
    }
}
.embeddedServiceSidebar {
	.sidebarHeader {
		background-color: $black-new !important;
	}
}
.embeddedServiceHelpButton {
	.helpButton {
		.uiButton {
			&:hover {
				background-color: none !important;
			}
		}
	}
	.uiButton {
		.helpButtonLabel {
			.message {
				text-decoration: none !important;
				text-decoration: none !important;
			}
		}
	}
}
.embeddedServiceLiveAgentStateChatAvatar.isLightningOutContext {
	.agentIconColor0 {
		background-color: $green !important;
	}
	.agentIconColor1 {
		background: $green !important;
		font-weight: $font-atten-book !important;
	}
}
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
	background-color: $green !important;
}
button[embeddedService-chatHeader_chatHeader] {
	padding-bottom: 40px !important;
}
.embeddedServiceSidebarMinimizedDefaultUI.helpButton {
    background-color: $green !important;
    color: $black-new !important;
    .message{
        text-decoration: none !important;
    }
}
button.closeButton[embeddedService-chatHeader_chatHeader] {
	padding-top: 17px !important;
}
.embeddedServiceLiveAgentStateWaiting {
	.waitingGreeting {
		font-weight: $font-atten-book;
	}
	.waitingMessage {
		font-weight: $font-atten-book;
	}
	.waitingStateContent {
		font-weight: $font-atten-book;
	}
}
.embeddedServiceSidebarButton.uiButton--inverse {
	background: $green !important;
	text-decoration: none !important;
	text-decoration: none;
	&:disabled {
		background: $green !important;
		text-decoration: none !important;
		text-decoration: none;
	}
	.label {
		color: #ffffff !important;
	}
}
.embeddedServiceSidebarDialogState {
	#dialogTextTitle {
		font-weight: $font-atten-book;
	}
	#dialogTextBody {
		font-weight: $font-atten-book;
	}
	.dialogButton {
		text-decoration: none !important;
	}
}
.embeddedServiceLiveAgentStateChatMessage {
	.uiOutputRichText {
		font-weight: $font-atten-book;
	}
}
.embeddedServiceLiveAgentStateChat {
	.chatSessionStartTime {
		font-weight: $font-atten-book;
	}
	.endChatContainer {
		.endChatButton {
			margin-bottom: 10px !important;
		}
	}
}
.embeddedServiceSidebarMinimizedDefaultUI {
	background-color: $green !important;
}
button.uiButton.helpButtonEnabled {
	&::before {
		background: none !important;
	}
}
.embeddedServiceSidebarFormField {
	.uiInput {
		.uiLabel-left {
			font-weight: $font-atten-book;
		}
	}
}
.helpButtonEnabled {
	&:focus {
		.helpButtonLabel {
			text-decoration: none !important;
		}
	}
}
span.label.bBody {
    font-weight: $font-atten-book !important;
	&:hover {
		text-decoration: none !important;
	}
}
p[embeddedService-chatHeaderAnnouncement_chatHeaderAnnouncement] {
	background-color: $green !important;
}
.announcement[embeddedService-chatHeaderAnnouncement_chatHeaderAnnouncement] {
	color: white;
	font-weight: $font-atten-book !important;
	background-color: $green !important;
}
button.startButton.uiButton--default.uiButton.embeddedServiceSidebarButton {
	background-color: $green !important;
	&:focus {
		text-decoration: none !important;
	}
}
button.dialogButton.dialog-button-0.uiButton.embeddedServiceSidebarButton {
    background: $green !important;
    &:focus {
		box-shadow: none !important;
	}
}
button.endChatButton.saveTranscriptButton.uiButton--inverse.uiButton.embeddedServiceSidebarButton {
	background: $green !important;
	font-weight: $font-atten-book !important;
	font-size: 14px !important;
}
.embeddedServiceSidebarButton {
	&:not(:disabled) {
		&:focus {
			background: $green !important;
			font-weight: $font-atten-book !important;
			text-decoration: none !important;
		}
		&:hover {
			background: $green !important;
			font-weight: $font-atten-book !important;
			text-decoration: none !important;
		}
	}
}
.embeddedServiceLiveAgentQueuePosition {
	.embeddedServiceIcon {
		>svg {
			background: unset !important;
		}
	}
}

#custom_btn{
    #twoform{
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        @media (max-width: 768px) {
            flex-direction: column;
            gap: 10;
        }
        a{
            width: 45%;
            @media (max-width: 768px) {
                width: 100%;
             }
        }
        .schedule_tour_div{
            width: 50%;
            @media (max-width: 768px) {
                width: 100%;
            }
            a{
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}


.alert_popup{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    .popup_message{
        padding: 30px;
        color: $black-new;
        font-size: 20px;
        border: 5px solid $green;
        border-radius: 20px;
        background-color: $white;
        position: relative;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 3.9px;
        font-weight: $font-atten-bold;
        line-height: 25px;
        a{
            color: $green;
        }
        p{
            margin: 0;
        }
        span{
            position: absolute;
            display: inline-block;
            top: 3%;
            right: 2%;
            cursor: pointer;
        }
    }

    @include max-width($iphonex-landscape){
        .popup_message{
            max-width: 90%;
        }
    }
}


.breadcrumbs_section{
    .breadcrumbs_list{
        padding: 15px 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li{
            display: inline-block;
            font-size: 12px;
            font-weight: $font-atten-bold;
            a{
                color: $gray-new;
                cursor: pointer;
                text-transform: uppercase;
                padding: 0 6px;
                font-weight: 500;
                &:hover{
                    color: $gray-new;
                    text-decoration: none;
                }
                &.current_breadcrumb{
                    cursor: unset;
                }
            }
            &.breadcrumb_separator{
                font-size: 10px;
                color: $gray-new;
                span{
                    vertical-align: top;
                    line-height: normal;
                }
            }
        }

        @include max-width($desktop-xl){
            li{
                font-size: 10px;
                &.breadcrumb_separator{
                    font-size: 8px;
                }
                a{
                    padding: 0 4px;
                }
            }
        }
        @include max-width-orientation($iphonex-landscape,'landscape'){
            padding: 10px 0;
            li{
                font-size: 8px;
                &.breadcrumb_separator{
                    font-size: 6px;
                }
            }
        }
        @include max-width($iphone4-landscape){
            padding: 10px 0;
            padding-right: 10%;
            line-height: normal;
            li{
                font-size: 10px;
                &.breadcrumb_separator{
                    font-size: 5px;
                }
            }
        }

    }
}


/*static page css*/
.global-main{
    .staic-content {
        padding: 0 5%;
        padding-bottom: 2%;
    }
    .neighborhood-staic-content {
        padding: 0 5%;
        padding-bottom: 2%;
        display: flex;
        flex-direction: column;
        .neigh_anchor{
            width: max-content;
        }
    }
    .static_button_content {
        text-align: center;
        margin-bottom: 3%;
        .learn-more {
            padding: 1% 7%;
            width: 100%;
            border: 1px solid;
            color: #fff;
            margin-right: 5%;
            text-decoration: none;
            text-transform: uppercase;
            background-color: $green;
            &:last-child {
                margin-right: 0 !important;
                background-color: $black-new;
            }
        }
    }
}


.national_holiday_date{
	&.ui-state-disabled{
		opacity: .7;
		.ui-state-default{
			background-color: $black-new;
			color: $white;
		}
	}
}

.promotion_disclaimer {
    padding: 0 5% 5%;
        p{
            margin-bottom: 0;
        }
    @include max-width($iphone4-landscape){
        padding: 0 10% 5%;
    }
}
.Utour-screen {
    z-index: 999999 !important;
}
.schedule-Modal{
    z-index: 9999999 !important;
}
.tph-ui-modal{
    #tour_form{
        display: block !important;
    }
    #priority_group_form{
        display: block !important;
    }
    .modal-dialog .modal-content .modal-header {
        padding: 0;
        background-color: #fff;
        .close {
            cursor: pointer;
            position: absolute;
            z-index: 1;
            background: unset;
            border-radius: 50%;
            border: 1px solid #fff;
            outline: none;
            color: #fff;
            right: 35px;
            top: 20px;
            height: 50px;
            width: 50px;
            outline: none;
            span {
                font-size: 25px;
                text-transform: unset;
                line-height: 0;
            }
            @include max-width($iphonex-landscape){
                border: 1px solid #545454;
                color: #545454;
                right: 10px;
                top: 10px;
                height: 40px;
                width: 40px;
            }
        }
    }
}

.form_image_overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background: transparent linear-gradient(180deg,transparent,rgba(0,0,0,.30)) 0 0 no-repeat padding-box;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000C1 100%) 0% 0% no-repeat padding-box;
}

.Mpc_main_section {
    .mpc-list {
        padding: 0;
        li {
            list-style: none;
        }
    }
}