@import '../../assets/scss/partials/variables';
@import '../../assets/scss/partials/mixins';
@import '../../assets/scss/partials/fonts';

.heading-section {
    margin: auto;

    .main-title {
        color: var(--gray-mid);
        // font-family: $font-secondary;
        font-size: 40px;
        // font-weight: 500;
        font-weight: $font-atten-medium;
        font-family: var(--font-family-medium);
        letter-spacing: -0.05em;
        line-height: 1;
        margin: 0;
        padding: 25px 0;
        word-break: break-word;

        @media (min-width: 768px) {
            font-size: 50px;
        }

        @media (min-width: 1024px) {
            font-size: 60px;
        }

        @media (min-width: 1601px) {
            font-size: 80px;
        }
    }

    .main-title--small {
        font-size: 32px;

        @media (min-width: 1024px) {
            font-size: 48px;
        }
    }

    .green-color {
        color: $green;
    }

    .gray-color {
        color: var(--gray-mid);
    }

    .new-black-color {
        color: var(--black);
    }

    .white-color {
        color: $white;
    }
}

.spring-section {
    background-color: $green;
    overflow: hidden;
    padding: 7% 5%;

    .heading-section {
        text-align: left;

        p,
        h2 {
            color: $white;
            width: 60%;
        }
    }

    .text-flex {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
    }

    .title-info {
        color: $black-new;
        width: 60%;
        font-size: 20px;
        margin: 0;
        letter-spacing: -1.1px;
        line-height: 30px;

        a {
            color: $white;

            &:hover {
                color: $white;
            }
        }

        li {
            color: $white;

            &:hover {
                color: $white;
            }
        }

        p {
            margin: 0;
        }

        .title-info {
            padding: 0;
        }
    }

    .learn-more.no-link {
        pointer-events: none;
    }

    @include max-width($desktop-large) {
        .title-info {
            padding-top: 0;
        }
    }

    @include max-width($iphonex-landscape) {
        padding: 10% 5%;

        .heading-section {
            p,
            h2 {
                width: 100%;
            }
        }

        .learn-more {
            margin-top: 20px;
        }

        .text-flex {
            display: block;
        }

        .title-info {
            padding-bottom: 20px;
            width: 100%;
        }
    }

    @include max-width($iphone6-landscape) {
        padding: 15% 10%;
    }
}

.spring-section--no-padding-h {
    padding-left: 0;
    padding-right: 0;
}

.testimonals-main {
    position: relative;

    .owl-stage {
        display: flex;

        &:after {
            display: none;
        }
    }

    .owl-nav {
        margin: -17px 0 0;
        position: absolute;
        top: 50%;
        width: 100%;

        button {
            border-radius: 50% !important;
            margin: 0;
            position: absolute;

            &:after {
                border-bottom: 2px solid $white;
                border-right: 2px solid $white;
                content: '';
                height: 20px;
                position: absolute;
                right: 20px;
                top: 43%;
                transform: rotate(-45deg);
                width: 20px;
            }

            span {
                display: none;
            }
        }

        .owl-prev {
            left: 0;

            &:after {
                left: 20px;
                transform: rotate(135deg);
            }
        }

        .owl-next {
            right: 0;
        }
    }

    .owl-dots {
        bottom: 0;
        position: absolute;
        padding-bottom: 10px;
        width: 100%;

        .owl-dot {
            span {
                background: #869791 !important;
            }

            &.active span {
                background: $white !important;
            }
        }
    }

    @include max-width($desktop-large) {
        .owl-nav {
            button:after {
                height: 15px;
                right: 10px;
                width: 15px;
            }

            .owl-prev:after {
                left: 10px;
            }
        }
    }

    @include max-width($iphone4-landscape) {
        .owl-nav {
            button:after {
                height: 10px;
                right: 2px;
                width: 10px;
            }

            .owl-prev:after {
                left: 2px;
            }
        }
    }
}

.testimonals-section {
    background-color: $green;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .heading-section {
        text-align: left;
        width: 100%;
        margin: 0;

        p,
        h2,
        h3 {
            color: $white;
            width: 100%;
        }
    }

    .small-text {
        color: $white;
        margin: 0;
        padding-top: 5px;
    }
}
