@import '../../assets/scss/partials/variables';
@import '../../assets/scss/partials/mixins';
@import '../../assets/scss/partials/fonts';

.no-mr {
    margin: 0;
}

.main-div {
    .accordion-newHomeSection {
        overflow: hidden;

        .accordionWrapper {
            position: relative;

            .headerArea {
                width: 50%;
                margin-bottom: 80px;
                padding-right: 5%;

                .heading-section {
                    margin: 0;
                    text-align: left;
                    width: 75%;

                    .sub-title {
                        padding-top: 0;
                    }

                    .main-title {
                        padding: 35px 0 15px;
                    }
                }
                .focus-boxes-subtext {
                    width: 90%;
                    font-size: 20px;
                    letter-spacing: -1.1px;
                    line-height: 30px;
                    color: $black-new;
                }
            }

            .accordionWrapperItem {
                width: 50%;
                min-height: 600px;
                padding-right: 5%;
                .item {
                    margin-bottom: 76px;
                    cursor: pointer;

                    &:last-child {
                        .itemHead {
                            &:before {
                                content: ' ';
                                position: absolute;
                                width: calc(100% - 45px);
                                bottom: -38px;
                                height: 1px;
                                background: #e5e5e5;
                            }
                        }
                    }

                    .itemHead {
                        opacity: 0.4;
                        position: relative;
                        transition: opacity 0.5s ease;
                        padding-right: 100px;

                        &:after {
                            content: ' ';
                            position: absolute;
                            width: calc(100% - 45px);
                            top: -38px;
                            height: 1px;
                            background: #e5e5e5;
                        }

                        .description {
                            a,
                            a:hover,
                            a:visited {
                                color: $charcoal;
                                border-bottom: 2px solid $apple;
                                font-size: 24px;
                                font-weight: 500;
                                letter-spacing: -1.2px;
                                line-height: 30px;
                                text-decoration: none;
                                display: inline-block;
                                margin-top: 8px;
                            }
                        }

                        .focus-boxes-heading {
                            max-width: 380px;
                            // font-family: $font-secondary;
                            font-size: 28px;
                            line-height: 34px;
                            color: $black-new;
                            margin-bottom: 18px;
                            letter-spacing: -0.7px;
                        }

                        p {
                            margin-bottom: 0;
                        }

                        .focus-boxes-description {
                            max-width: 380px;
                            margin-bottom: 0;
                            font-weight: $font-atten-medium;
                            font-size: 20px;
                            line-height: 26px;
                            letter-spacing: -0.5px;
                            color: $black-new;
                            white-space: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        .arrowRight {
                            position: absolute;
                            top: 34px;
                            right: 45px;
                            border: 1px solid $new-border-color;
                            height: 45px;
                            width: 45px;

                            &:after,
                            &:before {
                                content: ' ';
                                position: absolute;
                                top: 50%;
                                left: 45%;
                            }

                            &:after {
                                background: currentColor;
                                transform: translate(-50%, -50%);
                                height: 2px;
                                width: 16px;
                            }

                            &:before {
                                width: 8px;
                                height: 8px;
                                border-top: 2px solid currentColor;
                                border-right: 2px solid currentColor;
                                transform: translate(-1px, -50%) rotate(45deg);
                            }
                        }

                        &.active {
                            opacity: 1;

                            .focus-boxes-description {
                                display: block;
                            }
                        }

                        .mobileAccordion,
                        .mobileDesc,
                        .mobileImgArea {
                            display: none;
                        }
                    }

                    .imgarea {
                        position: absolute;
                        right: 0;
                        width: 50%;
                        height: 0px;
                        padding-bottom: calc(50% * 1.476);
                        overflow: hidden;
                        top: 0;
                        display: none;
                        > img {
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            object-fit: cover;
                            position: absolute;
                            opacity: 0;
                            transition: opacity 0.5s ease;
                        }

                        &.active {
                            display: block;
                            > img {
                                opacity: 1;
                            }
                        }
                    }

                    &:hover {
                        .itemHead {
                            opacity: 1;
                        }
                    }
                }
            }

            &.widerContent {
                .headerArea {
                    width: unset;
                }

                .accordionWrapperItem {
                    width: 60%;

                    .item {
                        .itemHead {
                            .focus-boxes-heading {
                                max-width: unset;
                            }

                            .focus-boxes-description {
                                max-width: unset;
                            }
                        }

                        .imgarea {
                            width: 40%;
                        }
                    }
                }
            }
        }
    }

    @include max-width($desktop-xl) {
        .accordion-newHomeSection {
            .accordionWrapper {
                .headerArea {
                    margin-bottom: 60px;
                }

                .accordionWrapperItem {
                    min-height: 795px;

                    .item {
                        margin-bottom: 50px;

                        .itemHead {
                            &:after {
                                top: -25px;
                            }

                            .focus-boxes-heading {
                                font-size: 24px;
                                line-height: 1.5;
                            }

                            .focus-boxes-description {
                                line-height: 1.5;
                            }
                        }

                        &:last-child {
                            .itemHead {
                                &:before {
                                    bottom: -25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include max-width($desktop-large) {
        .accordion-newHomeSection {
            .accordionWrapper {
                // .headerArea {
                //     .focus-boxes-subtext{
                //         font-size: 16px;
                //     }
                // }
                .accordionWrapperItem {
                    min-height: 684px;
                    //         .item .itemHead {
                    //             a, a:hover, a:visited {
                    //                 font-size: 18px;
                    //                 letter-spacing: -1px;
                    //                 line-height: 24px;
                    //         }
                    //     }
                }
            }
        }
    }

    @include max-width($macbook) {
        .accordion-newHomeSection {
            .accordionWrapper {
                .accordionWrapperItem {
                    min-height: 500px;
                    padding-right: 0;
                }
            }
        }
    }

    @include max-width($tablet-landscape) {
        .accordion-newHomeSection {
            .accordionWrapper {
                .accordionWrapperItem {
                    min-height: 444px;
                }
            }
        }
    }

    @include max-width($iphonex-landscape) {
        .accordion-newHomeSection {
            .accordionWrapper {
                &.widerContent .accordionWrapperItem {
                    width: unset;

                    .item .imgarea {
                        width: unset;
                    }
                }

                .mobileImageArea {
                    height: 300px;
                    width: 100%;
                    margin-bottom: 40px;
                }

                .headerArea {
                    width: 100%;
                    .focus-boxes-subtext {
                        width: 100%;
                    }
                }

                .accordionWrapperItem {
                    width: 100%;
                    min-height: 1px;

                    .item {
                        .itemHead {
                            padding-right: 0px;
                            opacity: 1;

                            &:after,
                            &:before {
                                width: 100%;
                                display: none;
                            }

                            .focus-boxes-heading {
                                margin-bottom: 0;
                                padding-right: 50px;
                                word-break: break-word;
                            }

                            .mobileDesc {
                                display: block;
                            }

                            .focus-boxes-description {
                                display: none;
                            }

                            .mobileAccordion {
                                padding: 20px 0 0 0;
                                overflow: hidden;
                                display: none;

                                .mobileDesc,
                                .mobileImgArea {
                                    display: block;
                                }

                                .mobileDesc {
                                    max-width: 100%;
                                    margin-bottom: 0;
                                    font-weight: $font-atten-book;
                                    line-height: 26px;
                                    letter-spacing: -0.5px;
                                    color: $black-new;
                                    display: block;
                                    font-size: 20px;
                                }

                                .mobileImgArea {
                                    display: none;

                                    > img {
                                        height: 300px;
                                        max-width: 100%;
                                        display: block;
                                        margin-bottom: 20px;
                                    }
                                }
                            }

                            .arrowRight {
                                top: 0;
                                right: 0;
                                width: 30px;
                                height: 30px;
                                transition: all 0.5s ease;
                            }

                            &.active {
                                .arrowRight {
                                    transform: rotate(90deg);
                                }

                                .focus-boxes-description {
                                    display: none;
                                }
                            }
                        }

                        .imgarea {
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 300px;
                            padding: 0;
                            pointer-events: none;

                            &.active {
                                pointer-events: all;
                            }
                        }
                    }
                }
            }
        }
    }
}
