@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.review-section-view {
  background-color: $bglightgray;
  padding-bottom: 20px;

  .eliant_logo {
    margin-top: -7px;
  }

  .eliant_logo img {
    width: 153px;
  }

  .owl-dots {
    background: #cbcbcb;
    bottom: -30px;
    display: flex;
    height: 2px;
    left: 0;
    position: absolute;
    transform: translateY(100%);
    width: 100%;

    .owl-dot {
      flex-grow: 1;
      height: 2px;
      width: 100%;

      &.active {
        background: var(--black);
      }

      span {
        display: none;
      }
    }
  }

  .owl-item {
    padding: 0 1px;
  }

  .owl-nav {
    height: 50px;
    left: 50%;
    margin: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + var(--owl-nav-width-offset, 0px) + (min(var(--wrap-padding-h, 32px), 50px) * 2));

    @media (max-width: 1023px) {
      display: none;
    }

    @media (min-width: 2000px) {
      --owl-nav-width-offset: 40px;
    }

    button[class*=owl-] {
      background: none !important;
      border-radius: 0 !important;
      display: block;
      height: 50px;
      margin: 0;
      pointer-events: auto;
      position: absolute;
      top: 0;
      width: min(var(--wrap-padding-h, 32px), 50px);

      &:after {
        border-bottom: 2px solid $black-new;
        border-right: 2px solid $black-new;
        content: "";
        height: 20px;
        position: absolute;
        right: 20px;
        top: 43%;
        transform: rotate(-45deg);
        width: 20px;
      }
    }

    span {
      display: none;
    }

    button[class*=owl-].owl-prev {
      left: 0;
    }

    button[class*=owl-].owl-next {
      right: 0;
    }

    button[class*=owl-].owl-next:after,
    button[class*=owl-].owl-prev:after {
      left: 50%;
      margin-left: -3px;
      right: auto;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    button[class*=owl-].owl-prev:after {
      margin-left: 3px;
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }

  .owl-stage {
    display: flex;
  }

  .reviews-data {
    color: $gray-new;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.1em;
  }

  .reviews-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .reviews-logo {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 45%;
  }

  .review_logo_data {
    display: flex;
    width: 100%;
  }

  .review_rating {
    display: flex;
    margin-right: 12px;
    position: relative;
    top: -2px;
  }

  .reviews-slider {
    // padding-bottom: 62px;
    position: relative;
  }

  .reviews-slider-div {
    border: 1px solid #DBDBDB;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 8%;

    h5 {
      border-bottom: 3px solid $green;
      color:$black-new;
      // font-family: $font-secondary;
      font-size: 28px;
      font-weight: bold;
      line-height: 37px;
      max-width: max-content;
    }

    .review-star1 {
      margin-bottom: 20px;
    }

    .star-text {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .review-star1 {
    align-items: center;
    display: flex;
    margin-top: 15px;

    @include max-width($iphone4-landscape) {
      align-items: flex-start;
      flex-direction: column;
      gap: 7px;
    }

    .star-review {
      align-items: center;
      display: flex;
      margin-right: 12px;
      position: relative;
      top: -2px;
    }

    .star-text {
      margin: 0;
      padding-right: 10px;
    }

    .total-review {
      letter-spacing: 0.1em;
      margin: 0;
    }

    span {
      color: $green;
      display: inline-block;
      font-size: 21px;
      padding-right: 3px;
    }

    svg {
      display: block;
    }
  }

  .reviews-text {
    color: $black-new;
    font-size: 20px;
    line-height: 1.5;
    margin: 0 0 10px;
  }

  .single-image {
    height: 100%;
    margin: 0 auto;
    width: 45%;

    @include max-width($tablet){
      width: 85%;
    }
  }

  .silder-text {
    flex-grow: 1;

    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }

  .star_content {
    color: #969696;
    font-weight: 600;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  @include max-width($tablet) {
    .eliant_logo{
      height: 100px;
      width: 176px;

      img {
        width: 157px;
      }
    }

    .reviews-info {
      flex-direction: column-reverse;
    }

    .reviews-logo {
      order:-1;
      padding: 20px 0 0;
      width: 100%;
    }
  }
}

.review_loader {
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;

  span {
    height: 80px;
    width: 80px;
  }
}

.reviews-heading {
  --section-header-title-margin-bottom: 10px;
}

@media (min-width: 768px) {
  .reviews-heading {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .reviews-heading {
    --section-header-title-margin-bottom: 20px;
  }

  .reviews-heading,
  .review-section-view .reviews-info .reviews-logo {
    width: calc(50% - var(--reviews-header-col-gap, 10px));
  }

  .reviews-logo {
    padding-top: 5px;
  }

  .reviews-logo .review_logo_data {
    justify-content: flex-end;
  }

  .review-section-view .reviews-slider {
    // padding-bottom: 122px;

    .owl-dots {
      bottom: -50px;
    }
  }
}

@media (min-width: 1280px) {
  .reviews-info {
    --reviews-header-col-gap: 20px;
  }
}

@media (min-width: 1601px) {
  .reviews-info {
    --reviews-header-col-gap: 24px;
  }
}

@media (min-width: 1664px) {
  .review-section-view .reviews-info {
    padding-bottom: 50px;
  }
}
