@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.product-card-section {
    background-color: $white;
    margin-bottom: 2%;
    .sectiontitle-main-div {
        .sectiontitle-headline{
            padding: 2% 5% 1% 0;
            @include max-width($iphonex-landscape){
                padding:  5% 0% 0% 0% ;
            }
        }
        
    }
    .qmi-card {
        justify-content: start;
        padding: 0.5% 4% 0;
        display: flex;
        flex-wrap: wrap;

        .card-main-div {
            margin-bottom: 4%;
            
            .card-imgs {
                position: relative;
                height: 653px;
                padding: 25px; // overflow: hidden;
                z-index: 1;
                .plan-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    .overlay {
                        height: 100%;
                        &:after {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: transparent linear-gradient(180deg, #00000000 0%, #000000 300%) 0% 0% no-repeat padding-box;
                    }
                }
                }
                .card-snipe {
                    position: absolute;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    width: 100%;
                    padding: 8px;
                    background-color: $green;
                    font-weight: $font-atten-medium;
                    color: $white;
                    font-size: 12px;
                    text-align: center;
                    text-transform: uppercase;
                }
                .promo-icon{ 
                    height:70px; 
                    width:70px; 
                    position: absolute; 
                    z-index: 2; 
                    top: 0; 
                    left: 0; 
                    background-repeat: no-repeat;
                }
                .card-status {
                    background: $white;
                    padding: 7px 10px;
                    display: inline-block;
                    font-size: 11px;
                    font-weight: $font-atten-bold;
                    color:$black-new;
                    line-height: 13px;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                    letter-spacing: 1.1px;
                }
                .card-img-info {
                    position: absolute;
                    z-index: 7;
                    height: auto;
                    bottom: 30px;
                    color: $white;
                    padding-right: 10px;
                    width: 90%;
                    .card-name {
                        font-size: 37px;
                        letter-spacing: -0.93px;
                        line-height: 51px;
                        margin-bottom: 5px; 
                        font-weight: $font-atten-medium;
                    }
                    .card-price {
                        font-size: 20px;
                        line-height: 27px;
                        margin: 0;
                    }
                }
            }

            .card-info {
                .card-detail {
                    display: flex;
                    justify-content: space-evenly;
                    margin: 0;
                    padding: 15px 0 10px;
                    align-items: center;
                    width: 100%;
                    li {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        list-style: none;
                        border-right: 1px solid $lightgray;
                        padding: 10px 5px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        text-align: center;
                        color: $black-new;
                        font-weight: bold;
                        font-size: 11px;
                        &:last-child {
                            border-right: none;
                        }
                        .info-value {
                            font-size: 12px;
                            font-family: $black-new;
                            margin-bottom: 5px;
                            line-height: 25px;
                        }
                        .info-name {
                            font-size: 10px;
                            color: $gray-new;
                        }
                    }
                }
                .qmi-learn-more {
                    display: none;
                }
            }
            .card-hover-content {
                overflow: hidden;
                // width: calc(100% - 30px);
                .qmi-card-content {
                    
                    .card-name {
                        font-size: 22px;
                        font-weight: $font-atten-medium;
                        width: 80%;
                    }
                    .card-text {
                        min-height: 40px;
                        p{
                            font-size: 20px;
                            line-height: 30px;
                            font-weight: $font-atten-book;
                            font-weight: 500;
                            color: $bordergray;
                        }
                    }
                    .learn-more {
                        border: 1px solid $new-border-color;
                        margin: 0 auto;
                        color: $black-new;
                        padding: 2% 10%;
                        text-align: center;
                        font-weight: $font-atten-bold;
                        font-size: 13px;
                        letter-spacing: 2px;
                        display: flex;
                        justify-content: center;
                        font-weight: bold;
                        line-height: 25px;
                        &:hover {
                            text-decoration: none;
                            color: $black-new;
                            background-color: $green;
                        }
                    }
                }
            }
            // &:hover {
            //     .card-imgs {
            //         .plan-img-info {
            //             display: block;
            //         }
            //     }
            // }
            @include max-width($desktop-xl) {
            
                .card-imgs {
                    height: 600px;
                }
                
            }
            @media (max-width:820px) and (min-width:$iphone6) {
              .card-img-info{
                height: 179px!important;
                font-size: 29px!important;
              }
            }
            @include max-width($iphone5){
                .card-img-info{
                    height: 179px!important;
                    font-size: 29px!important;
                  }
            }
            @include max-width($desktop-large) {
                
                .card-imgs {
                    height: 500px;
                    .card-img-info {
                        .card-name {
                            font-size: 34px;
                            line-height: 40px;
                        }
                        .card-price {
                            font-size: 18px;
                            line-height: 21px;
                        }
                    }
                }
                .card-info {
                    .card-detail {
                        li {
                            .info-value {
                                font-size: 12px;
                            }
                            .info-name {
                                font-size: 9px;
                            }
                        }
                    }
                }
                .card-hover-content{
                    .qmi-card-content{
                        .card-text{
                            p{
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            @include max-width($macbook) {
                .card-info {
                    .card-detail {
                        li {
                            .info-value {
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
            @include max-width($tablet-landscape) {
               
                .card-imgs {
                    height: 442px;
                    .card-img-info {
                        .card-name {
                            font-size: 30px;
                            line-height: 40px;
                        }
                    }
                    .card-snipe {
                        color: $black-new;
                    }
                    
                }
                
               
                .card-info {
                    .card-detail {
                        li {
                            letter-spacing: normal;
                            .info-value {
                                font-size: 8px;
                            }
                            .info-name {
                                font-size: 8px;
                            }
                        }
                    }
                }
                .card-hover-content {
                    .qmi-card-content{
                        .card-text{
                            p{
                                font-size: 16px;
                                line-height: 25px;
                            }
                        }
        
                    }
                    
                }
            }
           
            @include max-width($iphone6-plus-landscape) {
                margin-bottom: 8%;
               
            }
            
            @include max-width($iphone4-landscape) {
                margin-bottom: 12%;
                padding: 2px;
                .card-imgs {
                    height: 430px !important;
                
                }
               
                .card-info {
                    .card-detail {
                        padding-bottom: 50px;
                        box-shadow: 0 5px 6px rgba(0, 0, 0, .1);
                        li {
                            border: none;
                            padding: 15px 0px;
                            width: auto;
                            .info-value {
                                font-size: 12px;
                            }
                            .info-name {
                                font-size: 10px;
                            }
                        }
                    }
                    .qmi-learn-more {
                        display: block;
                        position: absolute;
                        top: 90%;
                        z-index: 1;
                        padding: 3px;
                        width: 94%;
                        margin-top: 2%;
                        margin-left: 7px;
                        border: 1px solid $new-border-color;
                        color: $black-new;
                        text-align: center;
                        font-weight: $font-atten-bold;
                        font-size: 10px;
                        letter-spacing: 2px;
                        line-height: 25px;
                        font-weight: bold;
                        &:hover {
                            text-decoration: none;
                            color: $black-new;
                            background-color: $green;
                        }
                    }
                }
                .card-hover-content{
                    display: none !important;
                }
            }
        }


        .owl-carousel{
            position: relative;
            margin-bottom: 5%;
            .owl-stage-outer{
                .owl-stage{
                    padding: 0 !important;
                    .owl-item{
                        width: 610px;
                        
                    }
                }
            }


            .owl-nav {
                display: flex;
                position: absolute;
                bottom: -21px;
                left: 5%;
                transform: translateY(100%);

                button {
                    margin: 0;
                    border: 0;

                    &.disabled {
                        opacity: 1;
                    }

                    &:hover {
                        background: transparent;
                        color: inherit;
                    }

                    .sliderArrow {
                        position: relative;
                        border: 1px solid $new-border-color;
                        height: 61px;
                        width: 61px;

                        &:after,
                        &:before {
                            content: " ";
                            position: absolute;
                            top: 50%;
                            left: 45%;
                        }

                        &:before {
                            width: 8px;
                            height: 8px;
                            border-top: 2px solid $black-new;
                            border-right: 2px solid $black-new;
                            transform: translate(-1px, -50%) rotate(45deg);
                        }

                        &:after {
                            background: $black-new;
                            transform: translate(-50%, -50%);
                            height: 2px;
                            width: 16px;
                        }

                        &.arrowLeft {
                            transform: rotate(180deg);
                        }
                    }

                }
            }

            .owl-dots {
                position: absolute;
                bottom: -50px;
                transform: translateY(100%);
                background: $new-border-color;
                height: 2px;
                display: flex;
                width: 75%;
                left: 15%;

                .owl-dot {
                    height: 2px;
                    flex-grow: 1;
                    width: 100%;

                    &.active {
                        background: $black-new;
                    }

                    span {
                        display: none;
                    }
                }
            }

            button:focus,
            button:active {
                outline: 0;
                border: 0;
            }
        }
        @include max-width($desktop-large) {
            .owl-carousel {
                .owl-nav {
                    left: 5%;
                    button {
                        .sliderArrow {
                            height: 50px;
                            width: 50px;
                         }
                     }
                }
                .owl-dots {
                    bottom: -46px;
                    left: 16%;
                }
            }
        }
        @include max-width($tablet) {
            .owl-carousel {
                .owl-nav{
                    button {
                        .sliderArrow {
                            height: 45px;
                            width: 45px;
                         }
                     }
                }
                .owl-dots {
                    width: 74%;
                    left: 19%;
                    bottom: -42px;
                }
            }
        }

        @include max-width($iphonex-landscape) {
            .owl-carousel {
                .owl-dots {
                    left: 19%;
                    bottom: -42px;
                }
            }
        }

        @include max-width($iphone6-landscape) {
            .owl-carousel {
                .owl-nav{
                    button {
                        .sliderArrow {
                            height: 45px;
                            width: 45px;
                         }
                     }
                }
                .owl-dots {
                    width: 74%;
                    left: 22%;
                    // bottom: -6px;
                }
            }
        }

         
        
        @include max-width($iphone4-landscape) {
            padding: 0.5% 10% 5%;
            
            .owl-carousel{
                .owl-nav {
                    left: 0;
                }

                .owl-dots {
                    width: 95%;
                    left: 0;
                    margin-bottom: 10px;
                    bottom: -12px;
                }
            }
        }
    }
}

