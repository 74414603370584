// Breakpoint
// @include max-width(768px) {} // -> outputs: @media (max-width: 768px)
@mixin max-width($breakpoint, $media-query-type: 'max-width', $media-query: '#{$media-query-type}: #{$breakpoint}') {
	@media (#{$media-query}) {
		@content;
	}
}

// Breakpoint
// @include breakpoint(768px) {} // -> outputs: @media (min-width: 768px)
@mixin min-width($breakpoint, $media-query-type: 'min-width', $media-query: '#{$media-query-type}: #{$breakpoint}') {
	@media (#{$media-query}) {
		@content;
	}
}

// Breakpoint
// @include breakpoint(768px) {} // -> outputs: @media (min-width: 768px)
@mixin max-width-orientation($breakpoint,$orientation, $media-query-type: 'max-width', $media-query: '#{$media-query-type}: #{$breakpoint}',$orientation_query: '(orientation: #{$orientation})') {
	@media (#{$media-query}) and #{$orientation_query} {
		@content;
	}
}

@mixin common-button {
    border-radius: 0;
    font-size: 20px;
	padding: 18px 20px;
	line-height: 25px;
	text-decoration: none;
	text-align: center;
	display: inline-block;
	@include max-width($desktop-large) {
		font-size: 16px;
		padding: 15px 20px;
	}
	@include max-width($iphone6-plus-landscape) {
		padding: 10px 20px;
	}
	@include max-width($iphone4-landscape) {
		width: 100%;
	}
}
@mixin green-filled-button {
    background-color: $green !important;
	border: 2px solid $green !important;
	color:$black-new;
	box-shadow: none;
	font-size:13px !important;
	line-height: 25px;
	letter-spacing: 3.9px;
	font-weight: $font-atten-bold !important;
	text-transform: uppercase;
	min-width: 260px;
	@include common-button;
}
@mixin green1-filled-button{
	// background-color: $green !important;
	// border: 2px solid $green !important;
	color:$black-new;
	box-shadow: none;
	font-size:13px !important;
	line-height: 25px;
	letter-spacing: 3.9px;
	font-weight: $font-atten-bold !important;
	text-transform: uppercase;
	min-width: 260px;
	@include common-button;
}
@mixin green-filled-form-button {
    background-color: $white !important;
	border: 1px solid $green !important;
	color:$black-new;
	box-shadow: none;
	font-size:13px !important;
	line-height: 25px;
	letter-spacing: 3.9px;
	font-weight: $font-atten-bold !important;
	text-transform: uppercase;
	min-width: 260px;
	@include common-button;
}

@mixin black-filled-button {
    color: $white;
    background-color: $black-new;
	border:2px solid $black-new;
	@include common-button;
	&:hover{
		color:$white;
		background-color: $black-new;
	}
}
@mixin  green-outline-button {
	color:$green;
    background-color: $white;
	border:2px solid $green;
	@include common-button;
}

@mixin  black-outline-button {
	color:$black-new;
	background-color:transparent; 
	border:1px solid $new-border-color;
	box-shadow: none;
	font-size:13px !important;
	line-height: 25px;
	letter-spacing: 3.9px;
	font-weight: 500;
	text-transform: uppercase;
	background-color:transparent; 
	&:hover{
		background-color:$green; 
		border:1px solid $green !important;
	}
	@include common-button;	
}
@mixin white-learn-more{
	border: 2px solid $white;
	font-weight: normal;
	min-width: 278px;
	color:$black-new;
	border: 1px solid $new-border-color;
	background-color:transparent; 
	font-size:13px !important;
	line-height: 25px;
	letter-spacing: 3.9px;
	font-weight: $font-atten-bold;
	text-transform: uppercase;
	@include common-button;
}
@mixin green-learn-more {
	color: $green;
	border: 2px solid $green;
	@include common-button;
}