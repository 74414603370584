.faq-section {
  background-color: #fff;
  padding: 80px var(--wrap-padding-h);
}

@media (min-width: 1024px) {
  .faq-section {
    padding-bottom: 100px;
    padding-top: 100px;
  }
}

@media (min-width: 1601px) {
  .faq-section {
    padding-bottom: 140px;
    padding-top: 140px;
  }
}

@media (max-width: 1023px) {
  .media-lockup + .faq-section {
    padding-top: 25px;
  }
}

.faq-section .accordion {
  margin: auto;
  padding-top: 50px;
}

@media (min-width: 1024px) {
  .faq-section .accordion {
    padding-top: 70px;
  }
}

.faq-section .accordion .card:not(:last-of-type),
.faq-section .accordion .card:last-of-type {
  border-color: #dbdbdb;
  border-width: 0 0 1px;
  border-style: solid;
}

.faq-section .accordion .card:first-child {
  border-width: 1px 0;
}

.faq-section .card-header {
  background: transparent;
  border: none;
  padding: 0;
}

.faq-section .card-header button{
  align-items: center;
  display: flex;
  line-height: 1.2;
  min-height: 86px;
  padding: 10px 30px 10px 0;
  text-decoration: none !important;
  width: 100%;
}

.faq-section .collapse,
.faq-section .collapsing {
  order: 1;

  &.show ~ .card-header .plus-icon {
    transform: rotate(-90deg);

    &:after {
      opacity: 0;
    }
  }

  .card-body {
    padding: 0 0 20px 0;
  }
}

.faq-section .collapsing ~ .card-header .plus-icon {
  transform: rotate(-90deg);

  &:after {
    opacity: 0;
  }
}

.faq-section .faq-description {
  padding-bottom: 30px;
}

.faq-section .ques-heading {
  align-items: center;
  color: var(--black);
  display: flex;
  justify-content: space-between;
  margin: 0;
  text-align: left;
  width: 100%;

  p {
    font-family: var(--font-family-medium);
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  i {
    font-size: 20px;
    transition: all 0.5s ease-in-out;
  }

  @media (max-width: 1023px) {
    p {
      font-size: 16px;
    }

    i {
      font-size: 16px;
    }
  }
}

.faq-section .sectiontitle-main-div {
  padding: 0;
}

.faq-section .sectiontitle-main-div .eyebrow-text-div {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: var(--visual-position, absolute) !important;
  width: 1px;
}

.faq-section .plus-icon {
  --plus-icon-transition: opacity 300ms ease-in-out;
  height: 70px;
  position: absolute;
  right: 0;
  top: 9px;
  transform-origin: center;
  transition: transform 300ms ease-in-out;
  width: 20px;

  @media (min-width: 1024px) {
    top: 12px;
  }
}