@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

#Geo_product{
    background-color: $green;
    padding: 7% 5%;
    margin: 3% 0%;
    overflow: hidden;
    .container_geo{
        display: flex;
        flex-wrap: wrap;
        .geo_box{
            width: 20%;
            margin-bottom: 20px;
            color: $black-new;
            // width: 60%;
            font-size: 20px;
            letter-spacing: -1.1px;
            line-height: 30px;
            // padding-top: 30px;
            p{
                margin:0;
            }
            a{
                color: $white;
                &:hover{
                    color: $white;
                }   
            }
            li{
                color: $white;
                &:hover{
                    color: $white;
                }   
            }
        }
        @include max-width($desktop) {
            .geo_box{
                width: 25%;
            }
        }
        @include max-width($iphonex-landscape) {
            .geo_box{
                width: 33%;
            }
        }
        @include max-width($tablet) {
            .geo_box{
                width: 50%;
            }
        }
        @include max-width($iphone4-landscape) {
            .geo_box{
                width: 100%;
                margin-bottom: 0;
            }
        }
        
    }
}