@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

#loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  z-index: 999;
  h2{
    position:absolute ;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 4em;
    font-weight: bold;
    color:$lightgray;
    z-index: 99;
  }
  .arrows-loading{
    position:absolute ;
    width:100%;
    height:100%;
    top:0;
    left:0;
  }

  .arrows-loading div{
  position:absolute ;
  display:block ;
  opacity: 0;
    img{
      object-fit: contain;
    }
  }
  .arrows-loading div:nth-child(1){
      top:20%;
      left:45%;
      animation:fall 4s linear;
      height:200px;
      width:220px;
  }
  .arrows-loading div:nth-child(2){
      top:55%;
      left:42%;
      animation:fall 3s linear;
      height:230px;
      width:265px;
  }
  .arrows-loading div:nth-child(3){
      top:13%;
      left:33%;
      animation:fall 5s linear;
      animation-delay:1.5s;
      height:156px;
      width:166px;
  }
  .arrows-loading div:nth-child(4){
    top:25%;
    left:35%;
    animation:fall 4s linear; 
    animation-delay:1s;
    height:100px;
    width:120px;
  }
  .arrows-loading div:nth-child(5){
      top:60%;
      left:30%;
      animation:fall 6s linear;
      animation-delay:2s;
      height:180px;
      width:200px;
  }
  .arrows-loading div:nth-child(6){
      top:8%;
      animation:fall 5s linear ;
      animation-delay:2.5s;
      height:120px;
      width:140px;
  }
  .arrows-loading div:nth-child(7){
      top:80%;
      animation:fall 7s linear ;
      animation-delay:5s;
      height:150px;
      width:180px;
  }
  .arrows-loading div:nth-child(8){
    top:55%;
    animation:fall 6s linear ;
    animation-delay:3.5s;
    height:150px;
    width:180px;
}
  .arrows-loading div:nth-child(9){
    top:15%;
    animation:fall 7s linear;
    animation-delay:6s;
    height:100px;
    width:120px;
  }
  .arrows-loading div:nth-child(10){
    top:75%;
    animation:fall 7s linear;
    animation-delay:6s;
    height:100px;
    width:120px;
  }
  .arrows-loading div:nth-child(11){
    top:30%;
    animation:fall 8s linear ;
    animation-delay:8s;
    height:100px;
    width:120px;
}
  .arrows-loading div:nth-child(12){
    top:55%;
    animation:fall 8s linear;
    animation-delay:8s;
    height:200px;
    width:220px;
  }

}
@keyframes fall{
  0%{
      opacity:0.2;
      left:25%;
  }
  30%{
    opacity:0.6;
    left:40%;
  }
  50%{
    opacity:1;
    left:50%;
  }
  70%{
    opacity:0.5;
    left:80%;
  }
  80%{
    opacity:0.2;
    left:90%;
  }
  90%{
    opacity:0;
    left:95%;
  }
}