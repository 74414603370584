:root {
    /* colors */
    --black: #242424;
    --gray-dark: #525252;
    --gray-mid: #848484;
    --gray-light: #bfbfc0;
    --green-2022: #bdd437;

    /* secondary colors */
    --border-color-dark: #898989;
    --border-color-light: #f3f3f3;
    --highlight-color: #cfe548;

    --font-family-medium: 'new-atten', Arial, sans-serif;
    --font-family-heavy: 'new-atten', Arial, sans-serif;

    --header-height: 80px;
    --header-height: 140px;
    --header-z-index: 10;

    --section-padding-v: 80px;
    --wrap-padding-h-default: clamp(
        var(--wrap-padding-h-min, 24px),
        var(--wrap-padding-h-val, 4vw),
        var(--wrap-padding-h-max, 32px)
    );
    --wrap-padding-h: var(--wrap-padding-h-default);
}

.title--x-large {
    --title-font-size: 70px;
}

@media (min-width: 768px) {
    :root {
        --eyebrow-font-size: 14px;
        --title-font-size: 50px;
        --wrap-padding-h-min: 40px;
        --wrap-padding-h-max: 150px;
        --wrap-padding-h-max: 64px;
    }

    .title--x-large {
        --title-font-size: 85px;
    }
}

@media (min-width: 1024px) {
    :root {
        --header-height: 140px;
        --header-padding-h: clamp(40px, 3.64583vw, 70px);
        --media-card-copy-color: var(--gray-mid);
        --media-card-copy-font-size: 20px;
        --media-card-title-color: var(--black);
        --media-card-title-font-size: 24px;
        --media-card-title-margin-top: 32px;
        --media-card-title-padding-top: 32px;
        --section-header-title-margin-bottom: 54px;
        --section-header-eyebrow-margin-bottom: 12px;
        --title-font-size: 60px;
    }

    .title--medium {
        --title-font-size: 80px;
    }

    .title--x-large {
        --title-font-size: 100px;
    }
}

/* UTILITY CLASSES */
.absolute {
    --bottom: var(--absolute-bottom, auto);
    --left: var(--absolute-left, auto);
    --right: var(--absolute-right, auto);
    --top: var(--absolute-top, auto);
    bottom: var(--bottom);
    position: var(--position, var(--position-mobile, absolute));
    left: var(--left);
    right: var(--right);
    top: var(--top);
}

@media (min-width: 1024px) {
    .absolute {
        --bottom: var(--absolute-bottom-desktop, var(--absolute-bottom));
        --left: var(--absolute-left-desktop, var(--absolute-left));
        --right: var(--absolute-right-desktop, var(--absolute-right));
        --top: var(--absolute-top-desktop, var(--absolute-top));
        position: var(--position, var(--position-desktop, absolute));
    }
}

.aspect-ratio {
    --position: relative;
    --ratio-height: var(--aspect-ratio-height, var(--aspect-ratio-height-mobile));
    --ratio-width: var(--aspect-ratio-width, var(--aspect-ratio-width-mobile));
    --aspect-ratio: calc(var(--ratio-height, 1) / var(--ratio-width, 1) * 100%);
    background-color: var(--aspect-ratio-background-color, #fafafa);
    overflow: var(--aspect-ratio-overflow, hidden);
    position: var(--position);
}

.aspect-ratio:before {
    content: '';
    display: block;
    padding-top: var(--aspect-ratio);
}

@media (min-width: 1024px) {
    .aspect-ratio {
        --ratio-height: var(--aspect-ratio-height, var(--aspect-ratio-height-desktop));
        --ratio-width: var(--aspect-ratio-width, var(--aspect-ratio-width-desktop));
    }
}

.background-fill:before {
    background-color: var(--background-color, var(--body-background-color));
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
}

.bold {
    font-weight: 700;
}

.badge {
    align-items: center;
    background-color: var(--badge-background-color, transparent);
    border: var(--badge-border, 1px solid);
    border-radius: var(--badge-border-radius, 4px);
    color: var(--badge-color, #9eb50d);
    display: var(--badge-display, inline-flex);
    font-size: var(--badge-font-size, 10px);
    font-weight: var(--badge-font-weight, 700);
    justify-content: center;
    letter-spacing: var(--badge-letter-spacing, 0.15em);
    line-height: 1.1;
    margin: var(--badge-margin, 0 14px 5px 0);
    min-height: var(--badge-min-height, 34px);
    min-width: var(--badge-min-width, 124px);
    padding: 8px 16px 6px;
    text-align: center;
    text-decoration: none;
    text-transform: var(--badge-text-transform, uppercase);
}

.block {
    display: block;
}

.border-custom {
    border: var(
        --border,
        var(--border-width, var(--border-width-mobile, 1px)) var(--border-style, var(--border-style-mobile, solid))
            var(--border-color, var(--border-color-mobile, inherit))
    ) !important;
}

.border-top-custom {
    border-top: var(--border-top-width, 1px) var(--border-top-style, solid) var(--border-top-color, inherit);
}

.button:not(div) {
    --btn-transition-default: background-color 150ms 300ms cubic-bezier(0.42, 0.46, 0.45, 0.99);
    align-items: center;
    background-color: var(--btn-background-color, var(--green-2022));
    border: var(--btn-border, none);
    border-radius: var(--btn-border-radius, 4px);
    color: var(--btn-color, var(--black));
    display: var(--btn-display, inline-flex);
    font-size: var(--btn-font-size, 14px);
    font-weight: var(--btn-font-weight, 700);
    justify-content: center;
    letter-spacing: var(--btn-letter-spacing, 0.2em);
    line-height: 1.1;
    min-height: var(--btn-min-height, 60px);
    min-width: var(--btn-min-width, 247px);
    overflow: hidden;
    padding: 15px 25px 12px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: var(--btn-text-transform, uppercase);
    transition: var(--btn-transition, var(--btn-transition-default));
    transition-delay: 0ms;
}

.button:not(div):before {
    background-color: var(--btn-background-color-hover, rgb(215 236 87));
    content: '';
    display: var(--btn-before-display, block);
    height: 750%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transform-origin: right top;
    transform: rotate(27deg) translate(25%, -85%);
    transition:
        100ms opacity 500ms cubic-bezier(0.42, 0.46, 0.45, 0.99),
        transform 600ms cubic-bezier(0.42, 0.46, 0.45, 0.99);
    width: 175%;
}

.button--light {
    --btn-background-color: #fff;
    --btn-background-color-hover: #f7f7f7;
}

.button:not(div) span {
    position: relative;
}

.button:not(div):hover {
    background-color: var(--btn-background-color-hover, rgb(215 236 87));
    color: var(--btn-color-hover, var(--black));
    text-decoration: none;
    transition-delay: var(--btn-transition-delay-hover, 300ms);
}

.button:not(div):hover:before {
    opacity: 1;
    transform: rotate(15deg) translate(-27%, 11%);
    transition-delay: 0ms, 0ms;
}

.button--alt {
    --btn-background-color: #fff;
    --btn-font-size: 14px;
    --btn-font-weight: 500;
    --btn-letter-spacing: 0;
    --btn-text-transform: none;
    --btn-min-height: 48px;
    --btn-min-width: 174px;
}

.button--hollow {
    --btn-background-color: transparent;
    --btn-border: 1px solid #bed437;
}

.button--hollow:focus {
    --btn-background-color: var(--btn-background-color-hover, var(--green-2022));
    --btn-color: var(--btn-color-hover, var(--#fff));
}

.button--small {
    --btn-font-size: 10px;
}

.back-btn:focus {
    --btn-background-color: #efefef;
    --btn-icon-transform: translateX(-5px);
}

.color-inherit {
    color: inherit;
}

.color-inherit:hover {
    color: inherit;
}

.cover {
    --bottom: auto;
    --height: 100%;
    --left: 0;
    --position: absolute;
    --right: auto;
    --top: 0;
    --width: 100%;
    bottom: var(--bottom);
    height: var(--height);
    left: var(--left);
    position: var(--position);
    right: var(--right);
    top: var(--top);
    width: var(--width);
}

.cursor {
    cursor: var(--cursor, var(--cursor-mobile, pointer));
}

.description {
    color: var(--description-color, #848484);
    font-size: var(--description-font-size, 16px);
    line-height: var(--description-line-height, 1.5);
}

.description + .button {
    margin-top: 30px;
}

@media (min-width: 1024px) {
    :root {
        --description-font-size: 20px;
    }
}

.display-block {
    display: block;
}

.eyebrow {
    color: var(--gray-light);
    font-size: var(--eyebrow-font-size, 12px);
    font-weight: 700;
    letter-spacing: var(--eyebrow-letter-spacing, 0.2em);
    margin-bottom: 0;
    text-transform: uppercase;
}

.eyebrow--small {
    --eyebrow-font-size: 12px;
    --eyebrow-letter-spacing: 0.15em;
}

.flex {
    align-items: var(--flex-align-items, var(--flex-align-items-mobile, flex-start));
    display: var(--flex-display, var(--flex-display-mobile, flex));
    flex-direction: var(--flex-direction, var(--flex-direction-mobile, row));
    flex-wrap: var(--flex-wrap, var(--flex-wrap-mobile, nowrap));
    justify-content: var(--flex-justify-content, var(--flex-justify-content-mobile, flex-start));
}

.flex--row-desktop {
    --flex-direction-mobile: column;
    --flex-direction-desktop: row;
}

.font-size {
    font-size: var(--font-size, var(--font-size-mobile, 1em));
    letter-spacing: var(--font-size-letter-spacing, var(--font-size-letter-spacing-mobile, 0));
    line-height: var(--font-size-line-height, var(--font-size-line-height-mobile, 1));
}

.font-weight--medium {
    font-weight: 500;
}

.grid {
    display: var(--grid-display, var(--grid-display-mobile, grid));
    grid-column-gap: var(--grid-column-gap, var(--grid-column-gap-mobile, 0));
    grid-row-gap: var(--grid-row-gap, var(--grid-row-gap-mobile, 0));
    grid-template-columns: var(
        --grid-template-columns-mobile,
        repeat(var(--grid-columns, var(--grid-columns-mobile, 1)), minmax(0, 1fr))
    );
}

.grid-column {
    grid-column: var(--grid-column, var(--grid-column-mobile, span var(--grid-columns, var(--grid-columns-mobile, 1))));
}

.grid-row {
    grid-row: var(--grid-row, var(--grid-column-mobile, 1));
}

.hover-zoom img {
    transform-origin: center;
    transition:
        opacity 250ms ease-in-out,
        transform 500ms ease-in-out;
}

.hover-zoom:hover img {
    transform: scale(1.05);
}

/* UTILITY CLASSES TABLET */

@media (min-width: 768px) {
    .grid {
        display: var(--grid-display, var(--grid-display-tablet, grid));
        grid-column-gap: var(--grid-column-gap, var(--grid-column-gap-tablet, 0));
        grid-row-gap: var(--grid-row-gap, var(--grid-row-gap-tablet, 0));
        grid-template-columns: var(
            --grid-template-columns-tablet,
            repeat(var(--grid-columns, var(--grid-columns-tablet, 1)), minmax(0, 1fr))
        );
    }

    .grid-column {
        grid-column: var(
            --grid-column,
            var(--grid-column-tablet, span var(--grid-columns, var(--grid-columns-tablet, 1)))
        );
    }
}

@media (min-width: 1024px) {
    .border-custom {
        border: var(
            --border,
            var(--border-width, var(--border-width-desktop, 1px))
                var(--border-style, var(--border-style-desktop, solid))
                var(--border-color, var(--border-color-desktop, inherit))
        ) !important;
    }

    .flex {
        align-items: var(--flex-align-items, var(--flex-align-items-desktop, flex-start));
        display: var(--flex-display, var(--flex-display-desktop, flex));
        flex-direction: var(--flex-direction, var(--flex-direction-desktop, row));
        flex-wrap: var(--flex-wrap, var(--flex-wrap-desktop, nowrap));
        justify-content: var(--flex-justify-content, var(--flex-justify-content-desktop, flex-start));
    }

    .font-size {
        font-size: var(--font-size, var(--font-size-desktop, 1em));
        letter-spacing: var(--font-size-letter-spacing, var(--font-size-letter-spacing-desktop, 0));
        line-height: var(--font-size-line-height, var(--font-size-line-height-desktop, 1));
    }

    .grid {
        display: var(--grid-display, var(--grid-display-desktop, grid));
        grid-column-gap: var(--grid-column-gap, var(--grid-column-gap-desktop, 0));
        grid-row-gap: var(--grid-row-gap, var(--grid-row-gap-desktop, 0));
        grid-template-columns: var(
            --grid-template-columns-desktop,
            repeat(var(--grid-columns, var(--grid-columns-desktop, 1)), minmax(0, 1fr))
        );
    }

    .grid-column {
        grid-column: var(
            --grid-column,
            var(--grid-column-desktop, span var(--grid-columns, var(--grid-columns-desktop, 1)))
        );
    }

    .grid-row {
        grid-row: var(--grid-row, var(--grid-column-desktop, 1));
    }
}

.flex-grow {
    flex-grow: var(--flex-grow, 1);
}

.flex-shrink {
    flex-shrink: var(--flex-shrink, 0);
}

.flush-top {
    margin-top: calc(-1 * var(--header-height));
}

.font--bold {
    font-weight: 700;
}

.font--book {
    font-weight: 300;
}

.font--italic {
    font-style: italic;
}

.font--medium {
    font-family: var(--font-family-medium);
    font-weight: 500;
}

.font--regular {
    font-weight: 400;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

@media (max-width: 1023px) {
    .flush-top-mobile {
        margin-top: calc(-1 * var(--header-height));
    }
}

.height {
    height: var(--height, var(--height-mobile, auto));
}

.lazy[data-src]:not([src]),
.lazy[data-srcset]:not([srcset]) {
    visibility: hidden;
}

.lazy {
    opacity: 0;
    transition: var(--transition, opacity 500ms ease-in-out);
}

// .lazy.loaded[data-src][src],
// .lazy.loaded[data-srcset][srcset] {
//   opacity: var(--opacity-loaded, 1);
// }

.lazy.loaded {
    opacity: var(--opacity-loaded, 1);
}

.margin {
    margin: var(--margin, var(--margin-mobile, 0));
}

.margin-horizontal {
    margin-left: var(--margin-horizontal-left-mobile, var(--margin-horizontal-left, 0));
    margin-right: var(--margin-horizontal-right-mobile, var(--margin-horizontal-right, 0));
}

.margin-vertical {
    margin-bottom: var(--margin-vertical-bottom-mobile, var(--margin-vertical-bottom, 0));
    margin-top: var(--margin-vertical-top-mobile, var(--margin-vertical-top, 0));
}

.max-height {
    max-height: var(--max-height, var(--max-height-mobile, 100%));
}

.max-width {
    max-width: var(--max-width, var(--max-width-mobile, 100%));
}

.min-height {
    min-height: var(--min-height, var(--min-height-mobile, auto));
}

.min-width {
    min-width: var(--min-width, var(--min-width-mobile, auto));
}

.no-underline {
    text-decoration: none;
}

.no-underline:hover {
    text-decoration: none;
}

.underline {
    text-decoration: underline;
}

.underline:hover {
    text-decoration: underline;
}

.object-fit {
    height: var(--object-fit-height, var(--object-fit-height-mobile, 100%));
    left: var(--object-fit-left, var(--object-fit-left-mobile, 0));
    max-width: var(--object-fit-max-width, var(--object-fit-max-width-mobile, none));
    mix-blend-mode: var(--object-fit-mix-blend-mode, var(--object-fit-mix-blend-mode-mobile, normal));
    object-fit: var(--object-fit, var(--object-fit-mobile, cover));
    object-position: var(--object-fit-object-position, var(--object-fit-object-position-mobile, center));
    position: var(--object-fit-position, var(--object-fit-position-mobile, absolute));
    top: var(--object-fit-top, var(--object-fit-top-mobile, 0));
    transform: var(--object-fit-transform, var(--object-fit-transform-mobile, none));
    transform-origin: var(--object-fit-transform-origin, var(--object-fit-transform-origin-mobile, center));
    width: var(--object-fit-width, var(--object-fit-width-mobile, 100%));
}

.padding {
    padding: var(--padding, var(--padding-mobile, 0));
}

.padding-horizontal {
    padding-left: var(--padding-horizontal-left, var(--padding-horizontal-left-mobile, 0));
    padding-right: var(--padding-horizontal-right, var(--padding-horizontal-right-mobile, 0));
}

.padding-vertical {
    padding-bottom: var(--padding-vertical-bottom, var(--padding-vertical-bottom-mobile, 0));
    padding-top: var(--padding-vertical-top, var(--padding-vertical-top-mobile, 0));
}

.position {
    bottom: var(--position-bottom, var(--position-bottom-mobile, auto));
    left: var(--position-left, var(--position-left-mobile, auto));
    position: var(--position, var(--position-mobile, absolute));
    right: var(--position-right, var(--position-right-mobile, auto));
    top: var(--position-top, var(--position-top-mobile, auto));
    transform: var(--position-transform, var(--position-transform-mobile, none));
}

@media (min-width: 768px) {
    .margin {
        margin: var(--margin, var(--margin-tablet, var(--margin-mobile, 0)));
    }

    .margin-horizontal {
        margin-left: var(
            --margin-horizontal-left,
            var(--margin-horizontal-left-tablet, var(--margin-horizontal-left-mobile, 0))
        );
        margin-right: var(
            --margin-horizontal-right,
            var(--margin-horizontal-right-tablet, var(--margin-horizontal-right-mobile, 0))
        );
    }

    .margin-vertical {
        margin-bottom: var(
            --margin-vertical-bottom,
            var(--margin-vertical-bottom-tablet, var(--margin-vertical-bottom-mobile, 0))
        );
        margin-top: var(--margin-vertical-top, var(--margin-vertical-top-tablet, var(--margin-vertical-top-mobile, 0)));
    }

    .padding {
        padding: var(--padding, var(--padding-tablet, var(--padding-mobile, 0)));
    }

    .padding-horizontal {
        padding-left: var(
            --padding-horizontal-left,
            var(--padding-horizontal-left-tablet, var(--padding-horizontal-left-mobile, 0))
        );
        padding-right: var(
            --padding-horizontal-right,
            var(--padding-horizontal-right-tablet, var(--padding-horizontal-right-mobile, 0))
        );
    }

    .padding-vertical {
        padding-bottom: var(
            --padding-vertical-bottom,
            var(--padding-vertical-bottom-tablet, var(--padding-vertical-bottom-mobile, 0))
        );
        padding-top: var(
            --padding-vertical-top,
            var(--padding-vertical-top-tablet, var(--padding-vertical-top-mobile, 0))
        );
    }
}

@media (min-width: 1024px) {
    .cursor {
        cursor: var(--cursor, var(--cursor-desktop, pointer));
    }

    .margin {
        margin: var(--margin, var(--margin-desktop, var(--margin-tablet, 0)));
    }

    .margin-horizontal {
        margin-left: var(--margin-horizontal-left, var(--margin-horizontal-left-desktop, 0));
        margin-right: var(--margin-horizontal-right, var(--margin-horizontal-right-desktop, 0));
    }

    .margin-vertical {
        margin-bottom: var(--margin-vertical-bottom, var(--margin-vertical-bottom-desktop, 0));
        margin-top: var(--margin-vertical-top, var(--margin-vertical-top-desktop, 0));
    }

    .max-height {
        max-height: var(--max-height, var(--max-height-desktop, 100%));
    }

    .max-width {
        max-width: var(--max-width, var(--max-width-desktop, 100%));
    }

    .min-height {
        min-height: var(--min-height, var(--min-height-desktop, auto));
    }

    .min-width {
        min-width: var(--min-width, var(--min-width-desktop, auto));
    }

    .object-fit {
        height: var(--object-fit-height, var(--object-fit-height-desktop, 100%));
        left: var(--object-fit-left, var(--object-fit-left-desktop, 0));
        max-width: var(--object-fit-max-width, var(--object-fit-max-width-desktop, none));
        mix-blend-mode: var(--object-fit-mix-blend-mode, var(--object-fit-mix-blend-mode-desktop, normal));
        object-fit: var(--object-fit, var(--object-fit-desktop, cover));
        object-position: var(--object-fit-object-position, var(--object-fit-object-position-desktop, center));
        position: var(--object-fit-position, var(--object-fit-position-desktop, absolute));
        top: var(--object-fit-top, var(--object-fit-top-desktop, 0));
        transform: var(--object-fit-transform, var(--object-fit-transform-desktop, none));
        transform-origin: var(--object-fit-transform-origin, var(--object-fit-transform-origin-desktop, center));
        width: var(--object-fit-width, var(--object-fit-width-desktop, 100%));
    }

    .padding {
        padding: var(--padding, var(--padding-desktop, var(--padding-tablet, 0)));
    }

    .padding-horizontal {
        padding-left: var(--padding-horizontal-left, var(--padding-horizontal-left-desktop, 0));
        padding-right: var(--padding-horizontal-right, var(--padding-horizontal-right-desktop, 0));
    }

    .padding-vertical {
        padding-bottom: var(--padding-vertical-bottom, var(--padding-vertical-bottom-desktop, 0));
        padding-top: var(--padding-vertical-top, var(--padding-vertical-top-desktop, 0));
    }

    .position {
        bottom: var(--position-bottom, var(--position-bottom-desktop, auto));
        left: var(--position-left, var(--position-left-desktop, auto));
        position: var(--position, var(--position-desktop, absolute));
        right: var(--position-right, var(--position-right-desktop, auto));
        top: var(--position-top, var(--position-top-desktop, auto));
        transform: var(--position-transform, var(--position-transform-desktop, none));
    }
}

.plus-icon:after,
.plus-icon:before {
    background-color: var(--plus-icon-color, var(--black));
    content: '';
    position: absolute;
    right: var(--plus-icon-right, 0);
    top: var(--plus-icon-top, 25px);
    transition: var(--plus-icon-transition, 200ms ease-in-out);
}

.plus-icon:after {
    height: var(--plus-icon-thickness, 1px);
    transform: translateY(9px);
    width: var(--plus-icon-length, 19px);
}

.plus-icon:before {
    height: var(--plus-icon-length, 19px);
    transform: rotate(-180deg) translateX(9px);
    width: var(--plus-icon-thickness, 1px);
}

.pointer-events-none {
    pointer-events: none;
}

.relative {
    position: relative;
}

.rte a:not(.button) {
    color: inherit;
}

.rte p {
    margin-bottom: 0;
}

.section-margin {
    margin-bottom: var(--section-margin-bottom, 40px);
    margin-top: var(--section-margin-top, 40px);
}

@media (min-width: 1024px) {
    .section-margin {
        margin-bottom: var(--section-margin-bottom-desktop, 60px);
        margin-top: var(--section-margin-top-desktop, 60px);
    }
}

.section-padding {
    padding-bottom: var(--section-padding-bottom, 40px);
    padding-top: var(--section-padding-top, 40px);
}

.section-padding--double {
    --section-padding-bottom: 80px;
    --section-padding-top: 80px;
    --section-padding-bottom-desktop: 120px;
    --section-padding-top-desktop: 120px;
}

.text-align {
    text-align: var(--text-align, var(--text-align-mobile, inherit));
}

.text-center {
    text-align: center;
}

@media (min-width: 1024px) {
    .section-padding {
        padding-bottom: var(--section-padding-bottom-desktop, 60px);
        padding-top: var(--section-padding-top-desktop, 60px);
    }

    .text-align {
        text-align: var(--text-align, var(--text-align-desktop, inherit));
    }
}

.text-align--center {
    text-align: center;
}

.text-align--left {
    text-align: left;
}

.text-align--right {
    text-align: right;
}

.title {
    color: var(--color, var(--gray-mid));
    font-size: var(--title-font-size, 40px);
    font-weight: $font-atten-medium;
    font-family: var(--font-family, var(--font-family-medium));
    letter-spacing: var(--title-letter-spacing, -0.05em);
    line-height: var(--title-line-height, 1);
}

.title__highlight {
    color: var(--highlight-color);
}

@media (max-width: 1023px) {
    .title__highlight-mobile {
        color: var(--highlight-color);
    }

    .mobile-block {
        display: block;
    }
}

.uppercase {
    text-transform: uppercase;
}

.viewport-height {
    height: 100vh;
}

.viewport-width {
    left: 50%;
    position: var(--viewport-position, var(--viewport-position-mobile, relative));
    transform: translateX(-50%);
    width: var(--viewport-width, var(--viewport-width-mobile, 100vw));
}

.width {
    width: var(--width, var(--width-mobile, auto));
}

.wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: var(--wrap-padding-h-mobile, var(--wrap-padding-h, 32px));
    padding-right: var(--wrap-padding-h-mobile, var(--wrap-padding-h, 32px));
    width: 100%;
}

.z-index {
    z-index: var(--z-index, var(--z-index-mobile, 1));
}

@media (min-width: 768px) {
    .viewport-width {
        position: var(--viewport-position, var(--viewport-position-tablet, relative));
        width: var(--viewport-width, var(--viewport-width-tablet, 100vw));
        width: var(--viewport-width, var(--viewport-width-tablet, var(--viewport-width-mobile, 100vw)));
    }

    .wrap {
        padding-left: var(--wrap-padding-h-tablet, var(--wrap-padding-h, 32px));
        padding-right: var(--wrap-padding-h-tablet, var(--wrap-padding-h, 32px));
    }
}

@media (min-width: 1024px) {
    .height {
        height: var(--height, var(--height-desktop, auto));
    }

    .viewport-width {
        position: var(--viewport-position, var(--viewport-position-desktop, relative));
        width: var(--viewport-width, var(--viewport-width-desktop, 100vw));
    }

    .width {
        width: var(--width, var(--width-desktop, auto));
    }

    .z-index {
        z-index: var(--z-index, var(--z-index-desktop, 1));
    }

    .wrap {
        padding-left: var(--wrap-padding-h-desktop, var(--wrap-padding-h, 32px));
        padding-right: var(--wrap-padding-h-desktop, var(--wrap-padding-h, 32px));
    }
}

/* SECTION HEADER */
.section-header__title {
    --color: var(--gray-mid);
}

/* DISPLAY UTILITY CLASSES */
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: var(--visual-position, absolute) !important;
    width: 1px;
}

.hide {
    display: none;
}

@media (min-width: 1024px) {
    .hide-desktop {
        display: none;
    }
}

@media (min-width: 1500px) {
    .hide-desktop-medium {
        display: none;
    }
}

@media (max-width: 1499px) {
    .show-desktop-medium {
        display: none;
    }
}

@media (max-width: 1023px) {
    .hide-mobile {
        display: none;
    }
}

.sectiontitle-headline {
    color: var(--gray-mid);
    font-size: 40px;
    font-weight: $font-atten-medium;
    letter-spacing: -0.05em;
    line-height: 1;
    padding-bottom: 2%;
    word-break: break-word;

    @media (min-width: 768px) {
        font-size: 50px;
    }

    @media (min-width: 1024px) {
        font-size: 60px;
    }

    @media (min-width: 1601px) {
        font-size: 80px;
    }

    .green-color {
        color: var(--highlight-color);
    }
}

.sectiontitle-headline--small {
    font-size: 32px;

    @media (min-width: 1024px) {
        font-size: 48px;
    }
}

.section-header__title {
    font-size: 40px;

    @media (min-width: 768px) {
        font-size: 50px;
    }

    @media (min-width: 1024px) {
        font-size: 60px;
    }

    @media (min-width: 1601px) {
        font-size: 80px;
    }
}

.section-header__title--small {
    font-size: 32px;

    @media (min-width: 1024px) {
        font-size: 48px;
    }
}

.global-components:not(.section-margin-bottom) + .section-margin,
.section-margin + .global-components .section-margin:first-child {
    margin-top: 0;
}
