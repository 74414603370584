@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.build_locations {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include min-width($desktop) {
        flex-direction: row;
    }

    .image {
        display: none;

        @include min-width($desktop) {
            align-self: center;
            display: block;
            margin-top: 0;
            margin-bottom: 0;
            width: 36%;
        }
    }

    .content {
        width: 100%;
        padding: 40px var(--wrap-padding-h-mobile, var(--wrap-padding-h, 32px)) 10px;

        @include min-width($desktop) {
            padding: 80px min(5.52vw, 106px);
            margin-left: 0;
            margin-right: auto;
            max-width: 900px;
            width: 64%;
        }

        .eyebrow {
            margin-bottom: var(--section-header-eyebrow-margin-bottom, 16px);
        }

        .title {
            color: var(--gray-mid);
            margin-bottom: 32px;
        }

        .state-list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;

            @include min-width($desktop) {
                align-items: flex-start;
            }

            ul a {
                color: $black-new;

                &:hover {
                    text-decoration: none;
                    border-bottom: 1px solid #BED344;
                }
            }
        }
    }

    .media-lockup__title.section-header__title span:nth-of-type(2) {
        color: var(--highlight-color);
    }

    @media (min-width: 1024px) {
        .media-lockup {
            --media-lockup-title-font-size: 48px;
            --media-lockup-subtitle-font-size: 20px;
        }
    }

    @media (min-width: 1024px) and (max-width: 1299px) {
        .media-lockup__lockup .button {
            margin-top: 35px;
        }
    }
}