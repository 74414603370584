@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.banner_section {
    .bannerSection {
        .bannerSectionWrapper {
            min-height: 100vh;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            overflow: hidden;
            margin-bottom: 80px;
            .bannerContent {
                position: relative;
                width: 100%;
                padding-bottom: 20%;
                align-items: flex-end;
                &:after {
                    content: ' ';
                    position: absolute;
                    background: #fff;
                    width: 5000px;
                    height: 5000px;
                    transform: rotate(26.6deg) translate(27%, 72%);
                    bottom: 0;
                    right: 0;
                }
                .bannerContentWrapper {
                    width: 66%; // margin: 0 auto;
                    padding: 0% 5% 0%;
                    position: relative;
                    z-index: 3;
                    .bannerHeader {
                        .heading-section {
                            text-align: left;
                            margin-left: 0;
                            width: 70%;
                            .main-title {
                                padding-bottom: 32px;
                                padding: 10px 0;
                                >span {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
            .add_overlay_home{
                background: transparent linear-gradient(119deg, #00000000 0%, #00000045 100%);
            }
        }
        .video-box {
            width: 100%;
            position: absolute;
            background-size: cover;
            right: 0;
            bottom: 0;
            height: 100%;
            object-fit: cover;
        }
    }
    #heading2{
        color: $new-grey-color;
        sup{
            font-size: 17px;
            top: -2rem !important;
            font-weight: bolder;
            letter-spacing: 0.1px;
        }
    }
    @include max-width($desktop-xl) {
        #heading2{
        sup{
            font-size: 14px;
            top: -1.2rem !important;
        }
    }
    }
    @include max-width($desktop-large) {
        #heading2{
        sup{
            font-size: 10px;
            top: -1rem !important;
        }
    }
    }
    @include max-width($tablet-landscape) {
        .bannerSection {
            .bannerSectionWrapper {
                .bannerContent {
                    &:after {
                        transform: rotate(26.6deg) translate(27%, 70.5%);
                    }
                }
            }
        }
    }
    @include max-width($iphonex-landscape) {
        .bannerSection {
            .bannerSectionWrapper {
                .bannerContent {
                    .bannerContentWrapper {
                        width: 100%;
                    }
                    &:after {
                        transform: rotate(26.6deg) translate(27%, 69%);
                    }
                }
            }
            .video-box {
                height: 100vh;
            }
        }
    }
    @include max-width(812px) {
        .bannerSection {
            .bannerSectionWrapper {
                .bannerContent {
                    &:after {
                        transform: rotate(26.6deg) translate(27%, 71%);
                    }
                }
            }
            .video-box {
                &.banner_video {
                    object-fit: cover;
                }
            }
        }
    }
    @include max-width($tablet) {
        .bannerSection {
            .bannerSectionWrapper {
                .bannerContent {
                    &:after {
                        transform: rotate(26.6deg) translate(27%, 69%);
                    }
                    .bannerContentWrapper {
                        width: 100%;
                    }
                }
            }
        }
    }
    @include max-width($iphone6-plus-landscape) {
        .bannerSection {
            .bannerSectionWrapper {
                padding-bottom: 20%;
                .bannerContent {
                    .bannerContentWrapper {
                        .bannerHeader {
                            .heading-section {
                                .main-title {
                                    span {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .bannerSection {
            .bannerSectionWrapper {
                .bannerContent {
                    padding-bottom: 50%;
                    &:after {
                        transform: rotate(26.6deg) translate(38%, 67.5%);
                    }
                    .bannerContentWrapper {
                        padding: 0 10%;
                        .bannerHeader {
                            .heading-section {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width:991px) and (orientation: landscape) {
         .bannerSection {
             .bannerSectionWrapper {
                 .bannerContent {
                     &:after {
                           transform: rotate(29.4deg) translate(27%,69%);
                     }
                 }
             }
         }
     }
    @media (max-width:812px) and (orientation: landscape) {
        .bannerSection {
            .bannerSectionWrapper {
                .bannerContent {
                    .bannerContentWrapper {
                        .bannerHeader {
                            .heading-section {
                                width: 40%;
                            }
                        }
                    }
                    &:after {
                        transform: rotate(26.6deg) translate(27%, 74.4%);
                    }
                }
            }
        }
    }
    @media (max-width:736px) and (orientation: landscape) {
        .bannerSection {
            .bannerSectionWrapper {
                padding-bottom: 0;
                .bannerContent {
                    .bannerContentWrapper {
                        .bannerHeader {
                            .heading-section {
                                width: 40%;
                            }
                        }
                    }
                    &:after {
                        transform: rotate(26.6deg) translate(27%, 70.5%);
                    }
                }
            }
        }
    }
    @media (max-width:667px) and (orientation: landscape) {
        .bannerSection {
            .bannerSectionWrapper {
                .bannerContent {
                    &:after {
                        transform: rotate(26.6deg) translate(27%, 70.5%)
                    }
                }
            }
        }
    }
    @media (max-width:568px) and (orientation: landscape) {
        .bannerSection {
            .bannerSectionWrapper {
                .bannerContent {
                    .bannerContentWrapper {
                        padding-bottom: 0;
                    }
                    &:after {
                        transform: rotate(26.6deg) translate(27%, 69.5%);
                    }
                }
            }
        }
    }
}

.bannerBtnWrapper {
    .headerForm {
        position: relative;
        width: 100%;
        margin-bottom: 80px;
        color: $black-new;
        transition: all 0.3s ease; // transition: opacity 0.5s ease-in-out 0s;
        .formgroup {
            position: relative;
        }
        .hoverPopup {
            display: none;
            position: absolute;
            top: 100%;
            width: 100%;
            left: 0;
            z-index: 1;
            background: #fff; // opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease; // transition: opacity 0.5s ease-in-out 0s;
            /*box-shadow: 3px 3px 10px 0 #dfdfdf;*/
            .optionWrapper {
                padding: 0%;
                padding-top: 80px;
                display: none;
                .optionHead {
                    position: relative;
                    align-items: center;
                    font-size: 12px;
                    letter-spacing: 1.2px;
                    color: $black-new;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                    font-weight: $font-atten-bold;
                    >span {
                        padding-right: 0.2em;
                    }
                    &:after {
                        content: " ";
                        background: $lightborder;
                        height: 2px;
                        flex-grow: 1;
                        width: auto;
                        position: relative;
                        margin-left: 10px;
                        opacity: 0.5;
                    }
                }
                .communitiesNearYou {
                    flex-wrap: wrap;
                    margin-bottom: 0px;
                    li {
                        width: 23%;
                        margin-right: 2.66%;
                        margin-bottom: 20px;
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
                        .communityCard {
                            flex-direction: column;
                            text-decoration: none;
                            .imgArea {
                                width: 100%;
                                height: 0;
                                overflow: hidden;
                                padding-bottom: 60%;
                                position: relative;
                                margin-bottom: 5px;
                                >img {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                }
                            }
                            p {
                                margin-bottom: 0;
                                color: $black-new;
                                font-size: 18px;
                                letter-spacing: -0.5px;
                                line-height: 40px;
                                font-weight: $font-atten-medium;
                            }
                        }
                    }
                }
                .whereWeBuild {
                    width: 75%;
                    flex-wrap: wrap;
                    li {
                        width: 23%;
                        margin-right: 2.66%;
                        margin-bottom: 5px;
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
                        .weBuildLink {
                            align-items: center;
                            color: $black-new;
                            text-decoration: none;
                            font-size: 20px;
                            letter-spacing: -1px;
                            line-height: 36px;
                            &:before {
                                content: ' ';
                                margin-right: 10px;
                                display: inline-block;
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 4px 0 4px 8px;
                                border-color: transparent transparent transparent $green;
                                margin-top: 0.1em;
                            }
                        }
                    }
                }
            }
            .searchResultsOptions {
                box-shadow: 3px 3px 10px 0 $lightgray;
                .mainContent {
                    max-height: 400px;
                    overflow: auto;
                    width: 100%;
                    &::-webkit-scrollbar {
                        background-color: $white;
                        width: 16px
                    }
                    &::-webkit-scrollbar-track {
                        background-color: $white;
                    }
                    &::-webkit-scrollbar-track:hover {
                        background-color: $white
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: $new-border-color;
                        border-radius: 16px;
                        border: 5px solid $white
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background-color: $new-border-color;
                    }
                    &::-webkit-scrollbar-button {
                        display: none;
                    }
                    ul {
                        padding: 4%;
                        border-bottom: 1px solid $bglightcream;
                        margin-bottom: 0;
                        &:last-child {
                            border-bottom: 0;
                        }
                        .headItem {
                            font-weight: $font-atten-bold;
                            margin-bottom: 10px; // font-size: 20px;
                            // letter-spacing: -0.5px;
                            // line-height: 35px;
                        }
                        .item {
                            margin-bottom: 5px; // font-size: 18px;
                            // letter-spacing: -0.45px;
                            // line-height: 28px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            label {
                                position: relative;
                                padding-left: 2em;
                                margin: 0;
                                user-select: none;
                                cursor: pointer;
                                p {
                                    margin: 0;
                                }
                                input {
                                    display: none;
                                }
                                .checkmark {
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    height: 1.4em;
                                    width: 1.4em;
                                    border: 1px solid #D2D0D0;
                                }
                                input:checked~.checkmark {
                                    border: 1px solid $green;
                                    background: $green;
                                }
                                input:checked~.checkmark::after {
                                    content: ' ';
                                    position: absolute;
                                    width: 40%;
                                    height: 70%;
                                    border-bottom: 2px solid #fff;
                                    border-right: 2px solid #fff;
                                    transform: translate(70%, 10%) rotate(45deg);
                                    top: 0;
                                    left: 0;
                                }
                            }
                            .itemLink {
                                color: $black-new;
                            }
                        }
                    }
                }
                .resultFooterWrapper {
                    padding: 2%; 
                    border-top: 1px solid $bglightcream;
                    .footerWrapper {
                        justify-content: space-between;
                        align-items: center;
                        .clearBtn {
                            color: $black-new;
                            font-weight: $font-atten-bold;
                            font-size: 14px;
                            text-decoration: underline;
                            cursor: pointer;
                            padding-left: 2%;
                        }
                        .DoneBtn {
                            background: $green;
                            color: $black-new;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            letter-spacing: 2.6px;
                            text-transform: capitalize;
                            font-size: 14px;
                            line-height: 16px;
                            font-weight: $font-atten-bold;
                            width: 100px;
                            height: 40px;
                            text-decoration: none;
                            cursor: pointer;
                            margin: 2%;
                        }
                    }
                }
            }
        }
        &.active {
            .hoverPopup {
                display: block;
                pointer-events: all;
            }
        }
        &.activehover {
            .hoverPopup {
                display: block;
                pointer-events: all;
                height: auto;
            }
        }
    }
    .no-result {
        padding: 5px;
    }
}

.banner_modal {
    .modal-body {
        .optionWrapper {
            display: none !important;
        }
    }
}

@include max-width($desktop-xl) {
    .bannerBtnWrapper {
        .headerForm {
            margin-bottom: 60px;
            .hoverPopup {
                .optionWrapper {
                    padding-top: 50px;
                    .communitiesNearYou {
                        li {
                            .communityCard {
                                p {
                                    font-size: 18px;
                                    letter-spacing: -0.5px;
                                    line-height: 40px;
                                }
                            }
                        }
                    }
                    .whereWeBuild {
                        li {
                            .weBuildLink {
                                font-size: 18px;
                                line-height: 26px;
                            }
                        }
                    }
                }
            }
        }
        .headermainContent {
            .leftCardsArea {
                .optionWrapper {
                    .communitiesNearYou {
                        li {
                            .communityCard {
                                p {
                                    font-size: 18px;
                                    letter-spacing: -0.5px;
                                    line-height: 40px;
                                }
                            }
                        }
                    }
                    .whereWeBuild {
                        li {
                            .weBuildLink {
                                font-size: 18px;
                                line-height: 26px;
                            }
                        }
                    }
                }
                .itemCard {
                    .itemhead {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}


@include max-width($tablet) {
    .bannerBtnWrapper {
        .headerForm {
            .hoverPopup {
                .optionWrapper {
                    .whereWeBuild {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include max-width($iphone6-plus-landscape) {
    .bannerBtnWrapper {
        .headerForm {
            width: 100%;
            margin-bottom: 40px;
            .current-location {
                a {
                    font-size: 16px;
                }
            }
            .hoverPopup {
                .searchResultsOptions {
                    .mainContent {
                        ul {
                            .headItem {
                                font-size: 20px;
                                letter-spacing: -0.5px;
                                line-height: 35px;
                            }
                            .item {
                                font-size: 18px;
                                letter-spacing: -0.45px;
                                line-height: 28px;
                            }
                        }
                    }
                }
                .optionWrapper {
                    display: none;
                    .communitiesNearYou {
                        li {
                            width: 100%;
                            margin-right: 0;
                        }
                    }
                    .whereWeBuild {
                        width: 100%;
                        li {
                            width: 100%;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .banner_modal {
        .modal-header {
            .close {
                background-color: $white !important;
                border: none !important;
                span {
                    font-size:40px !important;
                    color: #000 !important;
                }
            }
        }
        .modal-body {
            background-color: $white !important;
            .search_subtext {
                display: none !important;
            }
            .headerForm {
                margin-top: 10% !important;
                width: 100% !important;
                .current-location,
                .formgroup {
                    width: 80% !important;
                    margin: auto !important;
                }
            }
            .mainContent {
                width: 80% !important;
                margin: auto !important;
            }
            .list-unstyled {
                padding: 5% 2% !important;
            }
            .bannerBtnWrapper {
                position: unset !important;
                width: 100% !important;
                .active_shadow,
                .searchResultsOptions {
                    box-shadow: none !important;
                }
            }
            .optionWrapper {
                display: none !important;
            }
            ul#no_results_header {
                padding: 5px !important;
                margin: auto !important;
                width: 80% !important;
            }
            .resultFooterWrapper {
                .footerWrapper {
                    flex-direction: column !important;
                    .clearBtn {
                        width: 85% !important;
                        text-align: center;
                        text-transform: uppercase;
                        text-decoration: none !important;
                        background: $green;
                        letter-spacing: 2.6px;
                        line-height: 16px;
                        height: 40px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 15px !important;
                        margin-bottom: 0 !important;
                        padding-left: 0 !important;
                    }
                    .show_result_btn {
                        width: 85% !important;
                        margin: 15px !important;
                    }
                }
            }
        }
    }
}

