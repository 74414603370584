@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.sidebar-form{
    display: none;
    width: 360px;
    position: absolute;
    bottom: 163%;
    right: 6%;
    background: $white;
    text-align: center;
    box-shadow: 0px 4px 31px #00000071;
     .sidebar-close {
        font-weight: $font-atten-medium;
        color:#777777;
        text-align: right;
        margin-right: 15px;
        margin-top: 10px;
        font-size: 18px;
        cursor: pointer;
        font-weight: 600;
        line-height: normal;
    }
    .sidebar-agent-info{
        padding: 10%;
         padding-top: 2%;
        .sidebar-title{
            color:$green;
            font-size:13px !important;
            line-height: 25px;
            letter-spacing: 3.9px;
            font-weight: $font-atten-bold !important;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
        .agent-name{
            font-size: 15px;
            color:$black-new;
        }
        .small-gray-text{
            margin: 10px 0 5px;
            font-size: 11px;
            color:$lightgraytext;
        }
        .sidebar-btn {
            a{
                width: 100%;
                &.filled-btn{
                    @include green-filled-button;
                    padding: 10px 15px !important;
                    min-width: unset;
                }
                &.promotion-btn{
                    color: #545454;
                    box-shadow: none;
                    font-size: 13px!important;
                    letter-spacing: 3.9px;
                    font-weight: 700 !important;
                    text-transform: uppercase;
                    min-width: 260px;
                    border-radius: 0;
                    font-size: 20px;
                    padding: 18px 20px;
                    line-height: 25px;
                    text-decoration: none;
                    text-align: center;
                    display: inline-block;
                    padding: 10px 15px!important;
                    min-width: unset;
                }
                &.phone-num{
                    color:$black-new;
                    font-size:13px !important;
                    line-height: 25px;
                    letter-spacing: 3.9px;
                    font-weight: $font-atten-bold !important;
                }
                &:hover{
                    text-decoration: unset;
                }
            }
           
        }
        &:before{
            content:'';
            position: absolute;
            border-style: solid;
            border-color: $white transparent transparent transparent;
            border-width: 18px 25px 0;
            bottom: 0px;
            right: -25px;
            transform: rotate(180deg);
        }
    }
    
    @include max-width($tablet-landscape) {
        right: 10%;
        .sidebar-agent-info{
            padding: 5% 10%;
             padding-top: 2%;
        }
    }
    @include max-width($iphone6-plus-landscape) {
        .sidebar-agent-info{
            &:before{
                right: -16px;
                transform: rotate(90deg);
            }
        }
    }
    @include max-width($iphone4-landscape) {
        right: 50%;
        transform: translateX(50%);
        width: 90%;
    }
}
