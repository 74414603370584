@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.blog-and-news {
    background-color: $charcoal;
    padding: 80px 72px;

    & > div {
        border: 1px solid $steel;
        padding: 108px;
    }

    .blog-and-news__header {
        margin-bottom: 32px;
        width: 100%;

        .eyebrow {
            color: $white;
            font-size: var(--eyebrow-font-size,12px);
            font-weight: 500;
            line-height: 30px;
        }

        a, a:hover, a:visited {
            color: $white;
            border-bottom: 2px solid $apple;
            font-size: 24px;
            letter-spacing: -1.2px;
            line-height: 30px;
            text-decoration: none;
        }
    }

    .blog-and-news__posts {
        width: 100%;
    }

    .blog-and-news__footer {
        display: flex;
        justify-content: start;
    }

    @media (max-width: $tablet-landscape) {
        padding: 40px 12px;

        & > div {
            padding: 32px;
        }

        .blog-and-news__posts > div {
            margin-bottom: 32px;
        }
    }

    @media (max-width: $iphone6-landscape) {
        .blog-and-news__posts > div:last-child {
            margin-bottom: 24px;
        }

        .blog-and-news__footer {
            justify-content: center;
        }
    }
}