@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.journey {
  color: $black-new;
  gap: 0;
  display: flex;
  flex-direction: column;
  // padding-left: 20px;
  // padding-right: 20px;

  @media screen and (min-width: 1023px) {
    flex-direction: row;
    justify-content: space-between;
    min-height: 947px;
    gap: 64px;
  }

  @media screen and (min-width: 1440px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 64px;
  }

  @media screen and (min-width: 1550px) {
    gap: 128px;
  }
}

.journey-images {
  position: relative;
  height: 468px;
  margin: 24px auto;
  max-width: 345px;

  @media screen and (min-width: 1023px) {
    height: auto;
    margin: 0;
    max-width: 100%;
  }
}

.journey-image {
  position: absolute;
  width: 100%;
  height: 100%;
}

#journey-image-1, #journey-image-2 {
  overflow: hidden;
}

#journey-image-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 278px;
  height: 360px;

  @media screen and (min-width: 1023px) {
    width: 400px;
    height: 516px;
  }

  @media screen and (min-width: 1360px) {
    position: absolute;
    margin-bottom: 0;
    width: 579px;
    height: 748px; 
  }
}

#journey-image-2 {
  right: 0;
  left: auto;
  top: 250px;
  position: absolute;
  width: 137px;
  height: 185px;

  @media screen and (min-width: 1023px) {
    width: 233px;
    height: 313px;
    left: auto;
    top: 320px;
    right: 0
  }

  @media screen and (min-width: 1360px) {
    left: 350px;
    right: auto;
    top: 374px;
    width: 337px;
    height: 453px;
  }
}

.journey-left {
  order: 2;

  @media screen and (min-width: 1023px) {
    order: 1;
    flex: 1
  }
}

.journey-right {
  order: 1;
  position: relative;
  
  @media screen and (min-width: 1023px) {
    margin-top: 64px;
    margin-top: 0;
    min-width: 460px;
    order: 2;
  }

  @media screen and (min-width: 1100px) {
    margin-right: 88px;
  }
  
  @media screen and (min-width: 1360px) {
    margin-top: 64px;
    margin-top: 0;
    min-width: 600px;
    order: 2;
  }
}

.journey-section-header {
  display: none;
  @media screen and (min-width: 1023px) {
    display: block;
  }
}


.journey-step {
  overflow: hidden;
}

.journey-steps {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 24px;

  @media screen and (min-width: 1023px) {
    margin-top: 40px;
  }
}

.journey-step-heading {
  cursor: pointer;
  display: flex;
  line-height: 32px;
  margin-bottom: 16px;
}

.journey-step-number {
  background-color: $aloe;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  transition: background-color .5s ease;
}

.journey-step.active .journey-step-heading .journey-step-number {
  background-color: $apple;
}

.journey-step-content {
  display: grid;
  grid-template-rows: 0fr;
  margin: 0;
  transition: grid-template-rows .5s ease-in-out;

  &.desktop {
    padding-left: 42px;
  }

  &.active {
    grid-template-rows: 1fr;
    ;
  }

  &.active.desktop {
    padding-bottom: 32px;
  }

  p {
    margin: 0;
  }
}

.journey-step-title {
  display: inline-block;
  font-size: 24px;
  vertical-align: middle;

  @media screen and (min-width: 1023px) {
    margin-left: 8px;
  }
}

.journey-mobile-navigation {
  display: flex;
  justify-content: space-between;

  .journey-step-number {
    background-color: $apple;
    height: 24px;
    width: 24px;
  }
}

.journey-mobile-navigation-carousel {
  align-items: center;
  display: flex;
  gap: 16px;
}

.journey-mobile-navigation-button {
  background-color: $steel;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  width: 12px;

  &.active {
    background-color: $avacado;
  }
}