@import "../../assets/scss/partials/variables";

@media (min-width: 1024px) {
  .PlanCardItem__details {
    border-top: 1px solid #DEDEDE;
  }
}

.PlanCardItem{
  figure{
    .card-snipe{
      position: absolute;
      z-index: 2;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 8px;
      background-color: $green;
      color: $white;
      font-size: 12px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}