.videoArrow {
  background: white;
  border-radius: 50%;
  height: 88px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 88px;
}

.videoArrow:after {
  border-color: transparent transparent transparent #000000;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0;
}

@media (max-width: 1023px) {
  .videoArrow {
    height: 60px;
    width: 60px;
  }

  .videoArrow:after {
    border-width: 8px 0 8px 10px;
  }
}