.promo-qmi-card__image {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;

    img {
        aspect-ratio: 3 / 2;
        transition: scale 0.2s ease;
    }

    &:hover {
        img {
            scale: 1.05;
        }
    }
}

.promo-qmi-card__status {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    background-color: #fff;
    color: #676767;
    padding: 0.56rem 0.5rem 0.5rem 0.5rem;
    line-height: 1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}

.promo-qmi-card__snipe {
    width: 100%;
    padding: 0.5rem;
    color: #fff;
    font-weight: bold;
    font-size: 0.6875rem;
    text-align: center;
}

.promo-qmi-card__title {
    font-size: 1.1875rem;
    color: #000;
    margin: 0.75rem 0 0.75rem 0;
}

.promo-qmi-card__location {
    text-transform: uppercase;
    font-size: 0.6875rem;
    color: #000;
    margin: 0 0 0.75rem 0;
}

.promo-qmi-card__price {
    font-size: 1.1875rem;
    color: #000;
    font-size: 0.8125rem;
    font-weight: 500;
    margin: 0 0 0.75rem 0;
}

.promo-qmi-card__stats {
    display: flex;
    justify-content: flex-start;
    padding: 1rem 0;
    border-top: 1px solid #dedede;
    margin-bottom: 0.75rem;
}

.promo-qmi-card__stat {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    flex: 0 0 25%;
    flex-direction: column;
    gap: 0.5rem;
    color: #000;

    > span:first-child {
        font-size: 0.75rem;
        font-weight: 500;
    }

    > span:last-child {
        font-size: 0.6785rem;
        text-transform: uppercase;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        border-right: 1px solid #dedede;
    }
}

.promo-qmi-card__button {
    width: 100%;
    border: 1px solid #dedede;
    padding: 0.75rem;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    text-decoration: none;
    font-size: 0.6785rem;
    display: block;
    transition: all 0.35s ease;
    font-weight: 700;
    outline-color: var(--accent-color);

    &:hover {
        color: #fff;
        border-color: var(--accent-color);
        background-color: var(--accent-color);
        text-decoration: none;
    }
}
