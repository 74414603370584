@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";


#review_card{
    padding: 5px 20px;
    border: 1px solid #bababa;
    margin: 20px 40px;
    .review_content{
        padding: 15px 10px 0;
        display: flex;
        justify-content: space-between;
        .review_comment{
            max-width: 85%;
            font-size: 18px;
            @include max-width($desktop-large){
                max-width: 82%;
            }
            @include max-width($macbook){
                max-width: 82%;
            }
            @include max-width($desktop) {
                max-width: 80%;
            }
            @include max-width($tablet-landscape) {
                max-width: 70%;
            }
            @include max-width($tablet) {
                max-width: 68%;
            }
            @media (max-width:896px) and (orientation: landscape) {
                max-width: 68%;
            }
            @media (max-width:786px) and (orientation: landscape) {
                max-width: 64%;
            }
            @media (max-width:667px) and (orientation: landscape) {
                max-width: 65%;
            }
        }
        @include max-width($iphone6-landscape) {
            flex-direction: column;
            .review_comment{
                order: 2;
                max-width: 100%;
            }
        }
        
        .review_star_rating{
            display: flex;
            @include max-width($iphone6-landscape){
                order: 1;
                margin-bottom: 20px;
            }
            .star_rating{
                font-size: 18px;
                margin-right: 10px;
                color: $green;
                span{
                    margin-right: 5px;
                }
            }
            .review_rating{
                margin-top: 4px;
                font-size: 17px;
            }
        }
    }
    .review_date{
        max-width: 20%;
        padding: 0 10px 13px;
        font-size: 18px;
        @include max-width($iphone6-landscape){
            max-width: 100%;
        }
    }
}

