$black-new : #545454;
$charcoal: $black-new;
$white : #ffffff;
$darkgray : #a3a3a2;
$beige : #F5F5DC;
$graytext :#747474;
$lightgraytext :#868686;
$lightgray: #dfdfdf;
$green:#abad23;
$aloe: #ebf5c2;
$bglightcream:#F7F4EF;
$bglightgray:#fafafa;
$bgdarkgray:#EDEBE7;
$bdlightblack:#313131;
$purple : #3E3B4A;
$darkpurple : #25394b;
$gray:#95897A;
$pink:#C93051;
$bordergray:#707070;
$violet:#3c3b48;
$darkviolet:#33323C;
$wildsand:#EAE7E0;
$bgblue:#325b81;
$bgfootergray:#ebe9e3;
$bgfooterdarkgray:#efece7;
$overlayblack:#2B29259D;
$whitegrey:#F6F5F0;
$bordergrey:#D9D9D9;
$lightbrown:#C46E1A;
$textgrey:#9E9FA0;
$lightborder:#d8d3cd;
$green:#BED245;
$apple: #BED334;
$avacado: #7F9504;
$olive:#6A7C04;
$gray-new:var(--gray-mid);
$bggray-new:#F7F7F7;
$new-border-color:#D2D0D0;
$new-grey-color : #848484;
$steel: #C8C8C8;
// defaults
$link-primary: $black-new;
$link-secondary: $green;

// Media queries
$iphone5: 320px;
$GalaxyS8:360px;
$iphone6: 375px;
$iphone6-plus: 414px;
$iphone4-landscape: 480px;
$iphone6-landscape: 667px;
$iphone6-plus-landscape: 767px;
$iphonex-landscape:991px;
$tablet: 768px;
$tablet-landscape: 1024px;
$not-tablet: $tablet-landscape + 1;
$desktop: 1200px;
$macbook:1280px;
$desktop-large: 1440px;
$desktop-xl: 1600px;

