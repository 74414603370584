@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

div.tpc_form{
    background: #fff !important;
}
.fancybox-slide--html {
    padding: 0 !important;
    .tpc_form.fancybox-content {
        .fancybox-close-small {
            color: $white !important;
            background: unset !important;
            width: 35px!important;
            height: 35px!important;
            right: 25px!important;
            top: 25px!important;
            border-radius: 50%!important;
            border: 1px solid $white;
            padding: 8px !important;
            opacity: 1;
            @include max-width($iphonex-landscape) {
                color: #545454 !important;
                border: 1px solid #545454;
            }
        }
    }
}

.priority_group_text {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 5%;
    .priority_group_heading {
        font-size: 70px;
        color: $white;
        line-height: 70px;
        letter-spacing: -3.5px;
        font-weight: $font-atten-medium;
    }
    .priority_group_description {
        color: $white;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.5px;
        font-weight: $font-atten-book;
    }
    @include max-width($desktop-large) {
        .priority_group_heading {
            font-size: 45px;
            letter-spacing: -1.5px;
        }
    }
}

