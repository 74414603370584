// ----- Fonts ----- //

$font-size: 15px;

$font-sans-serif: Arial, sans-serif;
$font-serif: "Times New Roman", Times, serif;

// $font-primary: "Atten Book", $font-serif;
// $font-secondary: "Atten Medium", $font-sans-serif;
// $atten-heavy: "Atten Heavy", $font-sans-serif;


$font-atten-book: 300;
$font-atten-medium: 500;
$font-atten-bold: 700;

$general-font: "new-atten" , $font-sans-serif;
