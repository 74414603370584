@media (min-width: 1024px) {
  .avatar-card {
    --details-contact-padding: 15px 150px 15px 32px;
    --details-contacts-order: 1;
    --details-contacts-width: clamp(200px, 21.875%, 420px);
    --details-description-max-width: 95ch;
    --details-flex-direction: row;
    --details-info-order: 2;
    --details-info-padding: var(--section-padding-v) 0 0;
    --details-info-title-bottom: 27px;
    --details-justify-content: space-between;
    --details-message-border-bottom: 1px solid #242424;
    --details-message-font-size: 20px;
    --details-message-margin-top: 38px;
    --details-message-p-display: inline;
    --details-meta-border-width: 0;
    --details-meta-display: inline-flex;
    --details-meta-flex-wrap: nowrap;
    --details-meta-item-border-right: 1px solid var(--border-color-light);
    --details-meta-item-padding: 24px 30px;
    --details-meta-item-width: auto;
    --details-meta-order: 0;
    --details-meta-padding: 0;
    --details-meta-width: auto;
    --details-padding-bottom: var(--section-padding-v);
    --details-padding-top: 27px;
    --details-sticky-right: var(--wrap-padding-h);
  }
}

@media (min-width: 1400px) {
  .avatar-card {
    --details-contact-border-left: 1px solid var(--border-color-light);
    --details-meta-item-padding: 24px 40px;
  }
}