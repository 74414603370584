// .top-scroll {
//   visibility: hidden;
// }

@media (max-width: 374px),
  (max-height: 599px) {
  .hero__back-btn {
    display: none;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .hero__back-btn {
    height: 40px;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 1023px) {
  .hero__back-btn:before {
    display: none;
  }
}

@media (max-height: 599px) {
  .hero {
    --hero-max-height-mobile: calc(100vh - 100px);
    --hero-min-height-mobile: calc(100vh - 100px);
    --section-header-eyebrow-margin-bottom: 5px;
  }

  .hero__back-btn {
    --flex-display-mobile: none;
  }
}

@media (min-width: 1024px) {
  .hero {
    --hero-opacity-loaded: 1;
  }
}