@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

#tour_form{
    display: none;
}

#priority_group_form{
    display: none;
}
div#priority_group_form{
    img{
        height: auto;
        max-width: 100%;
        width: auto;
    }
    height: 100%;
    width: 100%;
    margin: 0;
	padding: 0;
    background: #fbf9f6;
    // margin-right: -.2em;
    cursor: -webkit-default;
    cursor: default;
}

div#tour_form{
    img{
        height: auto;
        max-width: 100%;
        width: auto;
    }
    height: 100%;
    width: 100%;
    margin: 0;
	padding: 0;
    background: #fbf9f6;
    // margin-right: -.2em;
    cursor: -webkit-default;
    cursor: default;
}
div#tour_forms {
    img{
        height: auto;
        max-width: 100%;
        width: auto;
    }
    height: 100%;
    width: 100%;
    margin: 0;
	padding: 0;
    background: #fbf9f6;
    // margin-right: -.2em;
    cursor: -webkit-default;
    cursor: default;
}
.tourpop_form{
    display: flex;
    height:100%;
    .form-column{
        width:50%;
        #mktoForm_5543 .logo,#mktoForm_5576 .logo{
            img{
                max-width: 150px !important;
            }
        }
    }
    .form-image{
        display:flex;
		width:50%;
		position: relative;
        img{
            height: 100% !important;
            object-fit: cover;
            background-position: center;
			background-size: cover;
			outline: none;
        }
        .two_image{
            width: 5%;
        }
        picture{
            flex:1;
         }
    }
}

#tour_forms {
	#mktoForm_5543,#mktoForm_5576 {
		.Header {
			.headtxt {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
				padding-right: 10px !important;
				font-weight: 600;
			}
		}
		.heading {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			line-height: 1.2 !important;
		}
		.step {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			font-size: 14px !important;
		}
		.subhead {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
		}
		.mktoFormRow {
			.mktoField {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
			}
		}
		.nxtStep {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			font-size: 14px !important;
		}
		.bckstep {
			a {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
				font-size: 14px !important;
			}
		}
		.Row_35 {
			.subhead {
				font-size: 18px !important;
			}
		}
		.Row_36 {
			.headd {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
			}
			.date {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
				line-height: 30px;
			}
		}
		.bbtn {
			a {
				@include black-outline-button;
				letter-spacing: 2px !important;
				// color: $black-new !important;
				// font-weight: $font-atten-book !important;
				// border: 1px solid $black-new !important;
				// line-height: 20px !important;
				// font-size: 18px !important;
			}
		}
		.ms-choice {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			border: 1px solid $black-new !important;
			line-height: 20px !important;
		}
		.ms-drop {
			li {
				label {
					span {
						color: $black-new !important;
						font-weight: $font-atten-book !important;
					}
				}
			}
			input[type="checkbox"] {
				visibility: visible !important;
				opacity: 1 !important;
				left: 20px !important;
				top: -2px;
				padding: 0 !important;
			}
		}
		a#PrivacyLink {
			color: $green !important;
		}
		.arrow {
			&::before {
				background-color: $black-new !important;
			}
			&::after {
				border-color: $black-new !important;
			}
		}
		.red-error {
			color: red !important;
			font-size: 15px;
			padding-top: 5px !important;
			font-weight: $font-atten-book !important;
		}
	}
	.mktoForm#mktoForm_5543,.mktoForm#mktoForm_5576 {
		.mktoLabel {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			font-size: 14px !important;
			left: 12px !important;
		}
		.button {
			.skip {
				a {
					color: $black-new !important;
					font-weight: $font-atten-book !important;
					background-color: transparent !important;
					font-size: 14px !important;
					letter-spacing: unset !important;
					text-transform: unset !important;
					border: unset !important;
				}
			}
			a {
				@include green-filled-button;
				min-width: 110px !important;
				max-width: unset !important;
				width: unset !important;
				padding: 10px!important;
			}
		}
		.Field_1,.Field_3 {
			.remove_dots{
				 label:before {
					border: 0px !important;
					box-shadow: unset !important;
					content: " " !important;
					background: rgb(0 0 0 / 0%) !important;
				}

			}
			.mktoRadioList {
				label {
					p {
						color: $black-new !important;
						font-weight: $font-atten-book !important;
						font-size: 14px !important;
					}
				}
				input {
					&:checked {
						+ {
							label {
								.appointment {
									border: 2px solid $green !important;
								}
							}
						}
					}
				}
			}
		}
		.mktoFormRow {
			.mktoField {
				border: 1px solid $black-new !important;
				line-height: 20px !important;
				text-shadow: unset !important;
			}
		}
		.mktoFormRow.Row_30 {
			.mktoField {
				border: none !important;
			}
		}
		input {
			font-size: 16px !important;
		}
		optgroup {
			font-size: 16px !important;
		}
		select {
			font-size: 16px !important;
		}
		textarea {
			font-size: 16px !important;
		}
	
	}
	.mktoForm#mktoForm_5576{
		.mktoFormRow.Row_25 .wrap500 {
			padding-bottom: 20px !important;
		}
	}
	#mktoForm_5576,#mktoForm_5543{
		.disclaimer {
			font-weight: $font-atten-book !important;
			font-size: 10px !important;
			margin: 15px 0 10px !important;
			color: $lightgraytext !important;
			line-height: 12px;
		}
	}
	#mktoForm_5543.mktoForm,#mktoForm_5576.mktoForm {
		.mktoError {
			.mktoErrorMsg {
				font-weight: $font-atten-book !important;
				font-size: 14px;
			}
		}
	}
	.mktoForm#mktoForm_5543,.mktoForm#mktoForm_5576 {
		.button {
			a {
				@include green-filled-button;
				img{
					display: none;
				}
			}
		}
	}
	#mktoForm_5543{
		.Row_55{
			.mktoFormCol{
				min-height:0;
			}
		}
	}

	@include max-width($iphone4-landscape) {
		.mktoForm#mktoForm_5576 {
			.button {
				a {
					width: 100% !important;
					letter-spacing: 2.9px;
				}
			}
		}
	}
	.arrow {
		.arrow {
			&::before {
				background-color: $black-new !important;
			}
			&::after {
				border-color: $black-new !important;
			}
		}
	}
	 
	#mktoForm_5576{
		.des {
			p{
				color: $black-new !important;
				font-weight: $font-atten-book !important;
				&.sbhead{
					color: $black-new !important;
					font-weight: $font-atten-book !important;
				}
			}
		}
		&.mktoForm{
			.Field_1 .mktoCheckboxList > label{
				color: $black-new !important;
				font-weight: $font-atten-book !important;
			}
			.whteInfo{
				color: $green !important;
				font-weight: $font-atten-book !important;
				border: 1px solid $green !important;
			}
			#Step4Row5{
				text-align: center;
			}
		} 
	} 
	.red-error {
		color: red !important;
		font-size: 15px;
		padding-top: 5px !important;
		font-weight: $font-atten-book !important;
	}
}

#tour_form {
	#mktoForm_5543,#mktoForm_5576 {
		.Header {
			.headtxt {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
				padding-right: 10px !important;
				font-weight: 600;
			}
		}
		.heading {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			line-height: 1.2 !important;
		}
		.step {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			font-size: 14px !important;
		}
		.subhead {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
		}
		.mktoFormRow {
			.mktoField {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
			}
		}
		.nxtStep {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			font-size: 14px !important;
		}
		.bckstep {
			a {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
				font-size: 14px !important;
			}
		}
		.Row_35 {
			.subhead {
				font-size: 18px !important;
			}
		}
		.Row_36 {
			.headd {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
			}
			.date {
				color: $black-new !important;
				font-weight: $font-atten-book !important;
				line-height: 30px;
			}
		}
		.bbtn {
			a {
				@include black-outline-button;
				letter-spacing: 2px !important;
				// color: $black-new !important;
				// font-weight: $font-atten-book !important;
				// border: 1px solid $black-new !important;
				// line-height: 20px !important;
				// font-size: 18px !important;
			}
		}
		.ms-choice {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			border: 1px solid $black-new !important;
			line-height: 20px !important;
		}
		.ms-drop {
			li {
				label {
					span {
						color: $black-new !important;
						font-weight: $font-atten-book !important;
					}
				}
			}
			input[type="checkbox"] {
				visibility: visible !important;
				opacity: 1 !important;
				left: 20px !important;
				top: -2px;
				padding: 0 !important;
			}
		}
		a#PrivacyLink {
			color: $green !important;
		}
		.arrow {
			&::before {
				background-color: $black-new !important;
			}
			&::after {
				border-color: $black-new !important;
			}
		}
		.red-error {
			color: red !important;
			font-size: 15px;
			padding-top: 5px !important;
			font-weight: $font-atten-book !important;
		}
	}
	.mktoForm#mktoForm_5543,.mktoForm#mktoForm_5576 {
		.mktoLabel {
			color: $black-new !important;
			font-weight: $font-atten-book !important;
			font-size: 14px !important;
			left: 12px !important;
		}
		.button {
			.skip {
				a {
					color: $black-new !important;
					font-weight: $font-atten-book !important;
					background-color: transparent !important;
					font-size: 14px !important;
					letter-spacing: unset !important;
					text-transform: unset !important;
					border: unset !important;
				}
			}
			a {
				@include green-filled-button;
				min-width: 110px !important;
				max-width: unset !important;
				width: unset !important;
				padding: 10px!important;
			}
		}
		.Field_1,.Field_3 {
			.mktoRadioList {
				label {
					p {
						color: $black-new !important;
						font-weight: $font-atten-book !important;
						font-size: 14px !important;
					}
				}
				input {
					&:checked {
						+ {
							label {
								.appointment {
									border: 2px solid $green !important;
								}
							}
						}
					}
				}
			}
		}
		.mktoFormRow {
			.mktoField {
				border: 1px solid $black-new !important;
				line-height: 20px !important;
				text-shadow: unset !important;
			}
		}
		.mktoFormRow.Row_30 {
			.mktoField {
				border: none !important;
			}
		}
		input {
			font-size: 16px !important;
		}
		optgroup {
			font-size: 16px !important;
		}
		select {
			font-size: 16px !important;
		}
		textarea {
			font-size: 16px !important;
		}
	
	}
	.mktoForm#mktoForm_5576{
		.mktoFormRow.Row_25 .wrap500 {
			padding-bottom: 20px !important;
		}
	}
	#mktoForm_5576,#mktoForm_5543{
		.disclaimer {
			font-weight: $font-atten-book !important;
			font-size: 10px !important;
			margin: 15px 0 10px !important;
			color: $lightgraytext !important;
			line-height: 12px;
		}
	}
	#mktoForm_5543.mktoForm,#mktoForm_5576.mktoForm {
		.mktoError {
			.mktoErrorMsg {
				font-weight: $font-atten-book !important;
				font-size: 14px;
			}
		}
	}
	.mktoForm#mktoForm_5543,.mktoForm#mktoForm_5576 {
		.button {
			a {
				@include green-filled-button;
				img{
					display: none;
				}
			}
		}
	}
	#mktoForm_5543{
		.Row_55{
			.mktoFormCol{
				min-height:0;
			}
		}
	}

	@include max-width($iphone4-landscape) {
		.mktoForm#mktoForm_5576 {
			.button {
				a {
					width: 100% !important;
					letter-spacing: 2.9px;
				}
			}
		}
	}
	.arrow {
		.arrow {
			&::before {
				background-color: $black-new !important;
			}
			&::after {
				border-color: $black-new !important;
			}
		}
	}
	 
	#mktoForm_5576{
		.des {
			p{
				color: $black-new !important;
				font-weight: $font-atten-book !important;
				&.sbhead{
					color: $black-new !important;
					font-weight: $font-atten-book !important;
				}
			}
		}
		&.mktoForm{
			.Field_1 .mktoCheckboxList > label{
				color: $black-new !important;
				font-weight: $font-atten-book !important;
			}
			.whteInfo{
				color: $green !important;
				font-weight: $font-atten-book !important;
				border: 1px solid $green !important;
			}
			#Step4Row5{
				text-align: center;
			}
		} 
	} 
	.red-error {
		color: red !important;
		font-size: 15px;
		padding-top: 5px !important;
		font-weight: $font-atten-book !important;
	}
}
.fancybox-slide--html {
	padding: 0 !important;
	.fancybox-content {
		.fancybox-close-small {
			color: $white !important;
			background: $black-new !important;
			width: 25px !important;
			height: 25px !important;
			padding: 0px !important;
			right: 10px !important;
			top: 10px !important;
			border-radius: 50% !important;
		}
	}
}
.custom-tour.fancybox-slide--html {
	padding: 0 !important;
}



#mktoForm_5543,#mktoForm_5576 {
    .heading{
        font-size: 29px !important;
	}
	.subhead{
		font-size: 16px !important;
	}
    .Row_3{
        .subhead{
            font-size: 16px;
        }
    }
}

@include max-width($iphonex-landscape) {
    .tourpop_form{
        .form-column{
            width:100%;
        }
        .form-image{
            display:none;
        }
    }
    #tour_form #mktoForm_5543 .Header,#tour_form #mktoForm_5576 .Header {
        padding: 35px 30px 0 !important;
    }
	#tour_forms #mktoForm_5543 .Header,#tour_forms #mktoForm_5576 .Header {
        padding: 35px 30px 0 !important;
    }
    #tour_forms .mktoForm#mktoForm_5543 .Field_1 .mktoRadioList label p,#tour_forms .mktoForm#mktoForm_5576 .Field_3 .mktoRadioList label p{
        font-size: 14px !important;
    }
	#tour_forms .mktoForm#mktoForm_5543 .Field_1 .mktoRadioList label p,#tour_forms .mktoForm#mktoForm_5576 .Field_3 .mktoRadioList label p{
        font-size: 14px !important;
    }

    #mktoForm_5543,#mktoForm_5576 {
        .heading{
            font-size: 27px !important;
        }
    }

}
@include max-width($iphone4-landscape) {
    #tour_form .mktoForm#mktoForm_5543 .mktoLabel{
        font-size: 13px !important;
    }
	#tour_forms .mktoForm#mktoForm_5543 .mktoLabel{
        font-size: 13px !important;
    }
}


tbody .ui-state-hover,
tbody .ui-state-focus {
     background: #007fff !important;
     color: $white !important;
     border: 1px solid #003eff !important;
 }