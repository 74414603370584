@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.contact-main {
    .desktop-contact-image-box-main {
        .image-box {
            background-size: cover;
            min-height: 1079px;
            display: flex;
            position: relative;
            align-items: center;
            z-index: 0;
            &.add_overlay::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: $overlayblack;
                z-index: -1;
            }
            .image-box-content {
                color: $white;
                display: block;
                text-align: center;
                width: 100%;
                padding: 10% 0;
                .image-heading {
                    font-size: 62px;
                    padding-bottom: 20px;
                    font-weight: $font-atten-medium;
                    word-break: break-word;
                    margin: 0;
                    letter-spacing: -1.55px;
                    line-height: 80px;
                }
                .image-description {
                    width: 45%;
                    margin: auto;
                    margin-bottom: 3%;
                    font-size: 22px;
                    line-height: 30px;
                    letter-spacing: -1.1px;
                }
                .cards-section {
                    display: flex;
                    flex-wrap: wrap;
                    width: 80%;
                    margin: auto;
                    justify-content: center;
                    .region-card {
                        width: 29%;
                        border: 1px solid $white;
                        margin: 2%;
                        padding: 10px 30px;
                        color: $black-new;
                        background-color: $white;
                        .card-heading {
                            font-size: 43px;
                            line-height: 75px;
                            letter-spacing: -1.07px;
                            text-align: left;
                            color: $green;
                            font-weight: $font-atten-medium;
                        }
                        .region-list {
                            list-style: none;
                            padding: 0;
                            text-align: left;
                            li {
                                border-bottom: 1px solid #E0E0E0;
                                font-size: 22px;
                                line-height: 35px;
                                letter-spacing: -0.55px;
                                font-weight: $font-atten-medium;
                                position: relative;
                                padding: 4% 0;
                                cursor: pointer;
                                &:before {
                                    content: " ";
                                    position: absolute;
                                    width: 7px;
                                    height: 7px;
                                    border-top: 2px solid #C3C3C3;
                                    border-right: 2px solid #C3C3C3;
                                    transform: translate(-1px, -50%) rotate(45deg);
                                    right: 10px;
                                    top: 50%;
                                }
                                &:after {
                                    background: #C3C3C3;
                                    height: 2px;
                                    width: 13px;
                                    content: " ";
                                    position: absolute;
                                    transform: translate(-50%, -50%);
                                    right: 5px;
                                    top: 50%;
                                }
                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        @include max-width($desktop-xl) {
            .image-box {
                min-height: 890px;
                .image-box-content {
                    padding: 5% 0;
                    .image-heading {
                        font-size: 52px;
                        line-height: 60px;
                    }
                    .image-description {
                        width: 50%;
                    }
                    .cards-section {
                        .region-card {
                            .card-heading {
                                font-size: 35px;
                                line-height: 65px;
                            }
                            .region-list {
                                li {
                                    font-size: 18px;
                                    padding: 2% 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        @include max-width($desktop-large) {
            .image-box {
                .image-box-content {
                    .image-heading {
                        font-size: 40px;
                        line-height: 48px;
                    }
                    .cards-section {
                        .region-card {
                            .region-list {
                                li {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
        @include max-width($tablet-landscape) {
            .image-box-content {
                .image-heading {
                    font-size: 52px;
                    line-height: 54px;
                }
            }
        }
        @include max-width($iphonex-landscape) {
            .image-box {
                min-height: 800px;
                .image-box-content {
                    .cards-section {
                        width: 95%;
                        .region-card {
                            width: 29.3%;
                            .card-heading {
                                font-size: 24px;
                                line-height: 35px;
                            }
                            .region-list {
                                li {
                                    font-size: 14px;
                                    width: 100%;
                                    padding-right: 25px;
                                    &:before {
                                        width: 5px;
                                        height: 5px;
                                    }
                                    &:after {
                                        width: 10px;
                                    }
                                }
                            }
                        }
                    }
                    .image-description {
                        width: 80%;
                    }
                }
            }
        }
        @include max-width($iphone6-plus-landscape) {
            display: none;
        }
        @include max-width($iphone4-landscape) {
            .image-box {
                padding: 15% 10%;
            }
        }
    }
}

.division_modal {
    .modal-header {
        right: 40px !important;
        top: 40px !important;
        .close {
            background-color: unset !important;
            border: 1px solid #707070 !important;
            border-radius: 50% !important;
            height: 40px !important;
            width: 40px !important;
            span {
                color: $black-new !important;
            }
        }
    }
    .modal-body {
        background-color: $white !important;
    }
    @include max-width($iphone6-plus-landscape) {
        .modal-header {
            top: 0px !important;
            right: 0px !important;
            .close {
                border: none !important;
            }
        }
    }
}

.modal-cards-section {
    padding: 5% 10%;
    .modal-cards-headsection {
        margin-left: 1%;
        .region-title {
            font-size: 16px;
            letter-spacing: 4.8px;
            line-height: 25px;
            font-weight: $font-atten-bold;
            color: $gray-new;
        }
        .card-division_name,
        .card-state_abbreviation {
            font-weight: $font-atten-medium;
            font-size: 80px;
            color: $green;
            padding-top: 20px;
            display: inline-block;
            word-break: break-word;
            margin: 0;
            letter-spacing: -4px;
            font-weight: 500;
            line-height: 80px;
        }
        .card-state_abbreviation {
            color: $gray-new;
            .comma {
                color: $green;
            }
        }
        .category-list {
            margin: 30px 0px 15px 0px;
            .active_cat {
                background: $black-new;
                color: white;
            }
            span {
                background: #f4f4f4;
                border-radius: 24px;
                margin-right: 10px;
                padding: 5px 20px;
                font-size: 18px;
                display: inline-block;
                color: #545454;
                line-height: 30px;
                letter-spacing: -0.45px;
                cursor: pointer;
                margin-bottom: 15px;
            }
        }
    }
    .contact-info-div {
        display: flex;
        flex-wrap: wrap;
        .contact-info {
            width: 31%;
            border: 1px solid #DBDBDB;
            padding: 5px;
            margin: 1%;
            padding: 30px;
            min-height: 198px;
            .contact-name {
                .title {
                    font-size: 24px;
                    letter-spacing: -0.6px;
                    line-height: 30px;
                    color: $black-new;
                    font-weight: $font-atten-medium;
                }
            }
            h6 {
                font-weight: $font-atten-book;
                color: $black-new;
                font-size: 22px;
            }
            .email,
            .contact_url {
                color: $green;
                font-weight: $font-atten-book;
                font-size: 22px;
                text-decoration: none;
                word-break: break-all;
                display: block;
            }
            .contact-text {
                font-size: 22px;
                letter-spacing: -1.1px;
                line-height: 30px;
                color: $black-new;
                margin: 0;
                p {
                    margin: 0;
                }
            }
        }
    }
    @include max-width($desktop-xl) {
        .modal-cards-headsection {
            .card-division_name,
            .card-state_abbreviation {
                font-size: 52px;
                line-height: 60px;
                letter-spacing: -2px;
            }
        }
    }
    @include max-width($desktop-large) {
        .modal-cards-headsection {
            .card-division_name,
            .card-state_abbreviation {
                font-size: 40px;
                line-height: 48px;
            }
        }
        .contact-info-div {
            .contact-info {
                .contact-text,
                h6,
                .email,
                .contact_url {
                    font-size: 20px;
                }
            }
        }
    }
    @include max-width($tablet-landscape) {
        .modal-cards-headsection {
            .card-division_name,
            .card-state_abbreviation {
                font-size: 50px;
                line-height: 54px;
            }
        }
        .contact-info-div {
            .contact-info {
                width: 48%;
            }
        }
    }
    @include max-width($iphonex-landscape) {
        .contact-info-div {
            .contact-info {
                .contact-text,
                h6,
                .email,
                .contact_url {
                    font-size: 18px;
                    letter-spacing: -0.9px;
                    line-height: 26px;
                }
            }
        }
    }
    @include max-width($iphone6-plus-landscape) {
        .modal-cards-headsection {
            margin-left: 0;
            .category-list {
                span {
                    width: 100%;
                }
            }
        }
        .contact-info-div {
            .contact-info {
                width: 100%;
                margin: 20px 0;
                padding-bottom: 50px;
            }
        }
    }
}