@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.grid-section {
    background-color: $bggray-new;

    .grid-section__header {
      background-color: $white;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 90px;
      padding-bottom: min(64px, 5%);
      flex-wrap: wrap;

      .heading-section-left{
        width: 47%;
        text-align: left;
        margin-right: 6%;
      }

      .heading-section-right{
        width: 47%;
        display: flex;
        text-align: left;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding-bottom: 40px;

        .description {
          color: $charcoal;
        }

        a, a:hover, a:visited {
          border-bottom: 2px solid $apple;
          color: $charcoal;
          text-decoration: none;
          font-size: 24px;
          font-weight: $font-atten-medium;
          line-height: 32px;;
        }
      }
    }

    .row {
        margin: 0;
    }
    .grid-img {
        padding: 0;
        height: 640px;
    }
    .grid-info {
        padding: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 76px;
            height: 55px;
            padding: 0;
            margin-bottom: 40px;
            object-fit: contain;
        }
        .heading-section {
            text-align: unset;
            width: unset;
            margin: 0 0 10px;
        }
        .main-title {
            margin: 0;
            padding:0;   
        }
        .green-color {
            color: $green;
        }
        .gray-color {
            color: $gray-new;
        }
        .title-info {
            font-size: 20px;
            color: #545454;
            margin-bottom: 0;
            letter-spacing: -1.1px;
            line-height: 30px;
            padding-top: 10px;
            font-weight: 400;
            p {
                margin: 0;
            }
        }
    }
    .learn-more {
        color: $black-new;
        border-bottom: 2px solid $green;
        font-size: 24px;
        font-weight: $font-atten-medium;
        padding-top: 30px;
        display: inline-block;
        letter-spacing: -1.2px;
        &:hover {
            text-decoration: none;
        }
    }
    @include max-width($desktop-xl) {
        .grid-img {
            height: 508px;
        }
    }
    @include max-width($desktop-large) {
        .learn-more {
            font-size: 18px;
            letter-spacing: -1px;
            line-height: 24px;
        }
        .grid-img {
            padding: 0;
            height: 457px;
        }
        .grid-info {
            img {
                width: 76px;
                height: 55px;
                margin-bottom: 30px;
            }
        }
    }
    @include max-width($macbook) {
        .grid-img {
            height: 433px;
        }
    }
    @include max-width($tablet-landscape) {
        .grid-img {
            height: 380px;
        }
    }
    @include max-width($iphonex-landscape) {
      .grid-section__header{
        padding-top: 40px;
        padding-bottom: 40px;

        .heading-section-left{
          width: 100%;
        }
        .heading-section-right{
          width: 100%;
          padding-bottom: 20px;
        }
      }
      .grid-img {
        height: 390px;
      }
      .grid-info {
        img {
          width: 69px;
          height: 51px;
        }
      }
    }
    @include max-width($iphone4-landscape) {
        .grid-img {
            height: 300px;
        }
        .grid-info {
            // min-height: 350px;
            padding: 5% 10%;
            img {
                width: 66px;
                height: 43px;
                margin-bottom: 20px;
            }
        }
    }
}

.grid-section .grid-img {
  padding-top: 68.3%;
  position: relative;
  height: 0;
}

.grid-section .grid-img img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

// Update responsive grid layout
@media (max-width: 1023px) {
    .grid-section__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: min(64px, 5%);
      flex-wrap: wrap;

      .heading-section-left {
        width: 100%;
        margin-right: 0;
      }
      .heading-section-right {
        width: 100%;
      }
    }

  .grid-section .grid-info {
    display: block;
    padding: 40px var(--wrap-padding-h, 32px) 65px;
  }

  // Update mobile order so images come first
  .grid-section .grid-img:nth-child(2) {
    order: 0;
  }

  .grid-section .grid-info:nth-child(0) {
    order: 1;
  }

  .grid-section .grid-img:nth-child(4) {
    order: 3;
  }

  .grid-section .grid-info:nth-child(1) {
    order: 2;
  }

  .grid-section .grid-info:nth-child(3) {
    order: 4;
  }

  .grid-section .grid-img:nth-child(6) {
    order: 5;
  }

  .grid-section .grid-info:nth-child(5) {
    order: 6;
  }
}

@media (min-width: 1024px) and (max-width: 1600px) {
  .grid-section .grid-img:nth-child(2) {
    order: 0;
  }

  .grid-section .grid-info:nth-child(0) {
    order: 1;
  }

  .grid-section .grid-info:nth-child(1) {
    order: 3;
  }

  .grid-section .grid-img:nth-child(4) {
    order: 4;
  }

  .grid-section .grid-info:nth-child(3) {
    order: 3;
  }

  .grid-section .grid-img:nth-child(6) {
    order: 5;
  }

  .grid-section .grid-info:nth-child(5) {
    order: 6;
  }
}

.grid-section .grid-img {
  @media (min-width: 1024px) {
    height: auto;
    min-height: 520px;
    padding-top: 0;
  }

  @media (min-width: 1601px) {
    min-height: 640px;
  }
}

.grid-section [class^="col-"] {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;

  @media (min-width: 1024px) and (max-width: 1600px) {
    &.grid-img {
      flex-basis: 57.3438%;
      max-width: 57.3438%;
      width: 57.3438%;
    }

    &.grid-info {
      flex-basis: 42.6563%;
      max-width: 42.6563%;
      padding: 40px min(5.52vw, 106px);
      width: 42.6563%;
    }
  }

  @media (min-width: 1601px) {
    flex-basis: 33.333%;
    max-width: 33.333%;
    width: 33.333%;
  }
}

.grid-section .grid-info b {
  color: var(--black);
  font-family: var(--font-family-medium);
  font-weight: 500;
}
