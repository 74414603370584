@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";


.Sitemap_section{
    padding: 5%;

    .submarket_section{
        padding: 25px 0;
        margin: 0 5%;
        h3{
            font-weight: 300;
        }
        &:not(:last-child){
            border-bottom: 1px solid #cccccc;
        }
        .neigh_comm_section{
            display: flex;
            flex-wrap: wrap;
            h4{
                color: $gray-new;
                font-weight: 300;
                a{
                    color: $gray-new;
                    &:hover{
                        color: $gray-new;
                    }
                }
            }
            
            > div{
                width: 33%;
                padding-left: 20px;
                ul{
                    padding-left: 20px;
                    list-style-type: none;
                    a{
                        color: $black-new;
                        &:hover{
                            color: $black-new;
                        }
                    }
                }
            }
        }
    }
}
@include max-width($tablet-landscape) {
    .Sitemap_section{
        .submarket_section{
            .neigh_comm_section{
                > div{
                    width: 50%;
                }
            }
        }
    }
}

@include max-width($iphone4-landscape) {
    .Sitemap_section{
        padding: 25% 10% 5%;
        .submarket_section{
            .neigh_comm_section{
                > div{
                    width: 100%;
                }
            }
        }
    }
}