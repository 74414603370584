@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.headerWrapper {

    .headerLogoArea {
        position: fixed;
        top: 0;
        right: 0;
        background: transparent;
        width: 320px;
        height: 160px;
        
        z-index: 999;
        overflow: hidden;
        pointer-events: none;

        &:after {
            content: ' ';
            position: absolute;
            width: 200%;
            height: 100%;
            background: $white;
            transform: rotate(26.6deg) translate(-25%, -100%);
            pointer-events: all;
        }

        .headerLogoWrapper {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 5%;

            .logoWrapper {
                position: relative;
                width: 90px;
                height: 48px;
                pointer-events: all;
            }

            .headerLogo {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;

                &.logoLight {
                    display: none;
                }
            }

            .hamburgerBtn {
                background-color: transparent;
                border: none;
                position: relative;
                height: 30px;
                margin-top: 9px;
                width: 20px;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 20px;
                cursor: pointer;
                padding: 8px 0;
                pointer-events: all;

                > span {
                    width: 100%;
                    height: 2px;
                    background: $black-new;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .navigationMain {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background: $black-new;
        padding-top: 80px;
        overflow: hidden;
        opacity: 0;
        pointer-events: none;
        z-index: 9995;
        transition: opacity 0.5s ease;
        display: none;

        .navigationWrapper {
            width: 100%;
            padding: 0 5%;
            .headingWrapper {
                .heading-section {
                    width: 100%;
                    text-align: left;

                    .main-title {
                        padding: 0;
                        margin-bottom: 25px;
                    }
                }
            }
        }

    }
   
    &.active {

        .headerLogoArea {
            z-index: 9996;
            &:after {
                background: transparent;
            }

            .headerLogoWrapper {

                .headerLogo {
                    display: none;

                    &.logoLight {
                        display: block;
                    }
                }

                .hamburgerBtn {
                    padding: 0;

                    > span {
                        background: $white;

                        &:first-child {
                            transform: rotate(-45deg) translate(-10px, 10px);
                        }

                        &:nth-child(2) {
                            opacity: 0;
                        }

                        &:last-child {
                            transform: rotate(45deg) translate(-10px, -10px);
                        }
                    }
                }
            }

        }

        .navigationMain {
            opacity: 1;
            pointer-events: all;
            height: 100%;
            display: block;
        }

    }

    // desktop
    @include min-width($tablet-landscape) {
        .headerLogoArea {
            width: 400px;
            height: 200px;

            .headerLogoWrapper {
                padding: 25px 40px 30% 30%;

                .logoWrapper {
                    width: 116px;
                    height: 56px;
                }
                .hamburgerBtn {
                    height: 30px;
                    width: 30px;
                }
            }
        }

        .navigationMain {
            padding-top: 130px;

            .navigationWrapper {
                padding: 0 2% 20px 5%;
            }
        }
    }

    // xl desktop
    @include min-width($desktop-xl) {
        .headerLogoArea {
            width: 540px;
            height: 270px;

            .headerLogoWrapper {
                padding: 30px 50px 30% 30%;

                .logoWrapper {
                    width: 170px;
                    height: 70px;
                }
            }
        }

        .navigationMain {

            .navigationWrapper {
                padding: 0 2% 20px 10%;
            }
        }
    }
}

.navigationMain {
    &::-webkit-scrollbar {
        background-color: $white;
        width: 16px
    }

    &::-webkit-scrollbar-track {
        background-color: $white;
    }

    &::-webkit-scrollbar-track:hover {
        background-color: $white
    }

    &::-webkit-scrollbar-thumb {
        background-color: $new-border-color;
        border-radius: 16px;
        border: 5px solid $white
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $new-border-color;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }
}

// make header horizontal on mobile on search page
@include max-width($tablet-landscape) { 
    .header_section.headerHorizontal .headerWrapper {
        .headerLogoArea {
            width: 100%;
            height: 50px;
            background-color: $white;
            transition: width .3s, height .3s;
        }
        .headerLogoWrapper {
            padding: 1px 16px;
        }

        &.active { 
            .headerLogoArea {
                background-color: $black-new;
            }
            @include min-width($tablet-landscape) { 
                background-color: transparent;
            }
        }
    }
}

.bg-diagonal {
    &:after {
        content: '';
        @include min-width($tablet-landscape) { 
            content: ' ';
            position: absolute;
            width: 220%;
            height: 100%;
            background: #505050;
            transform: rotate(26.6deg) translate(-25%, -100%);
            pointer-events: all;
            z-index: -1;
        }
    }
}