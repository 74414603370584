@import '../../assets/scss/partials/variables';
@import '../../assets/scss/partials/mixins';
@import '../../assets/scss/partials/fonts';

.global-components > .sectiontitle-main-div {
    margin: var(--section-margin-top, 40px) auto var(--section-margin-bottom, 40px);
    max-width: 1920px;
    padding: var(--section-padding-top, 40px) var(--wrap-padding-h-desktop, var(--wrap-padding-h, 32px))
        var(--section-padding-bottom, 40px);

    @media (min-width: 768px) {
        padding-left: var(--wrap-padding-h-tablet, var(--wrap-padding-h, 32px));
        padding-right: var(--wrap-padding-h-tablet, var(--wrap-padding-h, 32px));
    }

    @media (min-width: 1024px) {
        margin-bottom: var(--section-margin-bottom-desktop, 60px);
        margin-top: var(--section-margin-top-desktop, 60px);
        padding: var(--section-padding-top-desktop, 60px) var(--wrap-padding-h-desktop, var(--wrap-padding-h, 32px))
            var(--section-padding-bottom-desktop, 60px);
    }
}

.sectiontitle-main-div {
    padding: 5rem 5% 3rem 5%;

    .eyebrow-text-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;
        width: 100%;

        .sectiontitle-eyebrow_text {
            padding-right: 15px;
        }

        .eyebrowtext-border {
            border-top: 1px solid var(--gray-light);
            flex-grow: 1;
        }
    }

    @include max-width($iphone4-landscape) {
        padding: 5rem 10% 5% 10%;
    }
}
