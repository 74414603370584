@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.info-grid {
    .owl-dots {
        position: absolute;
        bottom: -24px;
        transform: translateY(100%);
        height: 4px;
        display: flex;
        width: 200px;
        justify-content: space-between;

        .owl-dot {
            border-radius: 50%;
            height: 10px;
            width: 10px;
            background: $steel;

            &.active {
                background: $avacado;
            }

            span {
                display: none;
            }
        }
    }

    .owl-carousel.owl-loaded  {
        display: none;
    }

    .info-grid__border {
        border: 1px solid $steel;
        padding: 108px;
    }

    .info-grid__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: min(64px, 5%);
        flex-wrap: wrap;

        .heading-section-left{
            width: 47%;
            text-align: left;
            margin-right: 6%;
        }

        .heading-section-right{
            width: 47%;
            display: flex;
            text-align: left;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            padding-bottom: 40px;

            .description {
                color: $charcoal;
            }

            a, a:hover, a:visited {
                border-bottom: 2px solid $apple;
                color: $charcoal;
                text-decoration: none;
                font-size: 24px;
                font-weight: $font-atten-medium;
                line-height: 32px;;
            }
        }
    }

    .info-grid__item_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 32px 22px;
    }

    .blog-info {
        height: 100%;

        a {
            font-size: var(--description-font-size, 16px);
            line-height: 24px;
            color: $olive;
        }

        h6 {
            font-size: 28px;
            line-height: 37px;
            font-weight: $font-atten-medium;
            color: $charcoal;
        }

        .blog-img {
            height: 25vw;
            margin-bottom:30px;
        }

        .blog-text {
            color: #848484;
            font-size: 20px;
            line-height: 1.375;
            margin: 0;
            max-width: var(--media-card-copy-max-width, 50ch);

            p {
                margin: 0 0 8px 0;
            }
        }

        .description {
            margin-bottom: 0.5rem;
        }
    }

    @include max-width($desktop-large) {
        .blog-info{
            h6{
                font-size: 22px;
                line-height: 32px;
            }
        }
        .info-grid__item_grid {
            .blog-info {
                margin-right: 2.5%;
            }
        }
    }

    @include max-width($iphonex-landscape) {
        .info-grid__header {
            padding-bottom: 40px;
            .heading-section-left{
                width: 100%;
            }
            .heading-section-right{
                width: 100%;
                padding-bottom: 20px;
            }
        }
    }

    @media (max-width: 1023px) {
        .info-grid__item_grid {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 32px 22px;
        }
    }

    @media (max-width: 767px) {
        .owl-carousel.owl-loaded {
            display: block;
        }

        .info-grid__border {
            border: unset;
            padding: 0;
        }

        .info-grid__header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: min(64px, 5%);
            flex-wrap: wrap;

            .heading-section-left {
                width: 100%;
                margin-right: 0;
            }
            .heading-section-right {
                width: 100%;
            }
        }

        .info-grid__item_grid {
            display: none;
        }

        .blog-info{
            .blog-img{
                height: 50vw;
            }
        }
    }
}
