
@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

html{
    margin-top: 0px !important;
}
*{
    padding: 0;
    margin: 0;
}
a{
    text-decoration: none;
    color: #000000;
    outline: none;
    &:hover{
        color: #000000;
        outline: none;
    }
    &:focus{
        outline: none;
    }
}

ul,ol{
    padding-left: 20px;
}
h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
}

section {
    // float: left;
    width: 100%;
}

img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

body{
    font-weight: $font-atten-medium;
    font-weight: 400;
    color: $black-new;

    /*Header Transition*/
    &.sticky-header {
        header {
            padding: 10px 0;
            box-shadow: 0px 10px 20px -22px #000000;
        }
    }
}

.container-fuild {
    width: 100%;
    margin: 0 auto;
    max-width: 1920px;    
    position: relative;
}

.container {
    width: 100%;
    margin: 0 auto;
    max-width: 1920px;
    position: relative;
}

body.body_overflow{
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
}

.main-outer-div {
    // float: left;
    width: 100%;
    padding-top:128px;
}

.hidden-btn{
    display: none !important;
}
/* Modal CSS */
.modal{
    padding: 0 !important;
    height: 100vh !important;
    z-index: 99999999;
}
.modal-dialog{
    height: 100%;
    padding: 0 !important;
    margin: unset;
    max-width: 100%;
    overflow-y: hidden !important;
    .modal-content{
        height: 100%;
        border: none;
        border-radius: unset;
        .modal-header{
            background-color: $bggray-new;
            padding: 2% 5%;
            border: 0;
            .modal-title{
                width: 90%;
            }
            h2{
                font-size: 44px;
                line-height: 58px;
                color:$black-new;
            }
            p{
                text-transform: uppercase;
                font-size: 14px;
                letter-spacing: 5.6px;
                color: $black-new;
                line-height: 19px;
                margin: 0;
            }
            .close{
                background: #fff;
                opacity: 1;
                border-radius: 0%;
                height: 76px;
                width: 76px;
                padding:3px 1px 0 0;
                margin: 0;
                outline: none;
                border: 1px solid $new-border-color;
                // position: absolute;
                // right: 5%;
                color:$black-new;
                span{
                    font-size: 50px;
                    line-height: 25px;
                    font-family: none;
                }
                @include max-width($desktop-xl){
                    height: 50px;
                    width: 50px;
                    span{
                        font-size: 35px;
                    }
                }
                @include max-width($iphonex-landscape) {
                    height: 35px;
                    width: 35px;
                    span{
                        font-size: 25px;
                        line-height: 15px;
                    }
                }
            }
            @include max-width($desktop-xl) {
                h2{
                    font-size: 40px;
                    line-height: 40px;
                }
            }
            @include max-width($desktop-large){
                h2{
                    font-size: 34px;
                    line-height: 34px;
                }
            }
            @include max-width($tablet-landscape){
                h2{
                    font-size: 30px;
                }
                p{
                    font-size: 12px;
                    letter-spacing: 4.8px;
                    line-height: 16px;
                }
            }
        }
        .modal-body{
            flex:1;
            padding: 0;
            margin-bottom: -1px;
            // background-color: $bggray-new;
            iframe{
                width: 100%;
                height: 100%;
                border: unset;
                position: absolute;
            }
        }
    }

}
/* /Modal CSS*/


/* Estimate_modal css*/
.Estimate_modal{
    .modal-dialog{
        .modal-content{
            ::-webkit-scrollbar {
                width: 0px;
            }
            .modal-header{
                padding: 0;
                position: absolute;
                right: 0;
                .close{
                    text-shadow: unset;
                    height: 30px;
                    width: 30px;
                    right: 12px;
                    top: 15px;
                    background: $black-new;
                    z-index:9;
                    span{
                        font-size: 30px;
                        font-weight: lighter;
                        line-height: 23px;
                        color: $white;
                    }
                }
            }
            .modal-body{
                overflow-y: auto;
                background-color: $bgdarkgray;
                .mortgage-section-main,.mortgage-section{
                    background-color: $bgdarkgray;
                    height:100%;
                }
            }
            @include max-width($tablet) {
                .modal-body{
                    .mortgage-section{
                        height: auto;
                    }
                }
            }
        }
    }
}

/* BreadCrum CSS */
.breadcrumbs_section{
    .breadcrumbs_list{
        padding: 15px 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li{
            display: inline-block;
            font-size: 12px;
            a{
                color: $gray-new;
                cursor: pointer;
                text-transform: uppercase;
                padding: 0 6px;
                &:hover{
                    color: $gray-new;
                    text-decoration: none;
                }
                &.current_breadcrumb{
                    cursor: unset;
                }
            }
            &.breadcrumb_separator{
                font-size: 10px;
                color: $gray-new;
                span{
                    vertical-align: top;
                    line-height: normal;
                }
            }
        }

        @include max-width($desktop-xl){
            li{
                font-size: 10px;
                &.breadcrumb_separator{
                    font-size: 8px;
                }
                a{
                    padding: 0 4px;
                }
            }
        }
        @include max-width-orientation($iphonex-landscape,'landscape'){
            padding: 10px 0;
            li{
                font-size: 8px;
                &.breadcrumb_separator{
                    font-size: 6px;
                }
            }
        }
        @include max-width($iphone4-landscape){
            padding: 10px 0;
            padding-right: 10%;
            line-height: normal;
            li{
                font-size: 7px;
                &.breadcrumb_separator{
                    font-size: 5px;
                }
            }
        }

    }
}
/* /BreadCrum CSS */

/* Events Page CSS */
.events-page{
    .events-sec{
        .header-sec {
            float: left;
            width: 100%;
            padding: 2% 9% 0;
            h2 {
                letter-spacing:0;
                text-align: left;
                font-size: 75px;
                line-height:85px;
                letter-spacing: 0px;
                color: #545454;
                margin-bottom: 28px;
            }
    
            .filter-sec{
                select{
                    font-size:23px;
                    line-height:28px;
                    letter-spacing: -0.57px;
                    color: #545454;
                    border: 1px solid #D1D1D1;
                    font-weight: $font-atten-medium;
                    padding: 25px 22px 24px;
                    margin: 0 20px 0 0;
                    min-width: 398px;
                    float: left;
                    outline: none;
                    -webkit-appearance: none;
                    background: no-repeat calc(100% - 20px);
                    // background-image:url('../../images/angle-arrow-down.png');
                }
        
                a{
                    float: left;
                    @include green-filled-button; 
                    padding: 20px 0px;
                    margin: 0;
                    min-width: 323px;
                    line-height: 35px !important;
                    border: 1px solid #e5721f;
                    transition: all .5s ease;

                    &:hover{
                        color: #000000;
                        background-color: $white !important;
                    }
                }
            }
        }

        .tabing-sec{
            float: left;
            width: 100%;
            margin: 7% 0 0;
            .react-tabs{
                ul.react-tabs__tab-list {
                    border-bottom: none;
                    padding: 0% 14%;
                    margin-bottom: 0;
                    li{
                        font-size:18px;
                        line-height:35px;
                        letter-spacing: 3.6px;
                        color: #969696;
                        border: 1px solid #D1D1D1;
                        font-weight: $font-atten-medium;
                        text-align: left;
                        border: none;
                        border-bottom:4px solid #ffffff;
                        padding: 0;
                        margin: 0 68px 0 0;
                        text-transform: uppercase;

                        &.react-tabs__tab--selected{
                            border-bottom:4px solid #E5721F;
                            color: #545454;
                        }
                        &:last-child{
                            margin: unset !important;
                        }
                    }
                }
                .react-tabs__tab-panel--selected {
                    background: #F7F7F7;
                    padding:5.1% 14% 0;
                    height: 100%;
                    float: left;
                    width: 100%;
                    .no-event{
                        margin:0px 0 6.1%;
                    }
                    .events-list{
                        float: left;
                        width: 100%;
                        margin:0px 0 160px;

                        .events-list-month {
                            h3{
                                font-size:48px;
                                line-height:50px;
                                letter-spacing:0px;
                                color: #545454;
                                margin: 0 0 59px;
                            }
                        }

                        .event{ 
                            margin: 0 0 7.6% 0;
                            float: left;
                            width: 100%;

                            // &:last-child {
                            //     margin: 0px;
                            // }
                            .ev-date{
                                float: left;
                                width: 165px;
                                background: #C0D34A;
                                color: $white;
                                text-align: center;
                                padding: 2.14% 2%;

                                span{
                                    display: block;
                                    letter-spacing: 0px;
                                    font-size: 18px;
                                    line-height: 32px;
                                }

                                span + span{
                                    display: block;
                                    letter-spacing: 0px;
                                    font-size: 60px;
                                    line-height: 74px;
                                }
                            }

                            .ev-info {
                                float: left;
                                width: 80%;
                                display: flex;
                                background: white;
                                min-height: 409px;
                                .ev-details{
                                    float: left;
                                    width: 62%;
                                    padding:3.3% 4.5% 3.3% 5.8%; 
                                    span{
                                        letter-spacing:4.2px;
                                        font-size:14px;
                                        line-height:25px;
                                        color: #969696;
                                        font-weight: $font-atten-medium;
                                        padding: 0 0 24px 0;
                                        display: block;
                                        text-transform: uppercase;
                                    }

                                    h3{
                                        letter-spacing: 0px;
                                        font-size: 30px;
                                        line-height: 32px;
                                        color: #545454;
                                        margin-bottom: 15px;
                                    }

                                    p{
                                        letter-spacing: 0px;
                                        color: #545454;
                                        font-size:18px;
                                        line-height: 20px;
                                    }

                                    a{
                                        letter-spacing:3.2px;
                                        font-size: 16px;
                                        line-height: 25px;
                                        font-weight: $font-atten-medium;
                                        color: #000000;
                                        text-decoration: none;
                                        position: relative;

                                        &::before{
                                            content: " ";
                                            position: absolute;
                                            top: 50%;
                                            left: auto;
                                            width: 7px;
                                            height: 7px;
                                            border-top: 2px solid #E5721F;
                                            border-right: 2px solid #E5721F;
                                            -webkit-transform: translate(-1px,-50%) rotate(45deg);
                                            transform: translate(-1px,-50%) rotate(45deg);
                                            right: -30px;
                                        }

                                        &::after {
                                            background: #E5721F;
                                            -webkit-transform: translate(-50%,-50%);
                                            transform: translate(-50%,-50%);
                                            height: 2px;
                                            width: 13px;
                                            content: " ";
                                            position: absolute;
                                            top: 50%;
                                            left: auto;
                                            right: -34px;
                                        }
                                    }

                                    .event-time-venue{
                                        display: block;
                                        margin: 38px 0 0;
                                        display: flex;
                                        align-items: center;
                                        span{
                                            letter-spacing:0px;
                                            font-size: 22px;
                                            line-height:38px;
                                            color: #545454;
                                            font-weight: $font-atten-medium;
                                            display: inline-block;
                                            width: 35%;
                                            padding: 10px 10% 10px 0;
                                            border-right: 1px solid #DEDEDE;
                                            display: flex;
                                        }

                                        span + span{
                                            letter-spacing:0px;
                                            font-size: 18px;
                                            line-height: 24px;
                                            color: #545454;
                                            font-weight: $font-atten-medium;
                                            display: inline-block;
                                            // word-break: break-all;
                                            width: 65%;
                                            border-right: none;
                                            padding:10px 0 10px 10%;
                                        }
                                    }
                                }

                                .ev-img {
                                    float: left;
                                    width: 414px;
                                    height: 409px;
                                    background-color: $lightgray;

                                    img  {
                                        object-fit: cover;
                                        height: 100%;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }

    @include max-width($desktop-xl) {
        .events-sec{
            .header-sec {
                padding: 2% 5% 0;
                h2 {
                    font-size:65px;
                    line-height:75px;
                }
        
                .filter-sec{
                    select{
                        min-width: 320px;
                        padding: 15px 22px 14px;
                    }
            
                    a{
                        padding: 11px 20px;
                        min-width:205px;
                    }
                }
            }

            .tabing-sec{
                margin:8% 0 0;
                .react-tabs{
                    .react-tabs__tab-panel--selected {
                        .events-list{
                            .event{ 
                                .ev-info {
                                    .ev-details {
                                        .event-time-venue {
                                            span {
                                                padding: 10px 5% 10px 0;
                                            }
                                            span+span {
                                                width: 80%;
                                                padding: 10px 0 10px 5%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include max-width($desktop-large) {
        .events-sec{
            .header-sec {
        
                .filter-sec{
                    select{
                        min-width: 325px;
                    }
            
                    a{
                        min-width:205px;
                    }
                }
            }

            .tabing-sec{
                .react-tabs{
                    .react-tabs__tab-panel--selected {
                        .events-list{
                            .event{ 
                                .ev-date {
                                    width: 15.7%;
                                    padding: 1.5% 2%;
                                }
                                .ev-info {
                                    width: 84%;
                                    .ev-details{
                                        padding: 3.7% 3.5% 3.7% 5.5%;

                                        h3 {
                                            font-size: 25px;
                                        }

                                        p {
                                            font-size: 16px;
                                        }

                                        .event-time-venue{
                                            margin: 23px 0 0;
                                            span{
                                                font-size:18px;
                                                line-height:24px;
                                                padding: 10px 2% 10px 0;
                                            }
    
                                            span + span{
                                                font-size: 16px;
                                                line-height: 22px;
                                                padding: 10px 0 10px 5%;
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }

    @include max-width($desktop) {
        .events-sec{
            .header-sec {
        
                .filter-sec{
                    a{
                        min-width:205px;
                    }
                }
            }

            .tabing-sec{
                .react-tabs{
                    .react-tabs__tab-panel--selected {
                        .events-list{
                            .event{ 
                                .ev-info {
                                    .ev-details{
                                        padding: 3.7% 3.5% 3.7% 5.5%;

                                        h3 {
                                            font-size: 25px;
                                        }

                                        p {
                                            font-size: 16px;
                                        }
                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }

    @include max-width(1100px) {
        .events-sec{
            .tabing-sec{
                .react-tabs{
                    .react-tabs__tab-panel--selected {
                        .events-list{
                            .event{ 
                                .ev-info {
                                    min-height: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include max-width($tablet-landscape) {
        .events-sec{
            .tabing-sec{
                .react-tabs{
                    .react-tabs__tab-panel--selected {
                        .events-list{
                            .event{ 
                                .ev-info {
                                    .ev-details{
                                        .event-time-venue{
                                            span{
                                                line-height: 28px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
            
        }
    }
    @include max-width($iphonex-landscape) {
        .events-sec{
            .header-sec {
                h2 {
                    font-size:65px;
                    line-height:75px;
                }
                .filter-sec{
                    select{
                        min-width: 247px;
                        font-size: 17px;
                        width: 225px;
                        margin: 0 15px 0 0;
                    }
            
                    a{
                        min-width:205px;
                    }
                }
            }

            .tabing-sec{
                margin: 3.5% 0 0;
                .react-tabs{
                    ul.react-tabs__tab-list {
                        padding: 0% 10%;
                        li{
                            font-size:14px;
                            line-height:30px;
                            letter-spacing: 3.2px;
                            margin: 0 40px 0 0;
                        }
                    }
                    .react-tabs__tab-panel--selected {
                        padding:5% 10%;
                        .no-event{
                            margin:22px 0;
                        }
                        .events-list{
                            margin:22px 0 100px;

                            .events-list-month {
                                h3{
                                    font-size:35px;
                                }
                            }

                            .event{ 
                                .ev-date{
                                    float: left;
                                    width: 15%;
                                    padding: 3.5% 1%;
    
                                    span{
                                        font-size: 14px;
                                        line-height:28px;
                                    }
    
                                    span + span{
                                        font-size:40px;
                                        line-height: 54px;
                                    }
                                }
    
                                .ev-info {
                                    width: 85%;
                                    .ev-details{
                                        float: left;
                                        width: 62%;
                                        padding:3.7% 2% 3.7% 4%; 
                                        span{
                                            padding: 0 0 12px 0;
                                        }
    
                                        h3{
                                            font-size: 26px;
                                            line-height: 32px;
                                        }
    
                                        p{
                                            font-size:15px;
                                        }
    
                                        a{
                                            font-size: 14px;
                                        }
    
                                        .event-time-venue{
                                            margin:15px 0 0;
                                            span{
                                                font-size:22px;
                                                line-height: 30px;
                                                padding: 5px 5% 5px 0;
                                            }
    
                                            span + span{
                                                font-size:18px;
                                                line-height:24px;
                                                padding: 5px 0 5px 5%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
            
        }
    }
    // @include max-width($tablet) {
    //     .events-sec{
    //         .header-sec {
    //             .filter-sec{
    //                 select{
    //                     min-width: 228px;
    //                     font-size: 16px;
    //                 }
    //             }
    //        }
    //    }
    // }
    @include max-width($tablet) {
        .events-sec{
            .header-sec {
                h2 {
                    font-size:48px;
                    line-height:60px;
                    margin-bottom: 19px;
                }
                .filter-sec{
                    float: left;
                    width: 100%;
                    select{
                        font-size: 20px;
                        width: 100%;
                        margin: 0 0px 14px 0;
                    }
            
                    a{
                        width:100%;
                        margin: 6px 0 0;
                    }
                }
            }

            .tabing-sec{
                margin:10.5% ​0 0;
                .react-tabs{
                    ul.react-tabs__tab-list {
                        padding: 0% 5%;
                        li{
                            font-size:14px;
                            line-height:35px;
                            letter-spacing: 2.8px;
                            margin: 0 10.5% 0 0;
                        }
                    }
                    .react-tabs__tab-panel--selected {
                        padding:8.4% 5%;
                        .no-event{
                            margin:22px 0 22px;
                        }
                        .events-list{
                            margin:22px 0 63px;
                            
                            .events-list-month {
                                h3{
                                    font-size:28px;
                                    line-height:50px;
                                    margin: 0 0 20px;
                                }
                            }

                            .event{ 
                                background: $white;
                                .ev-date{
                                    float: left;
                                    width: 27%;
                                    padding: 4.8% 0;
                                }
    
                                .ev-info {
                                    width:100%;
                                    min-height: unset;
                                    .ev-details{
                                        float: left;
                                        width: 100%;
                                        padding:6.5% 5% 12%; 
                                        span{
                                            padding: 0 0 12px 0;
                                        }
    
                                        h3{
                                            font-size: 26px;
                                            line-height: 32px;
                                            margin-bottom: 7px;
                                        }
    
                                        p{
                                            display: none;
                                        }
    
                                        a{
                                            font-size: 16px;
                                            line-height: 25px;
                                        }
    
                                        .event-time-venue{
                                            border-top: 1px solid #EEEEEE;
                                            margin: 21px 0 0;
                                            display: block;
                                            padding: 17px 0 0;
                                            span{
                                                font-size:22px;
                                                line-height: 38px;
                                                width: 60%;
                                                padding: 0 0% 0 0;
                                                border-right: none;  
                                            }
    
                                            span + span{
                                                font-size:18px;
                                                line-height:24px;
                                                width: 60%;
                                                padding: 5px 0 0;
                                            }
                                        }
                                    }
                                    .ev-img {
                                        display: none;
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
            
        }
    }
    @include max-width($iphone6-plus-landscape) {
        .events-sec{
            .tabing-sec{
                .react-tabs{
                    .react-tabs__tab-panel--selected {
                        .events-list{
                            .event{ 
                                .ev-date{
                                    span{
                                        font-size: 10px;
                                        line-height:18px;
                                    }
    
                                    span + span{
                                        font-size:30px;
                                        line-height:37px;
                                    }
                                }
                            }
                        }
    
                    }
                }
            } 
        }
    }
}
/* /Events Page CSS */

/* Events Details CSS */
.events-detail{
overflow-x: hidden;
    .detail-info {
        .img {
            width: 50%;
            float: left;
            position: relative;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }

            .ev-date{
                width: 165px;
                background: #C0D34A;
                color: $white;
                text-align: center;
                padding: 3.111% 2%;
                position: absolute;
                top: 10%;
                right: 0;

                span{
                    display: block;
                    letter-spacing: 0px;
                    font-size: 18px;
                    line-height: 32px;
                }

                span + span{
                    display: block;
                    letter-spacing: 0px;
                    font-size: 60px;
                    line-height: 74px;
                }
            }
        }

        .ev-detail {
            float: left;
            width: 50%;
            padding: 5% 14.5% 6.5% 8%;
            background-color: $bggray-new;
           

            span{
                letter-spacing:4.8px;
                font-size:16px;
                line-height:25px;
                color: #545454;
                font-weight: $font-atten-medium;
                padding:0 0 28px;
                display: block;
                text-transform: uppercase;
            }

            h3{
                letter-spacing: 0px;
                font-size:48px;
                line-height:50px;
                color: #545454;
                margin-bottom:11px;
              
              @include max-width($desktop-xl){
                max-width: 100%;
              }
              @include min-width($tablet-landscape){
                max-width: 81%;
              }
            }

            p{
                letter-spacing: 0px;
                color: #545454;
                font-size:18px;
                line-height: 20px;
            }

            ul {
                float: left;
                width: 100%;
                margin: 6px 0 0;
                padding: 0;
                li {
                    float: left;
                    width: 100%;
                    border-bottom: 1px solid #CCCCCC;
                    padding: 16px 0 17px;
                    list-style: none;
                    line-height: 26px;
                    span{
                        float: left;
                        width: 18%;
                        letter-spacing: 0px;
                        color: #545454;
                        font-size:18px;
                        line-height:26px;
                        font-weight: $font-atten-medium;
                        padding: 0;
                        margin: 0;
                        text-align: left;
                    }
                    p{
                        float: left;
                        width: 82%;
                        letter-spacing: 0px;
                        color: #545454;
                        font-size:18px;
                        line-height: 26px;
                        font-weight: $font-atten-medium;
                        padding: 0;
                        margin: 0;
                    }
                }
            }

            .rsvp-nav-link {
                float: left;
                margin: 65px 0 0;
                background: #C0D34A;
                color: $white;
                font-size:16px;
                line-height:35px;
                font-weight: $font-atten-medium;
                padding: 21px;
                min-width:324px;
                text-decoration: none;
                text-align: center;
                border: 1px solid #C0D34A;
                transition: all .5s ease;
                letter-spacing: 3.5px;
                cursor: pointer !important;

                &:hover{
                    color: #000000;
                    background: $white;
                }
            }
            
        }
    }

    .author-box {
        background: #545454;
        color: #ffffff;
        padding: 73px;

        .author-detail{
            width: 60.5%;
            margin: 0 auto;
            .author-img{
                float: left;
                width:208px;
                height: 205px;
                overflow: hidden;
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }

            .author-info{
                width: calc(100% - 212px);
                float: left;
                padding:0% 0% 0% 4%; 

                span{
                    letter-spacing:4.2px;
                    font-size:14px;
                    line-height:25px;
                    color: #969696;
                    font-weight: $font-atten-medium;
                    padding:0 0 28px;
                    display: block;
                }
    
                h3{
                    letter-spacing: 0px;
                    font-size:30px;
                    line-height:32px;
                    color: $white;
                    margin-bottom:15px;
                }
    
                p{
                    letter-spacing: 0px;
                    color: $white;
                    font-size:18px;
                    line-height: 20px;
                    margin: 0 0 13px 0;
                }

                a{
                    letter-spacing:3.2px;
                    font-size: 16px;
                    line-height: 25px;
                    font-weight: $font-atten-medium;
                    color: #C0D34A;
                    text-decoration: none;
                    position: relative;

                    &::before{
                        content: " ";
                        position: absolute;
                        top: 50%;
                        left: auto;
                        width: 7px;
                        height: 7px;
                        border-top: 2px solid #C0D34A;
                        border-right: 2px solid #C0D34A;
                        -webkit-transform: translate(-1px,-50%) rotate(45deg);
                        transform: translate(-1px,-50%) rotate(45deg);
                        right: -30px;
                    }

                    &::after {
                        background: #C0D34A;
                        -webkit-transform: translate(-50%,-50%);
                        transform: translate(-50%,-50%);
                        height: 2px;
                        width: 13px;
                        content: " ";
                        position: absolute;
                        top: 50%;
                        left: auto;
                        right: -34px;
                    }
                }
            }
        }
    }
    .event_disclaimer_section{
        .event_div_disclaimer{
            padding: 2% 5%;
            font-size: 18px;
            p{
                margin: 0;
            }
        }
    }

    @include max-width($desktop-large) {
        .detail-info {
            .ev-detail {
                padding: 4.6% 6% 6.5% 5%;
                
                .rsvp-nav-link{
                    line-height:25px;
                }
                
               
            }
        }
        .author-box{
            .author-detail{
                width: 80%;
            }
        } 
    }
    

  
    

    @include max-width($iphonex-landscape) {
        .detail-info {
            .img {
                width: 100%;
                height: 375px;

                .ev-date {
                    width: 33.1%;
                    padding: 6.2% 2%;
                    top: auto;
                    right: auto;
                    bottom: 0;
                    left: 0;

                    span {
                        font-size: 14px;
                        line-height: 25px;
                    }

                    span+span {
                        font-size: 43px;
                        line-height: 53px;
                    }
                }
            }
            .ev-detail {
                width: 100%;
                padding: 9.6% 7% 6.5% 7%;

                span{
                    letter-spacing:4.2px;
                    font-size:14px;
                    line-height:25px;
                    padding:0 0 8px;
                }
    
                h3{
                    font-size: 26px;
                    line-height: 32px;
                    margin-bottom:11px;
                }
    
                ul {
                    li {
                        span{
                            width:20%;
                        }
                        p{
                            width: 80%;
                        }
                    }
                }
    
                .rsvp-nav-link{
                    margin: 50px 0 43px;
                    // padding:12px 0px;
                    line-height: 18px;
                    width: 100%;
                }
            }
        }

        .author-box{
            padding: 50px 8px 30px;
            .author-detail {
                .author-img {
                    width: 100%;
                    height: auto;
                }
                .author-info {
                    width: 100%;
                    padding: 29px 0;

                    h3{
                        font-size: 26px;
                        line-height: 32px;
                    }
                }
            }
        }
    }

}
/* /Events Details CSS */

/* Popup CSS*/
.alert_popup{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    .popup_message{
        padding: 30px;
        color: $black-new;
        font-size: 20px;
        border: 5px solid #000000;
        border-radius: 20px;
        background-color: $white;
        position: relative;
        a{
            color: #000000;
        }
        p{
            margin: 0;
        }
        span{
            position: absolute;
            display: inline-block;
            top: 3%;
            right: 2%;
            cursor: pointer;
        }
    }

    @include max-width($iphonex-landscape){
        .popup_message{
            max-width: 90%;
        }
    }
}
/* Popup CSS Ends*/


.page_not{
    background-color:$bglightcream;
    height: 80vh;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*static page css*/


.global-main{
    .staic-content {
        padding: 0 5%;
        padding-bottom: 2%;
    }
    .static_button_content {
        text-align: center;
        margin-bottom: 3%;
        .learn-more {
            padding: 1% 7%;
            width: 100%;
            border: 1px solid;
            color: #fff;
            margin-right: 5%;
            text-decoration: none;
            text-transform: uppercase;
            background-color: #000000;
            font-weight: $font-atten-medium;
            letter-spacing: 2px;
            &:last-child {
                margin-right: 0 !important;
                background-color: $black-new;
            }
        }
    }
}